import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  
  // menu variables
  public activeInnerMenu: any = 'data';

  // edit product variables
  public showUpdateDetailsStatus: any = false;
  public showUpdateMeasurementStatus: any = false;
  public measurementMaxList: any = [1,2,3,4,5];
  public discountTypeList: any = ['none', 'bulk', 'single'];
  public updateProductDetails: any = {
    id: '',
    name: '',
    category: '',
    description: '',
    expiryDate: '',
    shelfNumber: '',
    noOfMeasurement: 0,
    noOfOldMeasurement: 0,
    unitOfMeasurement: [],
    file: null,
    moreImages : []
  }
  public updateProductData: any = {}
  public updateProductMeasurements: any = {}

  // category variables
  public categories: any = [];
  public listOfAvailableCategory = [];
  public oldCategory: any = null

  // fix unit of measurement details
  public fixStatus = false;
  public fixDetails: any = {
    errorUnit: [],
    correctUnit: [],
  }

  // EDIT NAME OF UNIT OF MEASUREMENT
  public editUnitDetails: any = {
    productId: null,
    measurements: [],
    oldUnit: null,
    newUnit: null,
  }

  // add more images
  public moreImagesDetails: any = {
    file: null,
    displayImage : null

  }

  constructor(
    private functionService: RequestService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((res)=>{

      if(res.id){

        this.getCategories();

        this.getSingleProduct(res.id);

      }else{

        this.goToPage('products/list');

      }

    });

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

    if(menu == 'editData'){

      this.updateProductData.id = this.updateProductDetails.id;
      this.updateProductData.name = this.updateProductDetails.name
      this.updateProductData.description = this.updateProductDetails.description
      this.updateProductData.shelfNumber = this.updateProductDetails.shelfNumber;
      this.updateProductData.displayImage = this.updateProductDetails.displayImage;
      this.updateProductData.category = this.updateProductDetails.category ? this.updateProductDetails.category._id : null;

      this.oldCategory = this.updateProductData.category

    }else if(menu == 'editUnits'){
      
      this.updateProductMeasurements.id = this.updateProductDetails.id;
      this.updateProductMeasurements.noOfMeasurement = this.updateProductDetails.noOfMeasurement;
      this.updateProductMeasurements.noOfOldMeasurement = this.updateProductDetails.noOfOldMeasurement;
      this.updateProductMeasurements.unitOfMeasurement = [];
      for (let i = 0; i < this.updateProductDetails.unitOfMeasurement.length; i++) {

        var measurement = this.updateProductDetails.unitOfMeasurement[i];

        this.updateProductMeasurements.unitOfMeasurement.push({
          name: measurement.name,
          quantity:  measurement.quantity,
          reorderLimit:  measurement.reorderLimit,
          costPrice:  measurement.costPrice,
          sellingPrice:  measurement.sellingPrice ,
          limitPrice:  measurement.limitPrice,
          barCode: measurement.barCode,
          discountType:  measurement.discountType,
          discountUnit:  measurement.discountUnit,
          discountPercentage: measurement.discountPercentage,
          wholesalesQuantity:  measurement.wholesalesQuantity
        })
        
      }

    }

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // EDIT PRODUCT FUNCTIONS 
  getSingleProduct(productId){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetOneProduct';
    var requestData = {
      productId: productId
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          var product = res.Product;

          this.updateProductDetails.id = product._id
          this.updateProductDetails.name = product.name
          this.updateProductDetails.category = product.category[0]
          this.updateProductDetails.description = product.description
          this.updateProductDetails.expiryDate = product.expiryDate;
          this.updateProductDetails.shelfNumber = product.shelfNumber;
          this.updateProductDetails.updatedAt = product.updatedAt;
          this.updateProductDetails.displayImage = product.Imageurl ? product.Imageurl : null;
          this.updateProductDetails.moreImages = product.moreImages ? product.moreImages : null;

          // structure unit of measurement
          this.updateProductDetails.noOfMeasurement = String(product.unitOfMeasurement.length);
          this.updateProductDetails.noOfOldMeasurement = this.updateProductDetails.noOfMeasurement
          this.updateProductDetails.unitOfMeasurement = [];

          var numberOfBarcode = product.barCode ? product.barCode.length: 0 ;
          var generatedBarcodes = [];

          for (let i = 0; i < product.unitOfMeasurement.length; i++) {
            
            if(i < numberOfBarcode){

              generatedBarcodes.push(product.barCode[i])

            }else{

              generatedBarcodes.push(product.barCode[numberOfBarcode-1])

            }
            
          }

          for (let i = 0; i < product.unitOfMeasurement.length; i++) {

            if(product.unitOfMeasurement[i]){

              var measurement = product.unitOfMeasurement[i];

              var unit = {
                name: measurement,
                quantity: product.quantity ? product.quantity[measurement]: null ,
                reorderLimit: product.reOrderLimit ? product.reOrderLimit[measurement]: null ,
                costPrice: product.costPrice ? product.costPrice[measurement]: null ,
                sellingPrice: product.sellingPrice ? product.sellingPrice[measurement] : null ,
                limitPrice: product.limitPrice ? product.limitPrice[measurement]: null ,
                barCode: generatedBarcodes[i],
                discountType: product.discountType ? product.discountType[measurement]: null ,
                discountUnit: product.discountUnit ? product.discountUnit[measurement]: null ,
                discountPercentage: product.discount ? product.discount[measurement]: null ,
                wholesalesQuantity: product.WholesalesQuantity ? product.WholesalesQuantity[measurement]: 0 ,
              };

              this.updateProductDetails.unitOfMeasurement.push(unit)

            }
            
          }

          // structure fixable details
          this.structureFixableDetails(res.Product);

          // structure edit name of unit
          this.structureEditUnitName(product.unitOfMeasurement, product._id);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // get image file
  onFileSelected() {
    
    var file:any = (<HTMLInputElement>document.getElementById('file')).files[0];
    this.updateProductData.file = file;

    var r = new FileReader();

    r.onload = (e:any)=> {

      // data is in r.result
      this.updateProductData.displayImage = e.target.result;

    }

    r.readAsDataURL(file);

  }

  // update product
  updateProduct(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/UpdateProduct';

    var requestData = new FormData();
    requestData.append('productId', this.updateProductData.id)
    requestData.append('description', this.updateProductData.description)
    requestData.append('shelfNumber', this.updateProductData.shelfNumber)
    requestData.append('name', this.updateProductData.name)
    if(this.oldCategory !== this.updateProductData.category){
      requestData.append('categoryId', this.updateProductData.category)
    }
    if(this.updateProductData.file){

      requestData.append('image', this.updateProductData.file)

    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          if(this.oldCategory !== this.updateProductData.category){

            this.removeCategoryFromProduct()

          }else{

            requestResponse = 'Product detail updated successfully.';
            this.toastNotification(requestResponse);
            
            this.toggleInnerMenu('data')
            this.getSingleProduct(this.updateProductData.id);

          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // remove category from product
  removeCategoryFromProduct(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/RemoveProductFromCategory';
    var requestData: any = {
      productId: this.updateProductData.id,
      categoryId: this.oldCategory,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product detail updated successfully.';
          this.toastNotification(requestResponse);

          this.toggleInnerMenu('data')
          this.getSingleProduct(requestData.productId);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // generate barcode
  getBarCode(){

    var result           = '';

    var characters       = '0123456789';

    var charactersLength = 12;

    for( var i = 0; i < charactersLength; i++ ) {

      result += characters.charAt(Math.floor(Math.random() * charactersLength));

    }

    return result;

  }

  // generate unit of measurement textbox
  generateMeasurementBoxes(){

    if(this.updateProductMeasurements.noOfMeasurement >= this.updateProductMeasurements.noOfOldMeasurement){

      if(this.updateProductMeasurements.noOfMeasurement > this.updateProductMeasurements.unitOfMeasurement.length ){

        for (let i = this.updateProductMeasurements.noOfOldMeasurement; i < this.updateProductMeasurements.noOfMeasurement; i++) {

          var option: any = {
            name: '',
            quantity: 0,
            reorderLimit: 0,
            costPrice: 0,
            sellingPrice: 0,
            limitPrice: 0,
            barCode: '',
            discountType: 'none',
            discountPercentage: '',
            discountUnit: 0,
            wholesalesQuantity: 0
          };
    
          this.updateProductMeasurements.unitOfMeasurement.push(option);
        
        }
        
      }else if(this.updateProductMeasurements.noOfMeasurement <= this.updateProductMeasurements.unitOfMeasurement.length){

        var diff = this.updateProductMeasurements.unitOfMeasurement.length - this.updateProductMeasurements.noOfMeasurement;

        for (let j = 0; j < diff; j++) {

          this.updateProductMeasurements.unitOfMeasurement.pop()

        }

      }

    }else{

      var requestResponse = 'Number of measurements cannot be reduced.';
      this.toastNotification(requestResponse);

      this.updateProductMeasurements.noOfMeasurement = this.updateProductMeasurements.noOfOldMeasurement;

    }

  }

  // structure data before sending http request to add item
  goToFinalStep(){

    var requestData: any = {
      productId: this.updateProductMeasurements.id,
      unitOfMeasurement: [],
      quantity: {},
      reOrderLimit: {},
      costPrice: {},
      sellingPrice: {},
      limitPrice: {},
      barCode: [],
      discountType: {},
      discount: {},
      discountUnit: {},
      WholesalesQuantity: {},
    };

    for (let i = 0; i < this.updateProductMeasurements.unitOfMeasurement.length; i++) {
      
      if(this.updateProductMeasurements.unitOfMeasurement[i]){

        var measurement = this.updateProductMeasurements.unitOfMeasurement[i];

        // add name of units of measurement
        requestData.unitOfMeasurement.push(measurement.name);

        // add quantity for unit of measurement
        requestData.quantity[measurement.name] = measurement.quantity

        // add reOrder Limit for unit of measurement
        requestData.reOrderLimit[measurement.name] = measurement.reorderLimit

        // add cost Price for unit of measurement
        requestData.costPrice[measurement.name] = measurement.costPrice

        // add selling Price for unit of measurement
        requestData.sellingPrice[measurement.name] = measurement.sellingPrice

        // add limit Price for unit of measurement
        if(measurement.limitPrice > 0){

          requestData.limitPrice[measurement.name] = measurement.limitPrice

        }else{

          requestData.limitPrice[measurement.name] = measurement.sellingPrice

        }

        // add barCode for unit of measurement
        if(measurement.barCode){

          requestData.barCode.push(measurement.barCode);

        }else{

          requestData.barCode.push(this.getBarCode());

        }

        // add discount Type for unit of measurement
        requestData.discountType[measurement.name] = measurement.discountType

        // add discount percentage and discount bulk unit for unit of measurement
        if(measurement.discountType == 'none'){

          requestData.discount[measurement.name] = 0;
          requestData.discountUnit[measurement.name] = 0

        }else if(measurement.discountType == 'single'){

          requestData.discount[measurement.name] = measurement.discountPercentage;
          requestData.discountUnit[measurement.name] = 0

        }else if(measurement.discountType == 'bulk'){

          requestData.discount[measurement.name] = measurement.discountPercentage;
          requestData.discountUnit[measurement.name] = measurement.discountUnit

        }

        // add WholesalesQuantity for unit of measurement
        requestData.WholesalesQuantity[measurement.name] = measurement.wholesalesQuantity

      }
      
    }

    this.updateUnitOfMeasurement(requestData);

  }

  // update unit of measurement
  updateUnitOfMeasurement(requestData){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/UpdateUnitOfMeasurement';

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product measurements updated successfully.';
          this.toastNotification(requestResponse);

          this.toggleInnerMenu('data')
          this.getSingleProduct(requestData.productId);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // CATEGORY FUNCTIONS

  // get all categories
  getCategories(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/GetCategories';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          var categories = res.Categories;

          this.categories = [];
          for (let i = 0; i < categories.length; i++) {
            
            if(categories[i]){

              var category = {
                name: categories[i].name,
                subCategories:categories[i].subCategories,
                _id: categories[i]._id,
                firstLetter: categories[i].name.substring(0,1),
                products: []
              };

              this.categories.push(category);

            }
            
          }

          // select available categories with no subcategory
          this.listOfAvailableCategory = [];
          for (let i = 0; i < this.categories.length; i++) {
        
            if(this.categories[i] && this.categories[i].subCategories.length < 1){
    
              this.listOfAvailableCategory.push(this.categories[i]);
    
            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }


  // FIX UNIT FUNCTIONS

  // structure fixable unit details
  structureFixableDetails(product){

    // check if quantity field has a unit that is not available in the unit of measurement
    var productRealMeaurements = product.unitOfMeasurement
    var quantityUnits = Object.keys(product.quantity);
    var availablity = 0;
    for (let i = 0; i < productRealMeaurements.length; i++) {
      
      var availableUnit = quantityUnits.includes(productRealMeaurements[i]);

      if(availableUnit){

        availablity++

      }
    }

    if(availablity !== productRealMeaurements.length){

      // store units with errors
      this.fixDetails.errorUnit = []
      for (let i = 0; i < quantityUnits.length; i++) {

        if(!productRealMeaurements.includes(quantityUnits[i])){

          if(quantityUnits[i].toLowerCase() !== 'upDated'.toLowerCase()){

            var errorMeasurement = {
              name: quantityUnits[i],
              quantity: product.quantity[quantityUnits[i]]
            }
    
            this.fixDetails.errorUnit.push(errorMeasurement)

          }

        }
        
      }

      // store units with no errors
      this.fixDetails.correctUnit = []
      for (let i = 0; i < productRealMeaurements.length; i++) {
        
        var correctMeasurement = {
          name: productRealMeaurements[i],
          quantity: product.quantity[productRealMeaurements[i]]
        }

        this.fixDetails.correctUnit.push(correctMeasurement)
        
      }

      if(this.fixDetails.errorUnit.length > 0){

        this.fixStatus = true;

      }

    }else{

      this.fixStatus = false

    }

  }

  // process fix
  fixErrorUnits(){

    if(this.fixDetails.errorUnit.length > 0){

      for (let i = 0; i < this.fixDetails.errorUnit.length; i++) {

        var errorUnit = this.fixDetails.errorUnit[i];

        var option: any = {
          name: errorUnit.name,
          quantity: 0,
          reorderLimit: 0,
          costPrice: 0,
          sellingPrice: 0,
          limitPrice: 0,
          barCode: '',
          discountType: 'none',
          discountPercentage: '',
          discountUnit: 0,
          wholesalesQuantity: 0
        };
    
        this.updateProductDetails.unitOfMeasurement.push(option);
        
        
      }

      this.goToFinalStep();

      this.fixStatus = false;

    }

  }

  // structure edit name of unit
  structureEditUnitName(measurements, productId){

    this.editUnitDetails.productId = productId
    this.editUnitDetails.oldUnit = null
    this.editUnitDetails.newUnit = null
    this.editUnitDetails.measurements = []

    for (let i = 0; i < measurements.length; i++) {
      
      this.editUnitDetails.measurements.push(measurements[i]);
    }
    
  }

  // edit name of unit
  editUnitName(form: NgForm){

    if(this.editUnitDetails.productId && this.editUnitDetails.oldUnit && this.editUnitDetails.newUnit){

      var requestData: any = {
        productId: this.editUnitDetails.productId,
        oldUnit: this.editUnitDetails.oldUnit,
        newUnit: this.editUnitDetails.newUnit,
      }

      this.editUnitRequest(form, requestData)

    }else{

      var requestResponse = 'Please select a unit of measurement';
      this.toastNotification(requestResponse);

    }

  }
  
  editUnitRequest(form: NgForm, requestData){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/EditSingleUnitOfMeasurement';

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product detail updated successfully.';
          this.toastNotification(requestResponse);

          form.resetForm();

          this.toggleInnerMenu('data')
          this.getSingleProduct(requestData.productId);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }


  // add more images
  // get image file
  onMoreFileSelected() {
    
    var file:any = (<HTMLInputElement>document.getElementById('fileMore')).files[0];
    this.moreImagesDetails.file = file;

    var r = new FileReader();

    r.onload = (e:any)=> {

      // data is in r.result
      this.moreImagesDetails.displayImage = e.target.result;

    }

    r.readAsDataURL(file);

  }

  addMoreImages(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/AddMoreImageProduct';

    var requestData = new FormData();
    requestData.append('productId', this.updateProductDetails.id)
    if(this.moreImagesDetails.file){

      requestData.append('image', this.moreImagesDetails.file)

    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product image updated successfully.';
          this.toastNotification(requestResponse);

          this.moreImagesDetails.displayImage = null;
          this.moreImagesDetails.file = null;

          this.getSingleProduct(this.updateProductDetails.id);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  deleteImages(image){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/DeleteOneImageFromProductMoreImages';

    var requestData = {
      productId: this.updateProductDetails.id,
      imgUri: image.imgUri
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product images updated successfully.';
          this.toastNotification(requestResponse);

          this.getSingleProduct(requestData.productId);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }
}
