import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // currency variables
  public currency = 'NGN';

  // cart variables
  public cartNumber: any = '0';
  public listOfCarts: any = [];
  public showCartList: any =  false;
  public cart: any = [];

  // summary variables
  public summary: any = {
    noOfProduct: 0,
    noOfCustomer: 0,
    noOfStaff: 0,
    nearExpiredProducts: 0,
  }

  // total stock of products variables
  public stockOfProducts: any = []
  
  // top selling items variables
  public topSellingItems: any = [];

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getListCart();

    this.getDashboard()

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get dashboard
  getDashboard(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/BasicSalesInfo';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.summary.noOfProduct = res.noOfproduct
          this.summary.noOfCustomer = res.customer
          this.summary.noOfStaff = res.staff

          this.structureTotalProductStock(res.totalStock);

          this.structureTopSellingProduct(res.TopSelling)

          this.getNearExpiredProducts()
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure total stock of products
  structureTotalProductStock(stock){

    this.stockOfProducts = [];

    for (let i = 0; i < stock.length; i++) {
    
      var product = stock[i];

      var processedProduct = {
        id: product.id,
        name: product.name,
        measurements: []
      };

      // convert object to array
      var objectKeys = Object.keys(product.quantity);
      for (let j = 0; j < objectKeys.length; j++) {
            
        var unit = objectKeys[j];

        if(unit !== 'upDated'){

          var measurement = {
            name: unit,
            sellingPrice: product.sellingPrice[unit],
            quantity: product.quantity[unit]
          }
  
          processedProduct.measurements.push(measurement);

        }        
        
      }

      this.stockOfProducts.push(processedProduct)
      
    }

  }

  // structure top selling items
  structureTopSellingProduct(soldItems){

    this.topSellingItems = [];

    var topSellingItems = [];

    var totalProductSales = 0;
    for (let i = 0; i < soldItems.length; i++) {
      
      var product = {
        name: soldItems[i].productName,
        totalSales: soldItems[i].TotalSales,
        width: 0,
        color: null
      }

      totalProductSales = Number(totalProductSales) + Number(soldItems[i].TotalSales);

      topSellingItems.push(product);
      
    }

    topSellingItems.sort(this.compareSalesAmount);

    // assign width and color
    for (let i = 0; i < 15; i++) {

      if(topSellingItems[i]){
      
        topSellingItems[i].color = this.getRandomColor();

        var width = (topSellingItems[i].totalSales / totalProductSales) * 100;
        topSellingItems[i].width = width.toFixed(2)+'%';


        this.topSellingItems.push(topSellingItems[i]);

      }
      
    }

  }

  // compare total sales between products, arrange in descending order
  compareSalesAmount(a, b) {

    const bandA = a.totalSales;
    const bandB = b.totalSales;

    let comparison = 0;

    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }

    return comparison * -1;
  }

  // generate random colors
  getRandomColor() {

    var letters = '0123456789ABCDEF';
    var color = '#';

    for (var i = 0; i < 6; i++) {

      color += letters[Math.floor(Math.random() * 16)];

    }

    return color;

  }

  // get list of carts
  getListCart(){

    this.functionService.getCart().subscribe((cart)=>{

      this.listOfCarts = cart;

      if(this.listOfCarts && this.listOfCarts.length > 0){

        var selectedCart = this.listOfCarts.find(o=> o.status == true);

        if(selectedCart){

          this.cart = selectedCart.cart;
          
          if(this.cart){

            this.cartNumber = this.cart.length;
    
          }

        }

      }

    })

  }

  // toggle cart list
  toggleCartList(status){

    this.showCartList = status

  }
  
  // add more carts to cart array
  addMoreCart(){

    // deactivate all previous carts
    for (let i = 0; i < this.listOfCarts.length; i++) {
      
      this.listOfCarts[i].status = false;
      
    }

    // add a new cart to the beginning of the array
    var cart = {
      status:true,
      cart: []
    }

    this.listOfCarts.unshift(cart);
    this.cartNumber = 0;

  }

  // choose cart to pass, deactivate others
  chooseCart(cartIndex, event){

    this.listOfCarts[cartIndex].status = event.checked;

    // deactivate all other carts
    for (let i = 0; i < this.listOfCarts.length; i++) {
      
      if(i !== cartIndex){

        this.listOfCarts[i].status = false;

      }else if(i == cartIndex && this.listOfCarts[i].status == false){

        this.listOfCarts[i].status == true;

      }
      
    }

  }

  // remove cart from cart list
  removeCart(cartIndex){

    this.listOfCarts.splice(cartIndex,1);

    if(this.listOfCarts.length < 1){

      var cart = {
        status:true,
        cart: []
      }
  
      this.listOfCarts.unshift(cart);

    }

  }

  // calculate amount of item from the quantity entered on unit of measurement
  calculateUnitAmount(measurement){

    var discountType = measurement.discountType;
    var discountPercentage = measurement.discountPercentage;
    var discountUnit = measurement.discountUnit;

    var wholesalesQuantity = measurement.wholesalesQuantity;
    var wholesalePrice = measurement.limitPrice;
    var retailPrice = measurement.sellingPrice

    var discountCharge = 0;

    if(discountType == 'none'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          measurement.cartAmount = wholesalePrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }else{

          measurement.cartAmount = retailPrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }

      }
      

    }else if(discountType == 'single'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          discountCharge = (discountPercentage/100) * wholesalePrice;
          measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }else{

          discountCharge = (discountPercentage/100) * retailPrice;
          measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }

      }

    }else if(discountType == 'bulk'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(measurement.cartQty >= discountUnit){

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            discountCharge = (discountPercentage/100) * wholesalePrice;
  
            measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }else{

            discountCharge = (discountPercentage/100) * retailPrice;
  
            measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }
  
        }else{

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            measurement.cartAmount = wholesalePrice * measurement.cartQty;

          }else{

            measurement.cartAmount = retailPrice * measurement.cartQty;

          }
  
  
        }

      }
      
    }

  }

  //  check cart for quantity issues
  proccessCart(){

    var passQuantities = false

    for (let i = 0; i < this.cart.length; i++) {
      
      if(this.cart[i].cartQty > this.cart[i].totalQuantity){

        passQuantities = false;
        break;

      }else{

        passQuantities = true;

      }
      
    }

    if(passQuantities){

      this.goToCart()

    }else{

      var requestResponse = 'One of the products has a quantity greater than its available quantity.';
      this.toastNotification(requestResponse);

    }

  }

  // go to cart page
  goToCart(){

    this.toggleCartList(false);

    if(this.cartNumber > 0){

      var passCart = false;

      for (let i = 0; i < this.listOfCarts.length; i++) {
        
        if(this.listOfCarts[i].status){

          passCart = true;
          break;

        }
      }

      if(passCart){

        this.goToPage('products/cart');
        
      }else{

        var requestResponse = 'No cart was selected.';
        this.toastNotification(requestResponse);
  
      }

    }else{

      var requestResponse = 'Cart is empty.';
      this.toastNotification(requestResponse);

    }


  }


  // get near expired products
  getNearExpiredProducts(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/ExpiryInventoryInfo';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.summary.nearExpiredProducts = res.expiryInventory ? res.expiryInventory.length : 0;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
