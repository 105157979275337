import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { AuthService } from '../../functions/auth/auth.service';

@Component({
  selector: 'app-list-staff',
  templateUrl: './list-staff.component.html',
  styleUrls: ['./list-staff.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class ListStaffComponent implements OnInit {


  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // staff records variables
  public staffList: any = [];
  public showSelectedStaff = false;
  public selectedStaff: any = {
    id: '',
    name: '',
    role: '',
    phoneNumber: ''
  }
  public popOver: NgbPopover;

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  // staff position variables
  public positions: any = [];

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // staff activities
  public activities: any = [
    {
      value: 'sales',
      name: 'sales activities'
    },
    {
      value: 'credit',
      name: 'credit sales activities'
    },
    {
      value: 'return',
      name: 'sales return activities'
    },
    {
      value: 'misc',
      name: 'misc event activities'
    },
    {
      value: 'expiry',
      name: 'expiration activities'
    },
    {
      value: 'inventory',
      name: 'inventory activities'
    }

  ]

  // add staff variables
  public addStaffDetails: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
  }

  // transfer staff
  public stores: any = [];

  constructor(
    private functionService: RequestService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.getStaffs();

  }

  // start and stop loading funx
  startLoading() {

    this.functionService.setLoading(true);

  }

  stopLoading() {

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any) {

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView() {
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }

  // goto selected page
  goToPage(page) {

    this.functionService.setMenu(page);

  }

  // get staff positions
  getPositions() {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/GetAvailableStaffPosition';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          this.positions = res.ManagersPosition;

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // get staffs
  getStaffs() {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/GetAllStaffs';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          this.staffList = res.staffs;

          this.structurePagination(res.totalItems);

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems) {

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords / this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if (start < 0) {

      start = 1

    }

    var end = this.pageIndex + 3;
    if (end < 6 && end <= this.noOfPages) {

      end = 6

    } else if (end > this.noOfPages) {

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);

    }

  }

  // get pages on scroll
  paginate(page) {

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if (this.pageIndex <= this.noOfPages && this.pageIndex > 0) {

      this.getStaffs();

    } else {

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page) {

    if (page <= this.noOfPages) {

      this.pageIndex = page;

      this.getStaffs();

    }

  }

  // select staff fnc
  selectStaff(staff) {

    if (staff.positionId) {

      this.selectedStaff = {
        id: staff._id,
        name: staff.personalInfo.firstName + ' ' + staff.personalInfo.lastName,
        role: staff.positionId.positionName,
        phoneNumber: staff.phoneNumber,
        email: staff.personalInfo.email,
        active: staff.active
      }

    } else {

      this.selectedStaff = {
        id: staff._id,
        name: staff.personalInfo.firstName + ' ' + staff.personalInfo.lastName,
        role: null,
        phoneNumber: staff.phoneNumber,
        email: staff.personalInfo.email,
        active: staff.active
      }

    }

    this.selectedStaff.activeStore = false;

    var pharm_user = JSON.parse(localStorage.getItem('pharm_user'))
    if (pharm_user) {

      if (pharm_user._id == this.selectedStaff.id) {
        this.selectedStaff.activeStore = true;
      }
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover, action?) {

    if (popOver) {

      if (action == 'position') {

        if (this.positions && this.positions.length == 0) {

          this.getPositions()

        }

        this.selectedStaff.newRole = this.selectedStaff.role

      } else if (action == 'switch' || action == 'transfer') {

        if (this.stores && this.stores.length == 0) {

          this.getStores()

        }

      }

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;

    }

    this.selectedStaff.action = action
  }

  // get stores
  getStores() {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'StoreLocation/GetAllStore';
    var requestData = {
      page: 1,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          this.stores = res.storeLocation;

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // edit staff position
  changeStaffPosition(editForm: NgForm) {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var role = this.positions.find(o => o.positionName == this.selectedStaff.newRole)

    var route = 'Staff/ChangeStaffPosition';
    var requestData = {
      staffId: this.selectedStaff.id,
      positionId: role._id,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          requestResponse = 'Staff position changed successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          if (this.popOver) {
            this.popOver.close()
          }

          this.getStaffs();

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // edit staff store
  changeStaffStore(editForm: NgForm) {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/TransferStaff';
    var requestData = {
      userId: this.selectedStaff.id,
      storeId: this.selectedStaff.storeId,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          requestResponse = 'Staff store changed successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          if (this.popOver) {
            this.popOver.close()
          }
          editForm.resetForm()

          if (this.selectedStaff.action == 'switch') {

            this.authService.logout();

          } else {

            this.getStaffs();

          }

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  //delete selected staff
  deleteStaff() {

    const isConfirmed = window.confirm('Are you sure you want to delete this staff?');
    if (!isConfirmed) {
      return; // Exit the function if the user clicked "Cancel"
    }

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/DeleteStaff';

    var requestData = {
      staffId: this.selectedStaff.id,
      active: true,
    }

    console.log(this.selectedStaff);

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();
        if (res.status.toLowerCase() == httpSuccessText) {
          requestResponse = 'Staff deleted successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          if (this.popOver) {
            this.popOver.close()
          }
          this.getStaffs();
        } else {
          requestResponse = res.message;
          this.toastNotification(requestResponse);
        }
      }, (err) => {
        this.stopLoading();
        requestResponse = err.error.message;
        this.toastNotification(requestResponse);
      }
    );
  }

  // toggle staff activation status
  toggleActivation(event) {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/ActivateAndDeactivate';
    var requestData = {
      staffId: this.selectedStaff.id,
      active: !event,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          requestResponse = 'Staff status changed successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          if (this.popOver) {
            this.popOver.close()
          }

          this.getStaffs();

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // search function
  searchEvent(event) {

    var searchData = event.target.value;

    if (searchData && searchData.toString().length > 2) {

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch() {

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;


  }

  // search request
  searchRequest() {

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/SearchStaff';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        if (res.status.toLowerCase() == httpSuccessText) {

          this.showSearchLoading = false;

          if (res.staffs.length > 0) {
            this.searchResult = res.staffs;
          }

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // show transactions 
  showStaffTransaction(chosenActivity) {

    var route = 'staff/activities/' + chosenActivity + '/' + this.selectedStaff.id;

    this.goToPage(route);

  }

  // add staff
  addStaff(addForm: NgForm) {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var numberPrefix = '+234';
    var extractedNumber = this.addStaffDetails.phoneNumber.substring(1, this.addStaffDetails.phoneNumber.length);
    var addPhoneNumber = numberPrefix + extractedNumber;

    var route = 'Staff/AddStaff';
    var requestData = {
      firstName: this.addStaffDetails.firstName,
      lastName: this.addStaffDetails.lastName,
      phoneNumber: addPhoneNumber,
      email: this.addStaffDetails.email,
      address: this.addStaffDetails.address
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          requestResponse = 'Staff added successfully.';
          this.toastNotification(requestResponse);

          addForm.resetForm()

          this.toggleInnerMenu('list')

          this.getStaffs();

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
