import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../functions/auth/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'edit';

  // user variable
  public userdata: any = {
    _id: '',
    superAdmin: null,
    image: {
      selectedImage: null,
      displayImage: null
    },
    personalInfo: {
      address: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      age: '',
      image: ''
    },
  }

  // password variables
  public changePasswordDetails : any = {
    oldPassword: '',
    password: '',
  }

  public passwordStrength = 'none';

  constructor(
    private functionService: RequestService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.getUserProfile();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // get user profile
  getUserProfile(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/GetProfile';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.userdata._id = res.profile._id;
          this.userdata.superAdmin = res.profile.superAdmin;
          this.userdata.personalInfo = res.profile.personalInfo;

          if(this.userdata.personalInfo.phoneNumber){

            var numberPrefix = '0';
            var extractedNumber = this.userdata.personalInfo.phoneNumber.substring(4, this.userdata.personalInfo.phoneNumber.length);
            var userPhoneNumber = numberPrefix+extractedNumber;
    
            this.userdata.personalInfo.phoneNumber = userPhoneNumber;
    
          }

          if(this.userdata.personalInfo.profileImage){

            this.userdata.image.displayImage =  this.userdata.personalInfo.profileImage
    
          }
          
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // get image
  onFileSelected() {

    this.userdata.image.selectedImage = (<HTMLInputElement>document.getElementById('file')).files[0]
    
    var file = this.userdata.image.selectedImage

    var r = new FileReader();

    r.onload = (e:any)=> {

      // data is in r.result
      this.userdata.image.displayImage = e.target.result;

    }

    r.readAsDataURL(file);
    
  }

  // edit profile
  editProfile(profileForm: NgForm){

    var numberPrefix = '+234';
    var extractedNumber = this.userdata.personalInfo.phoneNumber.substring(1, this.userdata.personalInfo.phoneNumber.length);
    var userPhoneNumber = numberPrefix+extractedNumber;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/UpdateStaffProfile';
    var requestData = new FormData();
    requestData.append('phoneNumber', userPhoneNumber);
    requestData.append('firstName', this.userdata.personalInfo.firstName);
    requestData.append('lastName', this.userdata.personalInfo.lastName);
    requestData.append('email', this.userdata.personalInfo.email);
    requestData.append('address', this.userdata.personalInfo.address);
    requestData.append('gender', this.userdata.personalInfo.gender);
    requestData.append('age', this.userdata.personalInfo.age);

    if(this.userdata.image.selectedImage){

      requestData.append('image', this.userdata.image.selectedImage);

    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Profile update was successful.';
          this.toastNotification(requestResponse);

          this.getUserProfile();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // check password strength
  checkPassword(event){

    if(event.target.value.toString().length >= 5){

      var status = true

      if(status){

        this.passwordStrength = 'strong';
  
      }else{
  
        this.passwordStrength = 'weak';
  
      }

    }else{

      this.passwordStrength = 'weak';

    }

  }

  // change password
  changePassword(changePasswordForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/ChangePassword';
    var requestData = {
      oldPassword: this.changePasswordDetails.oldPassword,
      newPassword: this.changePasswordDetails.password,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Password changed successfully.';
          this.toastNotification(requestResponse);

          changePasswordForm.resetForm();

          this.authService.logout();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
