import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-packed-orders',
  templateUrl: './packed-orders.component.html',
  styleUrls: ['./packed-orders.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class PackedOrdersComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'
  public popOver: NgbPopover;

  // sales records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;
  
  //filter variables
  public filterData: any;


  // modal variables
  public issueDetails: any = {
    comment:null
  }

  // sticker variables
  public stickerDetails: any = {}

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'OnlineSale/GetPackedOrder';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.Order;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.invoiceId = record.invoiceId ? record.invoiceId : null
    this.selectedRecord.receiptId = record.receiptId ? record.receiptId : null
    this.selectedRecord.totalAmount = record.totalAmount ? record.totalAmount : null
    this.selectedRecord.vat = record.vat > -1 ? record.vat : null
    this.selectedRecord.shippingFee = record.shippingFee ? record.shippingFee : null
    this.selectedRecord.paymentOption = record.paymentStatus ? record.paymentStatus : null
    this.selectedRecord.customer = record.contactPerson ? record.contactPerson : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.deliveryLocation = record.deliveryLocation ? record.deliveryLocation : null
    this.selectedRecord.storeApproval = record.storeApproval ? record.storeApproval : null
    this.selectedRecord.itemPacked = record.itemPacked ? record.itemPacked : null
    this.selectedRecord.deliveryCompleted = record.deliveryCompleted ? record.deliveryCompleted : null
    this.selectedRecord.itemPackedTime = record.itemPackedTime ? record.itemPackedTime : null
    this.selectedRecord.itemStatus = record.itemStatus ? record.itemStatus : null
    this.selectedRecord.dispatcher = record.dispatcher ? record.dispatcher : null
    this.selectedRecord.products = [];
    for (let i = 0; i < record.productDetails.length; i++) {
      
      if(record.productDetails[i]){

        var soldProduct = record.productDetails[i];

        var product = {
          id: soldProduct._id,
          name: soldProduct.name,
          totalAmount: soldProduct.totalAmount,
          unitOfMeasurement: soldProduct.unitOfMeasurement,
          sellingPrice: soldProduct.sellingPrice,
          quantity: soldProduct.quantity
        }

        this.selectedRecord.products.push(product);

      }
      
    }

    this.structureStickerDetails();
    this.shoppingCartView = 'true';
    this.marketView = 'true';
    if(this.popOver){

      this.popOver.close();
            
    }

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover, action?){

    if(popOver){

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;

      this.selectedRecord.action = action
    }

  }

  // add issue to order function
  addIssueToOrder(issueForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'OnlineSale/AddIssuesToOrder';
    var requestData: any = {
      orderId: this.selectedRecord.id,
      comment: this.issueDetails.comment
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Order issue added successfully.';
          this.toastNotification(requestResponse);

          issueForm.resetForm()
          this.toggleCartView()
          if(this.popOver){

            this.popOver.close();
                  
          }

          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // struucture sticker details
  structureStickerDetails(){

    this.stickerDetails.name = this.capitalize(this.selectedRecord.customer.name)
    this.stickerDetails.address = this.capitalize(this.selectedRecord.deliveryLocation.locationName.toLowerCase())
    this.stickerDetails.receiptId = this.selectedRecord.receiptId
    this.stickerDetails.phoneNumber = this.selectedRecord.customer.phoneNumber

  }

  // capitalize text
  capitalize(sample){
    const words = sample.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  }

}
