import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class DailyReportComponent implements OnInit {


  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // staff position variables
  public dailyReports: any = [];
  public showSelectedPosition = false;
  public selectedRecord: any = {}

  
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];
  public addDetails: any = {
    id: '',
    name: '',
    roles: []
  }

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getStaffsOnDailyReport();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get staff dailyReports
  getStaffsOnDailyReport(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/GetStaffsOnDailyReport';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.dailyReports = res.report;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    } : {
      name: null,
      phoneNumber: null,
      id: null
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/SearchStaff';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.staffs.length > 0){

            this.searchResult = [];

            var staffs = res.staffs;
  
            for (let i = 0; i < staffs.length; i++) {
              
              if(staffs[i]){
  
                var staff = {
                  firstName: staffs[i].personalInfo.firstName,
                  lastName: staffs[i].personalInfo.lastName,
                  phoneNumber: staffs[i].phoneNumber,
                  _id: staffs[i]._id,
                }
  
                this.searchResult.push(staff)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // create structure for the the selected staff
  chooseStaff(staff){

    this.addDetails.id = staff._id
    this.addDetails.name = staff.firstName+' '+staff.lastName
    

  }

  // edit position
  addStaff(staffForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/AddUserToOpenAndClosingReport';
    var requestData = {
      staffId: this.addDetails.id,
    };

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Staff access updated successfully.';
          this.toastNotification(requestResponse);

          staffForm.resetForm()

          this.closeSearch()
          
          this.toggleInnerMenu('list')

          this.getStaffsOnDailyReport();
          
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // remove staff on daily reports
  removeStaff(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/RemoveStaffFromDailyReport';
    var requestData = {
      staffId: this.selectedRecord.staff.id,
    };

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Staff access updated successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          this.getStaffsOnDailyReport();
          
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
