import { Component } from '@angular/core';
import { RequestService } from './functions/request.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './functions/auth/auth.service';
import onScan from 'onscan.js';
import { io } from 'socket.io-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  //header variables
  public showHeaderMenu: any = false;

  // menu variables
  public activeMenu = 'none';
  public activeMenuCategory: any = null;
  public bigActiveMenu = 'none';

  public passScreenSize = false;

  public isAuthenticated: boolean;

  public user_details: any = {
    name: '',
    role: ''
  }

  public socket: any;
  public firstFunctionRun: any = 0;
  public numberOfNewOrders: any = null;
  public numberOfApprovedOrders: any = null;
  public numberOfPackedOrders: any = null;
  public numberOfTransitOrders: any = null;
  public totalOrders: any = null;
  public numberOfPrescriptionInvoices: any = null;

  constructor(
    private router: Router,
    private functionService: RequestService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
  ){

    var screenWidth = window.innerWidth;

    if(screenWidth > 768){

      this.passScreenSize = true;
      
      onScan.attachTo(document);

    }else{
      
      this.passScreenSize = false;
      
    }

  }

  ngOnInit() {

    this.authService.checkAuthenticated();
    this.authService.getAuthenticatedUser().subscribe((authenticatedUser: any)  => {

      if(authenticatedUser && authenticatedUser._id){

        this.isAuthenticated = true;
        if(this.firstFunctionRun == 0){

          this.getNumberOfNewOrders()

        }
        this.firstFunctionRun++

      }else{
        
        this.isAuthenticated = false;
        this.functionService.setLoading(false)
        this.goToPage('login')
      }

    });

    this.initatePage();

  }

  // initiate page 
  initatePage(){

    // activate menu
    this.getActiveMenu();

    this.getLoading();

    this.setupSocketConnection();
  
  }
  
  // get the active menu
  getActiveMenu(){

    this.router.events.subscribe((event)=>{

      if (event instanceof NavigationEnd) {

        var menu = event.urlAfterRedirects

        if(menu){

          var menuArray = menu.split("/");
    
          this.activeMenu = menuArray[1];
          this.bigActiveMenu = menuArray[2];

          this.toggleMenuCategory(this.activeMenu, true)
    
          // set user details
          var user = JSON.parse(localStorage.getItem('pharm_user'));
          if(user){
    
            this.user_details.name = user.personalInfo.firstName +' '+ user.personalInfo.lastName
            this.user_details.phoneNumber = user.personalInfo.phoneNumber
            this.user_details.store = user.Store.locationName
    
          }
    
          if(this.activeMenu == 'login' ){
    
            this.isAuthenticated = false;
            this.logoutUser();
    
          }else if(this.activeMenu == 'password'){

            this.showHeaderMenu = false;
            this.isAuthenticated = false;

          }
    
        }
    

      }

    })

  }

  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get loading status
  getLoading(){

    this.functionService.getLoading().subscribe((loading) => {

      if(loading){

        this.spinner.show();

      }else{

        this.spinner.hide();

      }

    });

  }

  // logout function
  logoutUser(){

    this.showHeaderMenu = false;
    this.authService.logout();

  }

  // toggele header menu
  toggleHeaderMenu(){
    this.showHeaderMenu = !this.showHeaderMenu
  }

  // toggleMenuCategory
  toggleMenuCategory(category: any, innerMenu?: any){

    this.activeMenuCategory = category;

    setTimeout(() => {

      var otherMenus: any = document.querySelectorAll('.body-menu-section')

      if(this.activeMenuCategory){

        for (let i = 0; i < otherMenus.length; i++) {

          var divIncludesClass = otherMenus[i].classList.contains(category)
          if(divIncludesClass){
            
            if(innerMenu){

              otherMenus[i].style.display = 'block';

            }else{

              otherMenus[i].style.display = otherMenus[i].style.display == 'block' ? 'none' : 'block';

            }

          }else{

            otherMenus[i].style.display = 'none';

          }
          
        }

      }
      
    }, 0);

  }

  // WEBSOCKET REQUEST
  setupSocketConnection() {

    var baseUrl = 'https://vicpharm.milesbreed.com/'
    this.socket = io(baseUrl);

    var activeStore = JSON.parse(localStorage.getItem('pharm_user'));
    if(activeStore && activeStore._id){

      // activity websocket
      var endPointNewOrders = 'newOrder/'+activeStore.Store._id;

      this.socket.on(endPointNewOrders, (data) => {

        this.getNumberOfNewOrders();

      });
    }

  }

  // get new order
  getNumberOfNewOrders(){

    var httpSuccessText = 'OK'.toLowerCase();

    var route = 'OnlineSale/NewOrderNotification';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.numberOfNewOrders = res.newOrder
          this.numberOfApprovedOrders = res.approvedOrder
          this.numberOfPackedOrders = res.orderPack
          this.numberOfTransitOrders = res.transitOrder
          this.totalOrders = this.numberOfNewOrders + this.numberOfApprovedOrders + 
            this.numberOfPackedOrders + this.numberOfTransitOrders
          this.numberOfPrescriptionInvoices = res.prescriptionInvoice
        }

      }

    );

  }

}
