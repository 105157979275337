import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

   public records: any = [];
   public showSelectedRecord = false;
   public selectedRecord: any = {}
   public popOver: NgbPopover;
   
   // add category and sub category details
   public quickAddCategoryDetails: any = {
     categoryId: '',
     categoryName: '',
     subCategoryName: '',
     subStatus: false
   }

   public listOfCategory = [];


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getCategories();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // get all categories
  getCategories(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/GetCategories';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          var categories = res.Categories;

          this.records = [];

          for (let i = 0; i < categories.length; i++) {
            
            if(categories[i]){

              var category = {
                name: categories[i].name,
                subCategories:categories[i].subCategories,
                _id: categories[i]._id,
                firstLetter: categories[i].name.substring(0,1),
                products: categories[i].product
              };

              this.records.push(category);

            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // select record fnc
  selectRecord(record, initiator: any, position: any, popOver?: any){

    this.selectedRecord.name = record.name;
    this.selectedRecord.subCategories = record.subCategories;
    this.selectedRecord._id = record._id;
    this.selectedRecord.firstLetter = record.firstLetter;
    this.selectedRecord.products = record.products;

    this.togglePopover(position, initiator, popOver)
  
  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'

        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'

        }else{

          popOver.placement = 'bottom-left'

        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

  // show subcategory textbox 
  showSubCategory(event){

    this.quickAddCategoryDetails.subStatus = event.checked;

    if(this.quickAddCategoryDetails.subStatus){

      // select all major categories with no subcategory
      this.listOfCategory = [];

      for (let i = 0; i < this.records.length; i++) {
        
        if(this.records[i]){

          this.listOfCategory.push(this.records[i]);

        }
        
      }

    }

  }
  
  // quick add catgory fnc
  quickAddCategory(addCategoryForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/AddCategory';
    var requestData = {
      name: this.quickAddCategoryDetails.categoryName,
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Category created successfully.';
          this.toastNotification(requestResponse);

          addCategoryForm.resetForm();

          this.getCategories();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // quick add sub catgory fnc
  quickAddSubCategory(addSubCategoryForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/AddSubCategoy';
    var requestData = {
      name: this.quickAddCategoryDetails.subCategoryName,
      categoryId: this.quickAddCategoryDetails.categoryId,
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Sub category created successfully.';
          this.toastNotification(requestResponse);

          addSubCategoryForm.resetForm();

          this.getCategories();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // edit catgory fnc
  editCategory(editCategoryForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/EditCategory';
    var requestData = {
      categoryId: this.selectedRecord._id,
      name: this.selectedRecord.name,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Category updated successfully.';
          this.toastNotification(requestResponse);

          this.showSelectedRecord = false;

          this.getCategories();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
