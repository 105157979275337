import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../../functions/request.service';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-inventory-staff',
  templateUrl: './inventory-staff.component.html',
  styleUrls: ['./inventory-staff.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class InventoryStaffComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // staff details
  public staffId: any = null;


  // records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}
  public discountTypeList: any = ['none', 'bulk', 'single'];

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  //filter variables
  public searchData: any;

  constructor(
    private functionService: RequestService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((page)=>{

      if(page){

        if(page.id){

          this.staffId = page.id;

          this.getRecords();

        }
        
      }

    });

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/GetStaffInventory';
    var requestData = {
      staffId: this.staffId,
      page: this.pageIndex,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = [];

          var products = res.Inventory;

          for (let i = 0; i < products.length; i++) {
            
            if(products[i]){

              var product = {
                availableQuantity: products[i].availableQuantity,
                barCode: products[i].barCode,
                category: products[i].category,
                costPrice: products[i].costPrice,
                createdAt: products[i].createdAt,
                description: products[i].description,
                discount: products[i].discount,
                discountType: products[i].discountType,
                discountUnit: products[i].discountUnit,
                expiryDate: products[i].expiryDate,
                limitPrice: products[i].limitPrice,
                inventoryTypes: products[i].inventoryTypes,
                name: products[i].name,
                previousQuantity: products[i].previousQuantity,
                quantity: products[i].quantity,
                reOrderLimit: products[i].reOrderLimit,
                sellOnline: products[i].sellOnline,
                sellingPrice: products[i].sellingPrice,
                staffId: products[i].staffId,
                status: products[i].status,
                unitOfMeasurement: products[i].unitOfMeasurement,
                _id: products[i]._id,
              }

              this.records.push(product)

            }
            
          }

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord._id = record._id ? record._id : null
    this.selectedRecord.name = record.name ? record.name : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.expiryDate = record.expiryDate ? record.expiryDate : null
    this.selectedRecord.soldOut = record.soldOut ? record.soldOut : false
    this.selectedRecord.inventoryTypes = record.inventoryTypes ? record.inventoryTypes : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    } : {
      name: null,
      phoneNumber: null,
      id: null
    }
    this.selectedRecord.description = record.description ? record.description : null
    this.selectedRecord.category = record.category.length > 0 ? record.category[0].name : null
    this.selectedRecord.unitOfMeasurement = [];

    for (let i = 0; i < record.unitOfMeasurement.length; i++) {

      if(record.unitOfMeasurement[i]){

        var measurement = record.unitOfMeasurement[i];

        var unit = {
          name: measurement,
          quantity: record.quantity ? record.quantity[measurement] : null,
          reorderLimit: record.reOrderLimit ? record.reOrderLimit[measurement] : null,
          costPrice: record.costPrice ? record.costPrice[measurement] : null,
          sellingPrice: record.sellingPrice ? record.sellingPrice[measurement] : null,
          limitPrice: record.limitPrice ? record.limitPrice[measurement] : null,
          barCode: record.barCode ? record.barCode[i] : null,
          discountType: record.discountType ? record.discountType[measurement] : null,
          discountUnit: record.discountUnit ? record.discountUnit[measurement] : null,
          discountPercentage: record.discount ? record.discount[measurement] : null,
          availableQuantity: record.availableQuantity ? record.availableQuantity[measurement] : null,
        };

        this.selectedRecord.unitOfMeasurement.push(unit)

      }
      
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

}
