<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                List of new Orders
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Filter current record"  />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'" [ngbPopover]="popContent" 
            [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Order details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-list-section">
                        <div class="item-section" *ngFor="let x of selectedRecord.products">
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Product
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Measurement
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.unitOfMeasurement}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Selling Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.quantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Total
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.totalAmount | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Invoice ID
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.invoiceId}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Total amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.totalAmount | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Shipping fee
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.shippingFee | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                VAT
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.vat | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Payment Option
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.paymentOption == 'Approve'">
                                Card
                            </span>
                            <span *ngIf="selectedRecord.paymentOption !== 'Approve'">
                                {{selectedRecord.paymentOption}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Has Prescription?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="!selectedRecord.continueApproval">YES</b>
                                <b *ngIf="selectedRecord.continueApproval">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Order Approved?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.storeApproval">YES</b>
                                <b *ngIf="!selectedRecord.storeApproval">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date Ordered
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Customer
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.customer.name}} ({{selectedRecord.customer.phoneNumber}})
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Delivery Location
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.deliveryLocation.locationName}}
                            </span>
                        </div>
                    </div>
                    <div class="btn-details-section" *ngIf="!selectedRecord.storeApproval">
                        <div class="left-btn-details-section">
                            <button mat-flat-button (click)="togglePopover(popOver, true)">
                                Approve Order
                            </button>
                        </div>
                        <div class="right-btn-details-section">
                            <button mat-flat-button (click)="togglePopover(popOver, false)">
                                Cancel Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:searchData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord.id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        &#8358;{{x.totalAmount | number: '.2'}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        <b *ngFor="let y of x.productDetails;">
                                            {{y.name}}, 
                                        </b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Confirmation your action
            </span>
        </div>
        <div class="down-popover-section">
            <div class="approve-prescription-section" *ngIf="selectedRecord.action">
                <div class="approve-section" *ngIf="selectedRecord.continueApproval">
                    <div class="text-section">
                        <span>
                            Please click the button below if you are sure you want to approve 
                            the order with invoice id as <b>{{selectedRecord.invoiceId}}</b>.
                        </span>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button (click)="approveOrder()">
                            Confirm
                            
                        </button>
                    </div>
                </div>
                <div class="prescription-section" *ngIf="!selectedRecord.continueApproval">
                    <div class="text-section">
                        <span>
                            The order with invoice id as <b>{{selectedRecord.invoiceId}}</b> 
                            includes a/some product(s) that needs prescription approval.
                        </span>
                    </div>
                    <div class="popover-btn-section" (click)="goToPage('invoices/prescription')">
                        <button mat-flat-button>
                            View
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="cancel-section" *ngIf="!selectedRecord.action">
                <form name="cancelForm" #cancelForm="ngForm" (ngSubmit)="cancelForm.form.valid && cancelOrder(cancelForm)">
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Reason for cancelling
                            </span>
                        </div>
                        <div class="control-section">
                            <select name="comment" [(ngModel)]="cancelDetails.comment" required #comment="ngModel" required>
                              <option value="{{x}}" *ngFor="let x of reasonsForCancelling;">{{x}}</option>
                            </select>
                            <mat-error *ngIf="cancelForm.submitted && comment.invalid">
                                Please enter a valid reason
                            </mat-error>
                        </div>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button>
                            Confirm
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>