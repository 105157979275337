<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Expired Products record
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Expired Products
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('expired')" [class.activeMenu]="activeInnerMenu == 'expired'">
                <span>
                    <fa-icon icon="stopwatch"></fa-icon> Expire Product
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of expired Products</b>
                    <b *ngIf="activeInnerMenu == 'expired'">Expire a Product</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Record details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section">
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Product
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.product.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-list-section">
                                        <div class="item-section" *ngFor="let x of selectedRecord.measurements">
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Measurement
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{x.name}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Quantity
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{x.quantity | number}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Price At Expiration
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        &#8358; {{x.priceAtExpiration | number:'.2'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Reason for expiration
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.comment}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Date of Expiration
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.dateExpired | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.staff.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Date of Action
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="records.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="records.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of records; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                                    [class.activeRecord]="x._id == selectedRecord._id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.productId.name}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            Expiry Date: {{x.expiryDate | date : 'dd MMM, yyyy '}}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span>
                                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-section">
                                    <div class="pagination-text" (click)="getPage(1)">
                                        <span>
                                            First
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(-1)">
                                        <span>
                                            Prev
                                        </span>
                                    </div>
                                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                        <span>
                                            {{x}}
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(1)">
                                        <span>
                                            Next
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="getPage(noOfPages)">
                                        <span>
                                            Last
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'expired'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchData" autocomplete="off" 
                                    [(ngModel)]="searchData" placeholder="Search by product name" 
                                    (keyup)="searchEvent($event)" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                                (click)="chooseProduct(x)" [class.activeSearchItemSection]="x._id == expiryDetails.productId">
                                    <div class="top-search-item-section">
                                        <span>
                                            {{x.name}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            {{x.category.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="expireForm" #expireForm="ngForm" class="form" *ngIf="expiryDetails.productId"
                            (ngSubmit)="expireForm.form.valid && processExpiration(expireForm)">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Unit
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="unit" [(ngModel)]="expiryDetails.unit" required 
                                            #unit="ngModel" (selectionChange)="chooseProductMeasurement($event)">
                                                <mat-option value="{{x.name}}" *ngFor="let x of expiryDetails.measurement">
                                                    {{x.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="expireForm.submitted && unit.invalid">
                                                Please enter a valid unit
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Available Quantity
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="availableQty" readonly
                                            [(ngModel)]="expiryDetails.activeUnit.availableQty" #availableQty="ngModel" />
                                            <mat-error *ngIf="expireForm.submitted && availableQty.invalid">
                                                Please enter a valid Quantity
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Expired Quantity
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="qty" #qty="ngModel"
                                            max="{{expiryDetails.activeUnit.availableQty}}"
                                            [(ngModel)]="expiryDetails.activeUnit.qty"  />
                                            <mat-error *ngIf="expireForm.submitted && qty.invalid" >
                                                Please enter a valid Quantity
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="big-form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Reason for expiration
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="comment" required #comment="ngModel"
                                            [(ngModel)]="expiryDetails.comment"  >
                                            <mat-error *ngIf="expireForm.submitted && comment.invalid">
                                                Please select a valid reason
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Expire
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>