import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  // cart variables
  public cartNumber: any = '0';
  public cart: any = [];
  public cartInitializedArray: any = [];
  public cartTotalAmount: any = 0;
  public listOfCarts: any = [];
  public cartIndex = -1;

  // CUSTOMER DETAILS VARIABLES
  public addCustomerDetailsStatus: any = false;
  public customerDetails: any = {
    id:'',
    phoneNumber: '',
    fullName: '',
  };
  public showCustomerDetailsStatus: any = false;
  public statusOfDelivery = [
    {
      value: '1',
      text: 'yes'
    },
    {
      value: '0',
      text: 'no'
    }
  ];
  public processedCheckoutData: any = {
    customer: null,
    deliveryStatus: false,
    locationName: null,
    longitude: null,
    latitude: null,
    country: 'nigeria',
    state: "federal capital territory",
    wholesaleToken: null
  }
  public listOfStates: any = [
    "abia",
    "adamawa",
    "akwa Ibom",
    "anambra",
    "bauchi",
    "bayelsea",
    "benue",
    "borno",
    "cross River",
    "delta",
    "ebonyi",
    "edo",
    "ekiti",
    "enugu",
    "gombe",
    "imo",
    "jigawa",
    "kaduna",
    "kano",
    "katsina",
    "kebbi",
    "kogi",
    "kwara",
    "lagos",
    "nasarawa",
    "niger",
    "ogun",
    "ondo",
    "osun",
    "oyo",
    "plateau",
    "rivers",
    "sokoto",
    "taraba",
    "yobe",
    "zamfara",
    "federal capital territory"
  ];

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getListCart();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get list of carts
  getListCart(){

    this.functionService.getCart().subscribe((cart)=>{

      this.listOfCarts = cart;

      if(this.listOfCarts && this.listOfCarts.length > 0){

        var selectedCart = this.listOfCarts.find(o=> o.status == true);
        var selectedCartIndex = this.listOfCarts.findIndex(o=> o.status == true);

        if(selectedCart){

          this.cart = selectedCart.cart;
          
          if(this.cart){

            this.cartNumber = this.cart.length;

            if(this.cartNumber > 0){

              this.initializeCart(this.cart)
              this.cartIndex = selectedCartIndex
    
            }
    
          }

        }
      }

    })

  };

  // initialize cart function
  initializeCart(cart){

    this.cartInitializedArray = [];

    for (let i = 0; i < cart.length; i++) {

      this.cartInitializedArray.push(cart[i]);
      
    }

    // calculate total amount of all products
    this.calculateTotalAllProductAmount();

  }

  // calculate total amount of all products
  calculateTotalAllProductAmount(){

    this.cartTotalAmount = 0;

    for (let i = 0; i < this.cartInitializedArray.length; i++) {
      
      if(this.cartInitializedArray[i]){

        this.cartTotalAmount = Number(this.cartTotalAmount) + Number(this.cartInitializedArray[i].cartAmount);

      }
      
    }

  }

  // remove item from list
  removeItem(index){

    // get active cart index
    var activeCartIndex:any = this.listOfCarts.findIndex(o=> o.status == true);

    // check if active cart exist
    if(activeCartIndex > -1){
      
      this.cartInitializedArray.splice(index, 1);

      this.listOfCarts[activeCartIndex].cart = this.cartInitializedArray
  
      this.functionService.setCart(this.listOfCarts);

    }else{
    
      // show notification
      var requestResponse = 'No cart is active.';
      this.toastNotification(requestResponse);

    }

  }

  // calculate amount of item from the quantity entered on unit of measurement
  calculateUnitAmount(measurement){

    var discountType = measurement.discountType;
    var discountPercentage = measurement.discountPercentage;
    var discountUnit = measurement.discountUnit;

    var wholesalesQuantity = measurement.wholesalesQuantity;
    var wholesalePrice = measurement.limitPrice;
    var retailPrice = measurement.sellingPrice

    var discountCharge = 0;

    if(discountType == 'none'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          measurement.cartAmount = wholesalePrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }else{

          measurement.cartAmount = retailPrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }

      }
      

    }else if(discountType == 'single'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          discountCharge = (discountPercentage/100) * wholesalePrice;
          measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }else{

          discountCharge = (discountPercentage/100) * retailPrice;
          measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }

      }

    }else if(discountType == 'bulk'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(measurement.cartQty >= discountUnit){

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            discountCharge = (discountPercentage/100) * wholesalePrice;
  
            measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }else{

            discountCharge = (discountPercentage/100) * retailPrice;
  
            measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }
  
        }else{

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            measurement.cartAmount = wholesalePrice * measurement.cartQty;

          }else{

            measurement.cartAmount = retailPrice * measurement.cartQty;

          }
  
  
        }

      }
      
    }

    this.calculateTotalAllProductAmount();
    
  }

  // CUSTOMER DETAILS FUNCTIONS

  // toggle customer add status
  toggleAddCustomer(status){

    this.addCustomerDetailsStatus = status;

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Customer/SearchCustomer';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Customer.length > 0){
            this.searchResult = res.Customer;
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select customer fnc
  selectCustomer(record?){

    this.customerDetails.id = record._id;
    this.customerDetails.phoneNumber = record.phoneNumber;
    this.customerDetails.fullName = record.fullName;

    this.useSelectedCustomer();

  }

  // add customer
  addCustomer(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var numberPrefix = '+234';
    var extractedNumber = this.customerDetails.phoneNumber.substring(1, this.customerDetails.phoneNumber.length);
    var customerPhoneNumber = numberPrefix+extractedNumber;

    var route = 'Customer/AddCustomer';
    var requestData = {
      fullName: this.customerDetails.fullName,
      phoneNumber: customerPhoneNumber
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Customer details added successfully.';
          this.toastNotification(requestResponse);

          this.customerDetails.id = res.customer._id;

          this.checkProductTotalQuantity()
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  useSelectedCustomer(){

    if(this.customerDetails.id){

      this.checkProductTotalQuantity()

    }else{
      
      var requestResponse = 'Select the customer you want to sell to.';
      this.functionService.showNotification(requestResponse);

    }
    
  }

  // check product quantity
  checkProductTotalQuantity(){

    var passQuantities = false

    for (let i = 0; i < this.cartInitializedArray.length; i++) {
      
      if(this.cartInitializedArray[i].cartQty > this.cartInitializedArray[i].totalQuantity){

        passQuantities = false;
        break;

      }else{

        passQuantities = true;

      }
      
    }

    if(passQuantities){

      this.continueAfterCustomer(true)

    }else{

      var requestResponse = 'One of the products has a quantity greater than its available quantity.';
      this.toastNotification(requestResponse);

    }

  }

  // continue after selecting customer
  continueAfterCustomer(status){

    this.showCustomerDetailsStatus = status

    if(status){

      this.processedCheckoutData = {
        customer: this.customerDetails,
        deliveryStatus: false,
        locationName: null,
        longitude: null,
        latitude: null,
        country: 'nigeria',
        state: "federal capital territory",
        wholesaleToken: null
      }
      
      this.calculateTotalAllProductAmount()

    }

  }

  // process cart
  processCart(){
     
    var passDelivery = false;

    if(this.processedCheckoutData.deliveryStatus){

      if(this.processedCheckoutData.locationName && this.processedCheckoutData.state && 
      this.processedCheckoutData.country){

        passDelivery = true

      }

    }else{

      passDelivery = true;

    }

    if(passDelivery){

      var orderDetails = [];
      for (let i = 0; i < this.cartInitializedArray.length; i++) {
        
        orderDetails.push({
          id: this.cartInitializedArray[i].id,
          quantity: this.cartInitializedArray[i].cartQty,
          unitOfMeasurement: this.cartInitializedArray[i].measurement
        })

      }

      this.generateInvoice(orderDetails)

    }else{

      var requestResponse = 'Please provide the delivery address.';
      this.toastNotification(requestResponse);

    }

  }

  // generate invoice
  generateInvoice(orderDetails){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GenerateSalesInvoice';
    var requestData = {
      orderDetails: orderDetails,
      storePickUp: !this.processedCheckoutData.deliveryStatus? true: false,
      deliveryLocation: {
        street: this.processedCheckoutData.locationName,
        state: this.processedCheckoutData.state,
        country: this.processedCheckoutData.country,
      },
      contactPerson: {
        name: this.processedCheckoutData.customer.fullName,
        phoneNumber: this.processedCheckoutData.customer.phoneNumber,
      },
      salesToken: this.processedCheckoutData.wholesaleToken,
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.proceedToCheckout(res, requestData.storePickUp);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // proceed to checkout
  proceedToCheckout(res, storePickUp){

    var products = [];
    for (let i = 0; i < res.productDetails.length; i++) {

      products.push({
        name: res.productDetails[i].name,
        measurement: res.productDetails[i].unitOfMeasurement,
        cartQty: res.productDetails[i].quantity,
        cartAmount: res.productDetails[i].totalAmount,
        sellingPrice: res.productDetails[i].sellingPrice,
        discount: res.productDetails[i].discount,
      })
      
    }

    var checkout = {
      cart: products,
      cartIndex: this.cartIndex,
      cartTotalAmount: this.cartTotalAmount,
      customerDetails: this.customerDetails,
      server: {
        bankTransferId: res.bankTransferId,
        subTotal: res.subTotalAmount,
        totalAmount: res.totalAmount,
        vat: res.vat,
        vatPercentage: res.vatPercent,
        totalDiscount: res.totalDiscount,
        invoiceId: res.invoiceId,
        prescriptionProduct: res.prescriptionProduct,
        shippingFee: res.shippingFee,
        storePickUp: storePickUp
      }
    };

    this.functionService.setCheckout(checkout);
    this.goToPage('account/checkout');

  }

}
