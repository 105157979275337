<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Store Activities
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="no-data-section"  *ngIf="records.length < 1">
            <div class="img-section">
                <fa-icon icon="database"></fa-icon>
            </div>
            <div class="text-section">
                <span>
                    No record Found
                </span>
            </div>
        </div>
        <div class="data-section" *ngIf="records.length > 0">
            <div class="list-section">
                <div class="list-item-section" *ngFor="let x of records; let i = index" >
                    <div class="list-item-content" >
                        <div class="details-section">
                            <div class="top-details-section">
                                <span>
                                    {{x.event}}
                                </span>
                            </div>
                            <div class="middle-details-section">
                                <span>
                                    <b>
                                        {{x.staffId.personalInfo.firstName}} {{x.staffId.personalInfo.lastName}}
                                    </b>
                                </span>
                            </div>
                            <div class="down-details-section">
                                <span>
                                    {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-section">
                <div class="pagination-text" (click)="getPage(1)">
                    <span>
                        First
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(-1)">
                    <span>
                        Prev
                    </span>
                </div>
                <div class="pagination-text" [class.activePage]="pageIndex == x"
                *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                    <span>
                        {{x}}
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(1)">
                    <span>
                        Next
                    </span>
                </div>
                <div class="pagination-text" (click)="getPage(noOfPages)">
                    <span>
                        Last
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>