import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss']
})
export class StoreDetailsComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

  // user variable
  public storeLocation: any = {
    locationName: null,
    country: 'nigeria',
    state: "federal capital territory",
    longitude: null,
    latitude: null,
    feePerKm: null,
    maxKm: null,
    freeDeliveryKm: null,
    applyWholesalesPriceAtAmount: null,
    bankDetails: [
      {
        bankName: null,
        accountNumber: null,
        accountName: null,
      },
      {
        bankName: null,
        accountNumber: null,
        accountName: null,
      }
    ],
  }
  public listOfStates: any = [
    "abia",
    "adamawa",
    "akwa Ibom",
    "anambra",
    "bauchi",
    "bayelsea",
    "benue",
    "borno",
    "cross River",
    "delta",
    "ebonyi",
    "edo",
    "ekiti",
    "enugu",
    "gombe",
    "imo",
    "jigawa",
    "kaduna",
    "kano",
    "katsina",
    "kebbi",
    "kogi",
    "kwara",
    "lagos",
    "nasarawa",
    "niger",
    "ogun",
    "ondo",
    "osun",
    "oyo",
    "plateau",
    "rivers",
    "sokoto",
    "taraba",
    "yobe",
    "zamfara",
    "federal capital territory"
  ];

  public vatDetails: any = {
    vat: 0,
    vatId: null
  }

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getStoreLocation();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu
    if(menu == 'add'){
      this.getVat()
    }

  }

  // get store location
  getStoreLocation(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'StoreLocation/GetStoreLocation';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.storeLocation._id = res.storeLocation._id;
          this.storeLocation.feePerKm = res.storeLocation.feePerKm;
          this.storeLocation.freeDeliveryKm = res.storeLocation.freeDeliveryKm;
          this.storeLocation.maxKm = res.storeLocation.maxKm;
          this.storeLocation.applyWholesalesPriceAtAmount = res.storeLocation.applyWholesalesPriceAtAmount;
          this.storeLocation.bankDetails = res.storeLocation.bankDetails;
          this.storeLocation.locationName = res.storeLocation.locationName;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // edit storeLocation
  updateStoreLocation(storeLocationForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var bankDetails = [];
    for (let i = 0; i < this.storeLocation.bankDetails.length; i++) {
      
      bankDetails.push({
        bankName: this.storeLocation.bankDetails[i].bankName,
        accountNumber: this.storeLocation.bankDetails[i].accountNumber,
        accountName: this.storeLocation.bankDetails[i].accountName,
      })
      
    }

    var route = 'StoreLocation/UpdatePickupLocation';
    var requestData = {
      storeLocationId: this.storeLocation._id,
      locationName: this.storeLocation.locationName,
      street: this.storeLocation.locationName,
      country: this.storeLocation.country,
      state: this.storeLocation.state,
      longitude: this.storeLocation.longitude,
      latitude: this.storeLocation.latitude,
      feePerKm: this.storeLocation.feePerKm,
      maxKm: this.storeLocation.maxKm,
      freeDeliveryKm: this.storeLocation.freeDeliveryKm,
      applyWholesalesPriceAtAmount: this.storeLocation.applyWholesalesPriceAtAmount,
      bankDetails: bankDetails,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'store details update was successful.';
          this.toastNotification(requestResponse);

          this.getStoreLocation();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // get vat
  getVat(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Vat/GetVat';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.vatDetails.vat = res.vat.vat
          this.vatDetails.vatId = res.vat._id
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // add vat
  addVat(){

    if(this.vatDetails.vatId){

      this.startLoading();

      var httpSuccessText = 'OK'.toLowerCase();
      var requestResponse = '';

      var route = 'Vat/UpdateVat';
      var requestData = {
        vat: this.vatDetails.vat,
        vatId: this.vatDetails.vatId
      }

      this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
        (res: any)=>{

          this.stopLoading();

          if(res.status.toLowerCase() == httpSuccessText){

            requestResponse = 'VAT update was successful.';
            this.toastNotification(requestResponse);

            this.toggleInnerMenu('list')
            
          }else{
        
            requestResponse = res.message;
            this.toastNotification(requestResponse);
        
          }

        },(err)=>{

          this.stopLoading();

          requestResponse = err.error.message;
          this.toastNotification(requestResponse);

        }
      );

    }else{

      this.startLoading();

      var httpSuccessText = 'OK'.toLowerCase();
      var requestResponse = '';

      var route = 'Vat/AddVat';
      var requestDatas = {
        vat: this.vatDetails.vat
      }

      this.functionService.postTokenHttpRequestService(route, requestDatas).subscribe(
        (res: any)=>{

          this.stopLoading();

          if(res.status.toLowerCase() == httpSuccessText){

            requestResponse = 'VAT update was successful.';
            this.toastNotification(requestResponse);

            this.toggleInnerMenu('list')
            
          }else{
        
            requestResponse = res.message;
            this.toastNotification(requestResponse);
        
          }

        },(err)=>{

          this.stopLoading();

          requestResponse = err.error.message;
          this.toastNotification(requestResponse);

        }
      );

    }

  }

}
