<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Sales record
            </span>
        </div>
        <div class="filter-section">
            <div class="main-filter-text-section">
                <span>
                    search:
                </span>
            </div>
            <div class="left-filter-section" (click)="frompicker.open()">
                <div class="filter-text-section">
                    <span>
                        From
                    </span>
                </div>
                <div class="control-section">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="frompicker" name="frompicker" [(ngModel)]="selectedFromDate"
                        (dateChange)="dateChangeFnc($event, 'from')" >
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="icon-section">
                    <fa-icon icon="calendar-alt"></fa-icon>
                </div>
            </div>
            <div class="right-filter-section" (click)="topicker.open()">
                <div class="filter-text-section">
                    <span>
                        To
                    </span>
                </div>
                <div class="control-section">
                    <mat-form-field appearance="none">
                        <input matInput [matDatepicker]="topicker" name="topicker" [(ngModel)]="selectedToDate"
                        (dateChange)="dateChangeFnc($event, 'to')" >
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="icon-section">
                    <fa-icon icon="calendar-alt"></fa-icon>
                </div>
            </div>
        </div>
        <div class="btn-section">
            <div class="left-btn-section">
                <div class="search-section">
                    <div class="search-holder">
                        <div class="icon-section">
                            <fa-icon icon="search" matPrefix></fa-icon>
                        </div>
                        <div class="left-search-section">
                            <mat-form-field appearance="none" class="searchId">
                                <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                                placeholder="Filter current record"  />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-btn-section">
                <button mat-flat-button [ngbPopover]="popContent" (click)="togglePopover(popOver, 'export')"
                triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                    Export Record
                    
                </button>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Sales details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section" [ngbPopover]="popContent" 
                triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                    <div class="item-list-section">
                        <div class="item-section" *ngFor="let x of selectedRecord.products">
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Product
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.productName}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Measurement
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.unitOfMeasurement}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Selling Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.quantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Total
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.totalAmount | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Receipt ID
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.receiptId}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Total amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.totalAmount | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section" *ngIf="selectedRecord.totalAmountAfterReturn > 0">
                        <div class="label-section">
                            <span>
                                Total amount after return
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.totalAmountAfterReturn | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Sales Option
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.typeOfsales}}
                            </span>
                        </div>
                    </div>
                    <div class="item-list-section">
                        <div class="item-details-section" *ngFor="let x of selectedRecord.paymentType; let j=index">
                            <div class="label-section">
                                <span>
                                    Payment Option [{{1+j}}]
                                </span>
                            </div>
                            <div class="value-section">
                                <span>
                                    {{x.paymentType}}: &#8358; {{x.amount | number:'.2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Staff
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.staff.name}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Customer
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.customer">
                                {{selectedRecord.customer.personalInfo.fullName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date Sold
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="item-list-section">
                        <div class="item-details-section" *ngFor="let x of selectedRecord.returnProduct; let j=index">
                            <div class="label-section">
                                <span>
                                    sale Return [{{1+j}}]
                                </span>
                            </div>
                            <div class="value-section">
                                <span style="cursor: pointer;" (click)="goToReturnPage(x._id)">
                                    {{x._id}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="btn-details-section">
                        <div class="left-btn-details-section">
                            <button mat-flat-button printSectionId="print-section" ngxPrint>
                                Print receipt
                                
                            </button>
                        </div>
                        <div class="right-btn-details-section">
                            <button mat-flat-button (click)="togglePopover(popOver, 'reverse')">
                                Reverse
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:searchData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord._id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        &#8358;{{x.totalAmount | number: '.2'}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        <b *ngFor="let y of x.productDetails;">
                                            {{y.productName}}, 
                                        </b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                    <div class="pagination-text" (click)="clearFilter()" *ngIf="dateFilterShow">
                        <span>
                            Clear
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content" *ngIf="popOverAction == 'export'">
        <div class="top-popover-section">
            <span>
                Export Sales Record
            </span>
        </div>
        <div class="down-popover-section">
            <form name="exportForm" (ngSubmit)="exportForm.form.valid && exportRecord(exportForm)"
            #exportForm="ngForm"  class="popoevr-form">
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            From Date
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="date" name="exportDateFrom" 
                            [(ngModel)]="exportDetails.exportDateFrom" #exportDateFrom="ngModel" required/>
                            <mat-error *ngIf="exportForm.submitted && exportDateFrom.invalid">
                                Please enter a valid from Date
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            To Date
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="date" name="exportDateTo" 
                            [(ngModel)]="exportDetails.exportDateTo" #exportDateTo="ngModel" required/>
                            <mat-error *ngIf="exportForm.submitted && exportDateTo.invalid">
                                Please enter a valid to Date
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="popover-btn-section">
                    <button mat-flat-button>
                        Export
                        <fa-icon icon="angle-right"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="popover-content" *ngIf="popOverAction == 'reverse'">
        <div class="top-popover-section">
            <span>
                Confirmation your action
            </span>
        </div>
        <div class="down-popover-section">
            <div class="approve-prescription-section">
                <div class="prescription-section">
                    <div class="text-section">
                        <span>
                            Please click the button if you are sure you want to
                            reverse the sale with receipt id as <b>{{selectedRecord.receiptId}}</b>.
                        </span>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button (click)="reverseSales()">
                            Reverse sale
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div id="print-section">
    <div id="head-section" style="height: auto; width: 100%; border-bottom: 5px solid black">
        <div id="logo-section" style="height: 50px; width: 100%; position: relative;">
            <img id="logo-image" src="./assets/img/logo.png" alt="image" style="position: absolute; top: 50%; left: 50%; 
            transform: translate(-50%,-50%); height: 44.5px; width: 131px;">
        </div>
        <div id="name-section" style="height: 20px; width: 100%; position: relative;">
            <span style="position: absolute; top: 15%; left: 0; width: 100%; height: 70%; font-size: 14px; font-family: bold; 
            text-align: center;">
                VICPHARM INDUSTRIES LIMITED.
            </span>
        </div>
        <div id="location-section" style="height: 30px; width: 100%; position: relative;">
            <span style="position: absolute; top: 5%; left: 0; width: 100%; height: 90%; font-size: 11px; font-family: normal; 
            text-align: center;">
                Suite AO10 & AO11 Zitel Plaza, Plot 227, P.O.W. Mafemi Crescent, Utako, Abuja.
            </span>
        </div>
    </div>
    <div id="body-section" style="height: auto; width: 100%; border-bottom: 5px solid black;">
        <div id="staff-date-section" style="float: left; height: auto; width: 100%; margin: 0; 
        border-bottom: 2.5px solid black;">
            <div id="label-value-section" style="float: left; height: 20px; width: 100%; padding-top: 10px">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Receipt ID
                    </span>
                </div>
                <div id="value-section" style="text-transform: none; float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.receiptId}}
                    </span>
                </div>
            </div>
            <div id="label-value-section" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Date/Time
                    </span>
                </div>
                <div id="value-section" style="text-transform: capitalize;float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.date}}
                    </span>
                </div>
            </div>
            <div id="label-value-section" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Cashier
                    </span>
                </div>
                <div id="value-section" style="text-transform: capitalize;float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.cashier}}
                    </span>
                </div>
            </div>
            <div id="label-value-section" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Customer
                    </span>
                </div>
                <div id="value-section" style="text-transform: capitalize;float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal; overflow-wrap: break-word;">
                        {{receiptData.customer}}
                    </span>
                </div>
            </div>
        </div>
        <div id="item-head-section" style="float: left; height: 20px; width: 100%; margin: 0; border-bottom: 2.5px solid black;">
            <div id="desc-section" style="float: left; height: 20px; width: 25%; position: relative; text-align: left;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Item
                </span>
            </div>
            <div id="qty-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: center;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Qty
                </span>
            </div>
            <div id="price-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: center;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Price
                </span>
            </div>
            <div id="total-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: right;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Total
                </span>
            </div>
        </div>
        <div id="item-body-section" style="float: left; height: auto; width: 100%; margin: 0; border-bottom: 1.30px solid black;"
        *ngFor="let x of receiptData.products">
            <div id="desc-section" style="float: left; height: auto; width: 25%; position: relative; text-align: left; padding: 2.5px 0;">
                <span style=" height: auto; width: 100%;  font-size: 12px; font-weight: normal; 
               overflow-wrap: break-word; text-transform: capitalize;">
                    {{x.name}} ({{x.measurement}})
                </span>
            </div>
            <div id="qty-section" style="float: left; height: auto; width: 25%; position: relative; text-align: center; padding: 2.5px 0;">
                <span style=" height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    {{x.quantity |  number}}
                </span>
            </div>
            <div id="price-section" style="float: left; height: auto; width: 25%; position: relative; text-align: center; padding: 2.5px 0;">
                <span style="height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    &#8358;{{x.sellingPrice | number:".2"}}
                </span>
            </div>
            <div id="total-section" style="float: left; height: auto; width: 25%; position: relative; text-align: right; padding: 2.5px 0;">
                <span style="height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    &#8358;{{x.totalAmount | number:".2"}}
                </span>
            </div>
        </div>
        <div id="item-total-section" style="float: left; height: auto; width: 100%; margin: 0; border-bottom: 5px solid black;">
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        Total Amount
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.cartTotalAmount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        VAT 
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.vatAmount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        Discount
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.discount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        NET Total
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.netTotal | number:".2"}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div id="footer-section" style="height: auto; width: 100%;">
        <div id="advert-section" style="float: left; height: 20px; width: 100%; position: relative; padding-top: 10px">
            <span style="position: absolute; top: 10%; left: 0; width: 100%; height: 80%; font-size: 11px; font-family: normal; text-align: center;">
                Please note that this is a re-printed receipt. For further enquiries about this sale, visit the office address.
            </span>
        </div>
    </div>    
</div>