<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Staff with Ecommerce Notification Access
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Access List
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('expired')" [class.activeMenu]="activeInnerMenu == 'expired'">
                <span>
                    <fa-icon icon="sliders-h"></fa-icon> Toggle Access
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of Staff with Ecommerce Notification Access</b>
                    <b *ngIf="activeInnerMenu == 'expired'">Grant Staff Access for Ecommerce Notification </b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Record details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.staff.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Date Added
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="btn-details-section">
                                        <div class="full-btn-details-section">
                                            <button mat-flat-button (click)="removeStaff()">
                                                Remove Access
                                                
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="ecommerceNotifications.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="ecommerceNotifications.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of ecommerceNotifications; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                                    [class.activeRecord]="x._id == selectedRecord._id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.staffId.personalInfo.firstName}} 
                                                        {{x.staffId.personalInfo.lastName}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span>
                                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'expired'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchData" autocomplete="off" 
                                    [(ngModel)]="searchData" placeholder="Search by staff number" 
                                    (keyup)="searchEvent($event)" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                                (click)="chooseStaff(x)" [class.newListItemSection]="x._id == addDetails.id">
                                    <div class="top-search-item-section">
                                        <span>
                                            {{x.firstName}} {{x.lastName}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            {{x.phoneNumber}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="staffForm" #staffForm="ngForm" class="form" *ngIf="addDetails.id"
                            (ngSubmit)="staffForm.form.valid && addStaff(staffForm)">
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Staff Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="name" readonly
                                            [(ngModel)]="addDetails.name" #name="ngModel" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Add
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>