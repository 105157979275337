import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import * as moment from 'moment';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.scss']
})
export class PayInvoiceComponent implements OnInit {

  public popOver: NgbPopover;
  public showSellButton: any = true;

  // checkout variables
  public checkoutDetails: any = {
    cart: [],
    cartTotalAmount: 0,
    customerDetails: {},
    metadata: {
      vatPercentage: 0,
      vatAmount: 0,
      totalAmountAfterVat: 0,
      paymentOption: [
        {
          paymentType: 'POS',
          amount: null
        },
        {
          paymentType: 'Transfer',
          amount: null
        },
        {
          paymentType: 'Cash',
          amount: null
        },
      ],
      salesType: null,
      creditAmountPaid: 0,
      creditFinalDate: null,
      bankTransferId: null,
      totalDiscount: null,
      invoiceId: null,
      shippingFee: null,
      cartTotalWithoutDiscount: null,
      storePickUp: null
    }
  };
  public paymentOptions = [
    'POS', 'Transfer', 'Cash'
  ];
  public salesOptions = [
    {
      name:'full payment',
      value: 'fullPayment'
    },
    {
      name:'credit sales',
      value: 'creditSales'
    }
  ];
  public confirmCustomer: any = false;

  // print variables
  public printStatus: any = false;
  public receiptData: any = {
    receiptId: '',
    cashier: '',
    products: [],
    adverts: [],
    netTotal: 0,
    vatPercentage: 0,
    vatAmount: 0,
    cartTotalAmount: 0,
    discountTotal: 0,
    date: null,
    cartTotalWithoutDiscount: null,
    shippingFee: null
  }

  // rider
  public riderResult: any = [];
  public assignDetails: any = {
    requestData: {
      riderId: null
    },
    riderName: null,
    assigned: false
  }

  // popover data
  public selectedAction: any = {}

  constructor(
    private functionService: RequestService,
    private printService: PrintService
  ) { 

  }

  ngOnInit(): void {

    this.getInvoice();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover, action?){

    if(popOver){

      if(action == 'rider'){
        
        if(this.riderResult && this.riderResult.length == 0){
          
          this.getAllRiders();
    
        }
        
      }

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;

    }

    this.selectedAction.action = action
  }

  // get checkout details
  getInvoice(){

    this.functionService.getInvoice().subscribe((invoice)=>{

      if(invoice && invoice.products && invoice.products.length > 0){

        this.initializeCheckout(invoice)

      }

    })

  };

  // initialize checkout
  initializeCheckout(checkout){

    // get adverts from local storage
    this.receiptData.adverts = JSON.parse(localStorage.getItem('pharm_adverts'));

    // assign cart details to checkout details
    this.checkoutDetails.cart = checkout.products
    this.checkoutDetails.customerDetails = checkout.customer

    // get vat amount and totalAmountAfterVat
    this.checkoutDetails.metadata.bankTransferId = checkout.bankTransferId;
    this.checkoutDetails.metadata.cartTotalWithoutDiscount = checkout.subTotal;
    this.checkoutDetails.metadata.totalAmountAfterVat = checkout.totalAmount;
    this.checkoutDetails.metadata.vatAmount = checkout.vat;
    this.checkoutDetails.metadata.vatPercentage = checkout.vatPercentage
    this.checkoutDetails.metadata.totalDiscount = checkout.totalDiscount;
    this.checkoutDetails.metadata.invoiceId = checkout.invoiceId;
    this.checkoutDetails.metadata.shippingFee = checkout.shippingFee;
    this.checkoutDetails.metadata.storePickUp = checkout.storePickUp;

  }

  // structure creditsales
  structureCreditSales(){

    if(this.checkoutDetails.metadata.salesType == 'creditSales'){

      this.checkoutDetails.metadata.creditAmountPaid = 0;
      this.checkoutDetails.metadata.creditFinalDate = null;

      this.calculatePaidAmount();

    }

  }

  // calculate paid amount
  calculatePaidAmount(){

    this.checkoutDetails.metadata.creditAmountPaid = 0;

    for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {
      
      if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

        var paymentAmount = this.checkoutDetails.metadata.paymentOption[i].amount;
  
        this.checkoutDetails.metadata.creditAmountPaid = this.checkoutDetails.metadata.creditAmountPaid + paymentAmount;
  
      }
        
    }

  }

  // search customer
  searchCustomer(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Customer/SearchCustomer';
    var requestData = {
      keywords: this.checkoutDetails.customerDetails.phoneNumber,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          if(res.Customer){

            var customer = res.Customer.find(o=> o.personalInfo.phoneNumber == this.checkoutDetails.customerDetails.phoneNumber)
            if(customer && customer._id){
              
              this.checkoutDetails.customerDetails.id = customer._id;
              this.confirmCustomer = true;

            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // clear checkout andf cart array
  clearCheckoutAndCart(){

    var cart = [
      {
        status: true,
        cart:[]
      }
    ];
    var checkout = [];

    this.confirmCustomer = false;
    this.functionService.setCart(cart);
    this.functionService.setCheckout(checkout);

    this.goToPage('invoices/generated');

  }

  // proceed with sale
  processSale(){

    var requestResponse = '';
    
    if(this.checkoutDetails.metadata.salesType){

      var passPaymentAmount =  false;

      var paymentOption = [];
      var paymentAmount = 0;

      if(this.checkoutDetails.metadata.salesType == 'fullPayment'){

        // check payment option
        var passPayment = false;

        for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {
          
          if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

            passPayment = true;
            break;

          }
        }

        if(passPayment){

          for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {

            if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

              paymentOption.push(this.checkoutDetails.metadata.paymentOption[i]);
              paymentAmount = Number(paymentAmount) + Number(this.checkoutDetails.metadata.paymentOption[i].amount)

            }
            
          }

          if(paymentAmount == this.checkoutDetails.metadata.totalAmountAfterVat){

            passPaymentAmount= true;

            this.checkoutDetails.metadata.creditAmountPaid = 0;
            this.checkoutDetails.metadata.creditFinalDate = null;

          }else{

            passPaymentAmount= false;

            requestResponse = 'Please make sure that the paid amount corresponds to the total amount';
            this.toastNotification(requestResponse);

          }
        
        }else{

          passPaymentAmount= false;
        
          requestResponse = 'Please select payment option.';
          this.toastNotification(requestResponse);
        
        }


      }else if(this.checkoutDetails.metadata.salesType == 'creditSales'){

        if(this.checkoutDetails.metadata.creditAmountPaid > -1 && this.checkoutDetails.metadata.creditFinalDate){

          for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {

            if(this.checkoutDetails.metadata.paymentOption[i].amount > -1){

              paymentOption.push(this.checkoutDetails.metadata.paymentOption[i]);
              paymentAmount = Number(paymentAmount) + Number(this.checkoutDetails.metadata.paymentOption[i].amount)

            }
            
          }

          if(paymentAmount == this.checkoutDetails.metadata.creditAmountPaid && 
          this.checkoutDetails.metadata.creditAmountPaid < this.checkoutDetails.metadata.totalAmountAfterVat){

            passPaymentAmount= true;

          }else{

            passPaymentAmount= false;

            requestResponse = 'Please make sure that the paid amount corresponds to the total amount';
            this.toastNotification(requestResponse);

          }

        }else{

          passPaymentAmount= false;

          requestResponse = 'Please select Amount Paid and Payment Final Date.';
          this.toastNotification(requestResponse);

        }

      }

      if(passPaymentAmount){

        for (let j = 0; j < paymentOption.length; j++) {
          
          if(paymentOption[j].amount < 0 || paymentOption[j].amount == null){

            paymentOption[j].amount = 0

          }
          
        }

        var requestData = {
          invoiceId: this.checkoutDetails.metadata.invoiceId,
          typeOfsales: this.checkoutDetails.metadata.salesType,
          paymentType: paymentOption,
          expectedDate: this.checkoutDetails.metadata.creditFinalDate,
          paid: this.checkoutDetails.metadata.creditAmountPaid,
          CustomerId: this.checkoutDetails.customerDetails.id,
          riderId: this.assignDetails.requestData.riderId,
        };

        this.sell(requestData);

      }
  
    }else{
  
      requestResponse = 'Please select sales type.';
      this.toastNotification(requestResponse);
  
    }

  }

  // sell request
  sell(requestData){

    this.showSellButton = false;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/payForInvoice';

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Sale completed successfully.';
          this.toastNotification(requestResponse);

          this.receiptData.receiptId = res.receiptId
          this.togglePrintStatus(true);
           
        }else{

          this.showSellButton = true;
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.showSellButton = true;

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // toggle print status
  togglePrintStatus(status){

    this.printStatus = status

    if(this.printStatus){

      this.getReceiptData()
      
    }

  }

  // structure receipt data
  getReceiptData(){

    // set cahsier name
    var user = JSON.parse(localStorage.getItem('pharm_user'));
    this.receiptData.cashier = user.personalInfo.firstName;

    // set total price after tax, cart total amount and vat
    this.receiptData.vatPercentage = this.checkoutDetails.metadata.vatPercentage
    this.receiptData.vatAmount = this.checkoutDetails.metadata.vatAmount
    this.receiptData.cartTotalAmount = this.checkoutDetails.cartTotalAmount
    this.receiptData.cartTotalWithoutDiscount = this.checkoutDetails.metadata.cartTotalWithoutDiscount
    this.receiptData.netTotal = this.checkoutDetails.metadata.totalAmountAfterVat
    this.receiptData.discount = this.checkoutDetails.metadata.totalDiscount;
    this.receiptData.shippingFee = this.checkoutDetails.metadata.shippingFee;

    // set products and discount
    this.receiptData.products = [];
    for (let i = 0; i < this.checkoutDetails.cart.length; i++) {
      
      if(this.checkoutDetails.cart[i]){

        var product = this.checkoutDetails.cart[i];

        var purcahsedProduct = {
          name: product.name,
          measurement: product.measurement,
          quantity: product.quantity,
          sellingPrice: product.sellingPrice,
          totalAmount: 0
        }

        purcahsedProduct.totalAmount = purcahsedProduct.quantity * purcahsedProduct.sellingPrice

        this.receiptData.products.push(purcahsedProduct)

      }
      
    }

    // set date
    this.receiptData.date = moment(new Date()).format('YYYY-MM-D, hh:mm:ss A')
  }

  // RIDER
  // search request
  getAllRiders(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Rider/GetAllRider';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          if(res.staffs.length > 0){
            this.riderResult = res.staffs;
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // complete Sale After Rider has been assigned
  completeSaleAfterRider(){

    this.assignDetails.requestData.riderId = this.selectedAction.rider
    this.assignDetails.assigned = true;
    if(this.popOver){
      this.popOver.close()
    }

  }

}
