import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticatedUser = new BehaviorSubject<boolean>(null);

  constructor() { }

  checkAuthenticated() {

    var authenticated =  false;

    var user = JSON.parse(localStorage.getItem('pharm_user'));
    if(user && user._id){

      this.setAuthenticatedUser(user);
      authenticated =  true;
      
    }

    return authenticated;
  }

  // set and get user functions
  getAuthenticatedUser(){
    return this.authenticatedUser.asObservable();
  }

  setAuthenticatedUser(user){
    this.authenticatedUser.next(user);
  }

  logout(){

    localStorage.removeItem('pharm_token')
    localStorage.removeItem('pharm_user')
    localStorage.removeItem('pharm_customer')
    localStorage.removeItem('pharm_silent_product')

    this.setAuthenticatedUser(null);

  }

}
