<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                edit Store details
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="pen"></fa-icon> Edit Store
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="sliders-h"></fa-icon> Change VAT
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('store/all')">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> All Store
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">edit Store details</b>
                    <b *ngIf="activeInnerMenu == 'add'">Change VAT on Store</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="storeLocationForm" #storeLocationForm="ngForm" class="form"
                            (ngSubmit)="storeLocationForm.form.valid && updateStoreLocation(storeLocationForm)">
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Office Address
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="locationName" 
                                            [(ngModel)]="storeLocation.locationName" #locationName="ngModel" />
                                            <mat-error *ngIf="storeLocationForm.submitted && locationName.invalid">
                                                Please enter a valid office address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            State
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="state" [(ngModel)]="storeLocation.state" required 
                                            #state="ngModel" style="text-transform: capitalize;">
                                                <mat-option style="text-transform: capitalize;" value="{{x}}" 
                                                *ngFor="let x of listOfStates">{{x}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="storeLocationForm.submitted && state.invalid">
                                                Please enter a valid state
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Fee per Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="feePerKm" 
                                            [(ngModel)]="storeLocation.feePerKm" #feePerKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && feePerKm.invalid">
                                                Please enter a valid fee per Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Maximum Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="maxKm" 
                                            [(ngModel)]="storeLocation.maxKm" #maxKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && maxKm.invalid">
                                                Please enter a valid maximum Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Free Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="freeDeliveryKm" 
                                            [(ngModel)]="storeLocation.freeDeliveryKm" #freeDeliveryKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && freeDeliveryKm.invalid">
                                                Please enter a valid free Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Maximum Amount Benchmark to activate Wholesale
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="applyWholesalesPriceAtAmount" 
                                            [(ngModel)]="storeLocation.applyWholesalesPriceAtAmount" 
                                            #applyWholesalesPriceAtAmount="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && 
                                            applyWholesalesPriceAtAmount.invalid">
                                                Please enter a valid amount
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="measurement-section" *ngFor="let x of storeLocation.bankDetails; let i = index">
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Bank Name ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'bank' + i}}" 
                                                [(ngModel)]="x.bankName"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Account Name ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'accountName' + i}}" 
                                                [(ngModel)]="x.accountName"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Account Number ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'accountNumber' + i}}"
                                                [(ngModel)]="x.accountNumber"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        update
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="addForm" #addForm="ngForm"  class="form"
                            (ngSubmit)="addForm.form.valid && addVat()">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            VAT Percentage
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="vat" #vat="ngModel"
                                            [(ngModel)]="vatDetails.vat" required/>
                                            <mat-error *ngIf="addForm.submitted && vat.invalid">
                                                Please enter a valid vat
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Change
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>