<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Staff Positions
            </span>
        </div>
        <div class="btn-section">
            
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Position list
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="plus"></fa-icon> Add Position
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of Staff Positions</b>
                    <b *ngIf="activeInnerMenu == 'add'">add Staff Position</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="no-data-section"  *ngIf="positions.length <= 0">
                            <div class="img-section">
                                <fa-icon icon="database"></fa-icon>
                            </div>
                            <div class="text-section">
                                <span>
                                    No position Found
                                </span>
                            </div>
                        </div>
                        <div class="data-section" *ngIf="positions.length > 0">
                            <div class="list-section">
                                <div class="list-item-section" *ngFor="let x of positions; let i = index" >
                                    <div class="list-item-content" (click)="selectRecord(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                                        <div class="icon-section">
                                            <div class="circle">
                                                <span>
                                                    {{x.firstLetter}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="details-section">
                                            <div class="top-details-section">
                                                <span>
                                                    {{x.positionName}}
                                                </span>
                                            </div>
                                            <div class="middle-details-section">
                                                <span>
                                                    <b>
                                                        {{x.roles.length |number}} role(s)
                                                    </b>
                                                </span>
                                            </div>
                                            <div class="down-details-section">
                                                <span>
                                                   
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && createPosition(addForm)"
                    class="form">
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Name
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="positionName" 
                                    [(ngModel)]="positionDetails.name" #positionName="ngModel" required/>
                                    <mat-error *ngIf="addForm.submitted && positionName.invalid">
                                        Please enter a valid position name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="role-list-section">
                            <mat-checkbox name="role{{i}}" [(ngModel)]="x.checked" *ngFor="let x of roles;let i = index"
                            [color]="'primary'" class="checkbox-section">
                            {{x.name}}
                            </mat-checkbox>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Add
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Position Details
            </span>
        </div>
        <div class="down-popover-section">
            <form name="editForm" #editForm="ngForm" (ngSubmit)="editForm.form.valid && editPosition(editForm)" 
            class="popoevr-form">
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            Name
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="name"
                            [(ngModel)]="selectedRecord.positionName" #name="ngModel"/>
                            <mat-error *ngIf="editForm.submitted && name.invalid">
                                Please enter a valid name
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="role-list-section">
                    <mat-checkbox name="status{{i}}" [(ngModel)]="x.checked" *ngFor="let x of roles; let i = index"
                    [color]="'primary'" class="checkbox-section">
                    {{x.name}}
                    </mat-checkbox>
                </div>
                <div class="popover-btn-section">
                    <button mat-flat-button>
                        Save
                        <fa-icon icon="angle-right"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>