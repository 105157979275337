<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Near Expired Inventory
            </span>
        </div>
        <div class="btn-section">
            <div class="filter-section">
                <div class="left-filter-section" (click)="frompicker.open()">
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput [matDatepicker]="frompicker" name="frompicker" [(ngModel)]="selectedFromDate"
                            (dateChange)="dateChangeFnc($event, 'from')" >
                            <mat-datepicker #frompicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="icon-section">
                        <fa-icon icon="calendar-alt"></fa-icon>
                    </div>
                </div>
                <div class="right-filter-section" (click)="topicker.open()">
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput [matDatepicker]="topicker" name="topicker" [(ngModel)]="selectedToDate"
                            (dateChange)="dateChangeFnc($event, 'to')" >
                            <mat-datepicker #topicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="icon-section">
                        <fa-icon icon="calendar-alt"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="no-data-section"  *ngIf="records.length <= 0">
            <div class="img-section">
                <fa-icon icon="database"></fa-icon>
            </div>
            <div class="text-section">
                <span>
                    No record Found
                </span>
            </div>
        </div>
        <div class="data-section" *ngIf="records.length > 0">
            <div class="list-section">
                <div class="list-item-section" *ngFor="let x of records; let i = index" >
                    <div class="list-item-content" (click)="selectRecord(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                        <div class="icon-section">
                            <div class="circle">
                                <span>
                                    {{x.firstLetter}}
                                </span>
                            </div>
                        </div>
                        <div class="details-section">
                            <div class="top-details-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="middle-details-section">
                                <span>
                                    <b>
                                        Expiry Date: <b>{{x.expiryDate | date : 'dd MMM, yyyy ':'UTC'}}</b>
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Clear Inventory
            </span>
        </div>
        <div class="down-popover-section">
            <form name="expireForm" #expireForm="ngForm" (ngSubmit)="expireForm.form.valid && expireInventory(expireForm)">
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            Product
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="name" [(ngModel)]="selectedRecord.name" 
                            #name="ngModel" required readonly />
                        </mat-form-field>
                    </div>
                </div>
                <div class="popover-btn-section">
                    <button mat-flat-button>
                        clear stock
                        <fa-icon icon="angle-right"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>