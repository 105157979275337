import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '5%',
      })),
      state('false', style({
        width: '30%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '5%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '5%', }),
        style({ width: '30%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '95%',
      })),
      state('false', style({
        width: '70%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '95%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '95%', }),
        style({ width: '70%', }),
      ])))
    ])
  ]
})
export class SellComponent implements OnInit {

  // cart variables
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'
  public cartNumber: any = '0';
  public cart: any = [];
  public listOfCarts: any = []

  // products variables
  public products: any = [];
  public popOver: NgbPopover;
  public selectedProduct: any = {}
  public activeMeasurement: any = {}

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // renderer variables
  public globalRender: any;


  constructor(
    private functionService: RequestService,
    private renderer: Renderer2,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.getListCart();

    this.getProducts();

    this.globalRender = this.renderer.listen(document, 'scan', (sScancode?) => {
      this.searchScannerRequest(sScancode)
    });
  }

  // reset renderer 
  ngOnDestroy() {
    this.globalRender();
  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }

  // get list of carts
  getListCart(){

    this.functionService.getCart().subscribe((cart)=>{

      this.listOfCarts = cart;

      if(this.listOfCarts && this.listOfCarts.length > 0){

        var selectedCart = this.listOfCarts.find(o=> o.status == true);

        if(selectedCart){

          this.cart = selectedCart.cart;
          
          if(this.cart){

            this.cartNumber = this.cart.length;
    
          }

        }

      }

    })

  }

  // get products
  getProducts(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetProduct';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.products = [];

          var products = res.Products;

          for (let i = 0; i < products.length; i++) {
            
            if(products[i]){

              var product = {
                category: products[i].category ? products[i].category[0] : null,
                costPrice: products[i].costPrice ? products[i].costPrice : null,
                discount: products[i].discount ? products[i].discount : 0,
                discountType: products[i].discountType ? products[i].discountType : 'none',
                discountUnit: products[i].discountUnit ? products[i].discountUnit : 0,
                limitPrice: products[i].limitPrice ? products[i].limitPrice : null,
                name: products[i].name ? products[i].name : null,
                quantity: products[i].quantity ? products[i].quantity : null,
                sellingPrice: products[i].sellingPrice ? products[i].sellingPrice : null,
                unitOfMeasurement: products[i].unitOfMeasurement ? products[i].unitOfMeasurement : null,
                _id: products[i]._id ? products[i]._id : null,
                wholesalesQuantity: products[i].WholesalesQuantity ? products[i].WholesalesQuantity : null,
                shelfNumber: products[i].shelfNumber ? products[i].shelfNumber : null,
                firstLetterOfCategory: products[i].category ? products[i].category[0].name.substring(0,1) : null
              }

              this.products.push(product)

            }
            
          }

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }
  
  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getProducts();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getProducts();

    }

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Product.length > 0){

            this.searchResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  category: products[i].category ? products[i].category[0] : null,
                  costPrice: products[i].costPrice ? products[i].costPrice : null,
                  discount: products[i].discount ? products[i].discount : 0,
                  discountType: products[i].discountType ? products[i].discountType : 'none',
                  discountUnit: products[i].discountUnit ? products[i].discountUnit : 0,
                  limitPrice: products[i].limitPrice ? products[i].limitPrice : null,
                  name: products[i].name ? products[i].name : null,
                  quantity: products[i].quantity ? products[i].quantity : null,
                  sellingPrice: products[i].sellingPrice ? products[i].sellingPrice : null,
                  unitOfMeasurement: products[i].unitOfMeasurement ? products[i].unitOfMeasurement : null,
                  _id: products[i]._id ? products[i]._id : null,
                  wholesalesQuantity: products[i].WholesalesQuantity ? products[i].WholesalesQuantity : null,
                  shelfNumber: products[i].shelfNumber ? products[i].shelfNumber : null,
                  firstLetterOfCategory: products[i].category ? products[i].category[0].name.substring(0,1) : null
                }
  
                this.searchResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // search scanner request
  searchScannerRequest(sScancode){

    this.searchData = sScancode.detail.scanCode;
    this.showSearchItems = true;
    this.showSearchLoading = true;
    this.searchResult = [];

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductScanner';
    var requestData = {
      barCode: this.searchData,
    }
    
    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        console.log(route)

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;
          console.log(res)

          if(res.Product && res.Product._id){

            var products = res.Product;

            var product = {
              category: products.category ? products.category[0] : null,
              costPrice: products.costPrice ? products.costPrice : null,
              discount: products.discount ? products.discount : 0,
              discountType: products.discountType ? products.discountType : 'none',
              discountUnit: products.discountUnit ? products.discountUnit : 0,
              limitPrice: products.limitPrice ? products.limitPrice : null,
              name: products.name ? products.name : null,
              quantity: products.quantity ? products.quantity : null,
              sellingPrice: products.sellingPrice ? products.sellingPrice : null,
              unitOfMeasurement: products.unitOfMeasurement ? products.unitOfMeasurement : null,
              _id: products._id ? products._id : null,
              wholesalesQuantity: products.WholesalesQuantity ? products.WholesalesQuantity : null,
              shelfNumber: products.shelfNumber ? products.shelfNumber : null,
              firstLetterOfCategory: products.category ? products.category[0].name.substring(0,1) : null
            }
  
            this.searchResult.push(product)

          }
          
          this.changeRef.detectChanges();
          
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

    console.log(this.searchResult)

  }

  // select product fnc
  selectProduct(product, initiator, position, popOver?){

    this.selectedProduct = {
      firstLetterOfCategory: product.firstLetterOfCategory,
      _id: product._id,
      unitOfMeasurement: product.unitOfMeasurement,
      sellingPrice: product.sellingPrice,
      quantity: product.quantity,
      name: product.name,
      limitPrice: product.limitPrice,
      discountUnit: product.discountUnit,
      discountType: product.discountType,
      discount: product.discount,
      costPrice: product.costPrice,
      category: product.category,
      wholesalesQuantity: product.wholesalesQuantity,
      shelfNumber: product.shelfNumber,
      saleStructure: []
    }

    for (let i = 0; i < this.selectedProduct.unitOfMeasurement.length; i++) {
      
      if(this.selectedProduct.unitOfMeasurement[i]){

        var measurement = this.selectedProduct.unitOfMeasurement[i];

        var saleStructure = {
          measurement: measurement,
          shelfNumber: this.selectedProduct.shelfNumber ? this.selectedProduct.shelfNumber : null,
          totalQuantity: this.selectedProduct.quantity ? this.selectedProduct.quantity[measurement] : null,
          wholesalesQuantity: this.selectedProduct.wholesalesQuantity ? this.selectedProduct.wholesalesQuantity[measurement] : null,
          sellingPrice: this.selectedProduct.sellingPrice ? this.selectedProduct.sellingPrice[measurement] : null,
          costPrice: this.selectedProduct.costPrice ? this.selectedProduct.costPrice[measurement] : null,
          limitPrice: this.selectedProduct.limitPrice ? this.selectedProduct.limitPrice[measurement] : null,
          discountType: this.selectedProduct.discountType ? this.selectedProduct.discountType[measurement] : 'none',
          discountPercentage: this.selectedProduct.discount ? this.selectedProduct.discount[measurement] : 0,
          discountUnit: this.selectedProduct.discountUnit ? this.selectedProduct.discountUnit[measurement] : 0,
          cartAmount: 0,
          cartQty: null,
          discountCharge: 0,
        };

        if(saleStructure.totalQuantity > 0){

          this.selectedProduct.saleStructure.push(saleStructure);

        }

      }
      
    }

    this.activeMeasurement = {};
    
    this.togglePopover(position, initiator, popOver)

  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(this.marketView == 'false'){

          if(index == 1){

            popOver.placement = 'bottom-left'

          }else if(index % 3 == 0){
            
            popOver.placement = 'bottom-right'

          }else if(index % 3 !== 0){

            popOver.placement = 'bottom-left'

          }

        }else{

          if(index == 1){

            popOver.placement = 'bottom-left'

          }else if(index % 2 == 0){
            
            popOver.placement = 'bottom-right'

          }else{

            popOver.placement = 'bottom-left'

          }

        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }else if(position == 'search'){

        popOver.autoClose = 'outside';
        popOver.placement = 'right-top'

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

  // select active measurement
  selectActiveMeasurement(unit){

    this.activeMeasurement.measurement = unit.measurement
    this.activeMeasurement.totalQuantity = unit.totalQuantity
    this.activeMeasurement.wholesalesQuantity = unit.wholesalesQuantity
    this.activeMeasurement.sellingPrice = unit.sellingPrice
    this.activeMeasurement.costPrice = unit.costPrice
    this.activeMeasurement.limitPrice = unit.limitPrice
    this.activeMeasurement.discountType = unit.discountType
    this.activeMeasurement.discountPercentage = unit.discountPercentage
    this.activeMeasurement.discountUnit = unit.discountUnit
    this.activeMeasurement.cartAmount = unit.cartAmount
    this.activeMeasurement.cartQty = unit.cartQty
    this.activeMeasurement.discountCharge = unit.discountCharge

    setTimeout(()=>{ 
      document.getElementById('cartQty').focus();
    },0);

  }

  // calculate amount of item from the quantity entered on unit of measurement
  calculateUnitAmount(measurement){

    var discountType = measurement.discountType;
    var discountPercentage = measurement.discountPercentage;
    var discountUnit = measurement.discountUnit;

    var wholesalesQuantity = measurement.wholesalesQuantity;
    var wholesalePrice = measurement.limitPrice;
    var retailPrice = measurement.sellingPrice

    var discountCharge = 0;

    if(discountType == 'none'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          measurement.cartAmount = wholesalePrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }else{

          measurement.cartAmount = retailPrice * measurement.cartQty;
          measurement.discountCharge = 0;

        }

      }
      

    }else if(discountType == 'single'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

          discountCharge = (discountPercentage/100) * wholesalePrice;
          measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }else{

          discountCharge = (discountPercentage/100) * retailPrice;
          measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
          measurement.discountCharge = discountCharge;

        }

      }

    }else if(discountType == 'bulk'){

      if(measurement.cartQty <= measurement.totalQuantity){

        if(measurement.cartQty >= discountUnit){

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            discountCharge = (discountPercentage/100) * wholesalePrice;
  
            measurement.cartAmount = (wholesalePrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }else{

            discountCharge = (discountPercentage/100) * retailPrice;
  
            measurement.cartAmount = (retailPrice - discountCharge) * measurement.cartQty;
            measurement.discountCharge = discountCharge;

          }
  
        }else{

          if(wholesalesQuantity && measurement.cartQty >= wholesalesQuantity){

            measurement.cartAmount = wholesalePrice * measurement.cartQty;

          }else{

            measurement.cartAmount = retailPrice * measurement.cartQty;

          }
  
  
        }

      }
      
    }

  }

  // add product to cart
  addToCart(){

    // structure added cart
    var structureUnitOfAddedProducts = {
      firstLetterOfCategory: this.selectedProduct.firstLetterOfCategory,
      id: this.selectedProduct._id,
      name: this.selectedProduct.name,
      measurement: this.activeMeasurement.measurement,
      totalQuantity: this.activeMeasurement.totalQuantity,
      wholesalesQuantity: this.activeMeasurement.wholesalesQuantity,
      sellingPrice: this.activeMeasurement.sellingPrice,
      costPrice: this.activeMeasurement.costPrice,
      limitPrice: this.activeMeasurement.limitPrice,
      discountType: this.activeMeasurement.discountType,
      discountPercentage: this.activeMeasurement.discountPercentage,
      discountUnit: this.activeMeasurement.discountUnit,
      cartAmount: this.activeMeasurement.cartAmount,
      cartQty: this.activeMeasurement.cartQty,
      discountCharge: this.activeMeasurement.discountCharge,
    }

    // check for old and new products
    // find if the product already exist
    var addedProduct = this.cart.find(o=> o.id == structureUnitOfAddedProducts.id && 
        o.measurement == structureUnitOfAddedProducts.measurement);
    if(addedProduct){

      addedProduct.cartQty = addedProduct.cartQty + structureUnitOfAddedProducts.cartQty
      this.calculateUnitAmount(addedProduct);

    }else{

      this.cart.push(structureUnitOfAddedProducts);

    }

    this.addToGlobalCart();

  }

  // add current cart to list of cart
  addToGlobalCart(){

    // get active cart index
    var activeCartIndex:any = this.listOfCarts.findIndex(o=> o.status == true);

    // check if active cart exist
    if(activeCartIndex > -1){

      this.listOfCarts[activeCartIndex].cart = this.cart
      this.functionService.setCart(this.listOfCarts);
      if(this.popOver){

        this.popOver.close();
              
      }

    }else{

      // show notification
      var requestResponse = 'No cart is active.';
      this.toastNotification(requestResponse);

    }

    

  }

  // add more carts to cart array
  addMoreCart(){

    // deactivate all previous carts
    for (let i = 0; i < this.listOfCarts.length; i++) {
      
      this.listOfCarts[i].status = false;
      
    }

    // add a new cart to the beginning of the array
    var cart = {
      status:true,
      cart: []
    }

    this.cart = []

    this.listOfCarts.unshift(cart);
    this.cartNumber = 0;

  }

  // choose cart to pass, deactivate others
  chooseCart(cartIndex){

    // deactivate all other carts
    for (let i = 0; i < this.listOfCarts.length; i++) {
      
      if(i !== cartIndex){

        this.listOfCarts[i].status = false;

      }else if(i == cartIndex){

        this.listOfCarts[i].status = true;
        this.cart = this.listOfCarts[i].cart
        this.cartNumber = this.cart.length

      }
      
    }

  }

  // remove cart from cart list
  removeCart(cartIndex){

    this.listOfCarts.splice(cartIndex,1);

    this.cart = []

    if(this.listOfCarts.length < 1){

      var cart = {
        status:true,
        cart: []
      }
  
      this.listOfCarts.unshift(cart);

    }else{
      
      var selectedCartIndex = this.listOfCarts.findIndex(o=> o.status == true);
      if(selectedCartIndex < 0 ){

        this.listOfCarts[0].status = true;

      }
    }

  }

  // remove item from cart
  removeItem(itemIndex, cartIndex){
    this.listOfCarts[cartIndex].cart.splice(itemIndex,1);
  }

  //  check cart for quantity issues
  proccessCart(){

    var passQuantities = false

    for (let i = 0; i < this.cart.length; i++) {
      
      if(this.cart[i].cartQty > this.cart[i].totalQuantity){

        passQuantities = false;
        break;

      }else{

        passQuantities = true;

      }
      
    }

    if(passQuantities){

      this.goToCart()

    }else{

      var requestResponse = 'One of the products has a quantity greater than its available quantity.';
      this.toastNotification(requestResponse);

    }

  }

  // go to cart page
  goToCart(){

    if(this.cartNumber > 0){

      var passCart = false;

      for (let i = 0; i < this.listOfCarts.length; i++) {
        
        if(this.listOfCarts[i].status){

          passCart = true;
          break;

        }
      }

      if(passCart){

        this.goToPage('account/cart');
        
      }else{

        var requestResponse = 'No cart was selected.';
        this.toastNotification(requestResponse);
  
      }

    }else{

      var requestResponse = 'Cart is empty.';
      this.toastNotification(requestResponse);

    }


  }

}
