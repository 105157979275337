<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                All Stores
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Store list
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="plus"></fa-icon> add Store
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of Stores</b>
                    <b *ngIf="activeInnerMenu == 'add'">add Store</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Store details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Location
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.locationName}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Fee per Kilometre (Delivery)
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.feePerKm}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Maximum Kilometre (Delivery)
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.maxKm}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Free Kilometre (Delivery)
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.freeDeliveryKm}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Maximum Amount Benchmark to activate Wholesale
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                &#8358; {{selectedRecord.applyWholesalesPriceAtAmount | number:'.2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Number of staffs
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.staff.length}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-list-section">
                                        <div class="item-section" *ngFor="let x of selectedRecord.bankDetails">
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Bank
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{x.bankName}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Account Name
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{x.accountName}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Account Number
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{x.accountNumber}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Date of creation
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Last updated at
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.updatedAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="btn-details-section" *ngIf="selectedRecord.activeStore">
                                        <div class="full-btn-details-section">
                                            <button mat-flat-button (click)="goToPage('store/details/update')">
                                                Edit Store
                                                
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="stores.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="stores.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of stores; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                                    [class.activeRecord]="x._id == selectedRecord._id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.locationName}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        Active: <b *ngIf="x.active">YES</b>
                                                        <b *ngIf="!x.active">NO</b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span>
                                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-section">
                                    <div class="pagination-text" (click)="getPage(1)">
                                        <span>
                                            First
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(-1)">
                                        <span>
                                            Prev
                                        </span>
                                    </div>
                                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                        <span>
                                            {{x}}
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(1)">
                                        <span>
                                            Next
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="getPage(noOfPages)">
                                        <span>
                                            Last
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="storeLocationForm" #storeLocationForm="ngForm" class="form"
                            (ngSubmit)="storeLocationForm.form.valid && addStoreLocation(storeLocationForm)">
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Office Address
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="locationName" 
                                            [(ngModel)]="storeLocation.locationName" #locationName="ngModel" />
                                            <mat-error *ngIf="storeLocationForm.submitted && locationName.invalid">
                                                Please enter a valid office address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            State
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="state" [(ngModel)]="storeLocation.state" required 
                                            #state="ngModel" style="text-transform: capitalize;">
                                                <mat-option style="text-transform: capitalize;" value="{{x}}" 
                                                *ngFor="let x of listOfStates">{{x}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="storeLocationForm.submitted && state.invalid">
                                                Please enter a valid state
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Fee per Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="feePerKm" 
                                            [(ngModel)]="storeLocation.feePerKm" #feePerKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && feePerKm.invalid">
                                                Please enter a valid fee per Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Maximum Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="maxKm" 
                                            [(ngModel)]="storeLocation.maxKm" #maxKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && maxKm.invalid">
                                                Please enter a valid maximum Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Free Kilometre (Delivery)
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="freeDeliveryKm" 
                                            [(ngModel)]="storeLocation.freeDeliveryKm" #freeDeliveryKm="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && freeDeliveryKm.invalid">
                                                Please enter a valid free Kilometre (Delivery)
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Maximum Amount Benchmark to activate Wholesale
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="applyWholesalesPriceAtAmount" 
                                            [(ngModel)]="storeLocation.applyWholesalesPriceAtAmount" 
                                            #applyWholesalesPriceAtAmount="ngModel"/>
                                            <mat-error *ngIf="storeLocationForm.submitted && 
                                            applyWholesalesPriceAtAmount.invalid">
                                                Please enter a valid amount
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="measurement-section" *ngFor="let x of storeLocation.bankDetails; let i = index">
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Bank Name ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'bank' + i}}" 
                                                [(ngModel)]="x.bankName"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Account Name ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'accountName' + i}}" 
                                                [(ngModel)]="x.accountName"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="small-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Account Number ({{1+i}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="{{'accountNumber' + i}}"
                                                [(ngModel)]="x.accountNumber"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Add
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>