<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Balance Sheet
            </span>
        </div>
        <div class="btn-section">
            <div class="filter-section">
                <div class="main-filter-text-section">
                    <span>
                        search:
                    </span>
                </div>
                <div class="left-filter-section" (click)="frompicker.open()">
                    <div class="filter-text-section">
                        <span>
                            From
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput [matDatepicker]="frompicker" name="frompicker"
                                [(ngModel)]="selectedFromDate" (dateChange)="dateChangeFnc($event, 'from')">
                            <mat-datepicker #frompicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="icon-section">
                        <fa-icon icon="calendar-alt"></fa-icon>
                    </div>
                </div>
                <div class="right-filter-section" (click)="topicker.open()">
                    <div class="filter-text-section">
                        <span>
                            To
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput [matDatepicker]="topicker" name="topicker" [(ngModel)]="selectedToDate"
                                (dateChange)="dateChangeFnc($event, 'to')">
                            <mat-datepicker #topicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="icon-section">
                        <fa-icon icon="calendar-alt"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="role" class="profit-loss-section">
        <div class="profit-text-holder profit-section" *ngIf="analysis.result == 'profit' ">
            <span>
                You have made a profit of <b> &#8358; {{analysis.value | number: '.2'}}</b> from
                <b>{{selectedFromDate | date: 'yyyy-MM-d'}}</b> to
                <b>{{selectedToDate | date: 'yyyy-MM-d'}}</b>
            </span>
        </div>
        <div class="profit-text-holder loss-section" *ngIf="analysis.result == 'loss' ">
            <span>
                You have made a loss of <b> &#8358; {{analysis.value | number: '.2'}} </b> from
                <b>{{selectedFromDate | date: 'yyyy-MM-d'}}</b> to
                <b>{{selectedToDate | date: 'yyyy-MM-d'}}</b>
            </span>
        </div>
    </div>

    <div *ngIf="role" class="expenses-revenue-section">
        <div class="expenses-section">
            <div class="expenses-content">
                <div class="label-section">
                    <span>
                        total cost price
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        &#8358; {{analysis.totalCost | number: '.2'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="debt-section">
            <div class="debt-content">
                <div class="label-section">
                    <span>
                        total selling price
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        &#8358; {{analysis.totalSales | number: '.2'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="revenue-section">
            <div class="revenue-content">
                <div class="label-section">
                    <span>
                        total debt
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        &#8358; {{analysis.totalDebt | number: '.2'}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="expenses-list-section">
        <div class="each-expense-section" *ngFor="let x of debtSales">
            <div class="top-expenses-section">
                <div class="left-text-section">
                    <span>
                        &#8358; {{x.remainingAmount | number: '.2'}}
                    </span>
                </div>
                <div class="right-text-section">
                    <span>
                        Expected Date: {{x.expectedDate | date : 'dd MMM, yyyy'}}
                    </span>
                </div>
            </div>
            <div class="down-expenses-section">
                <div class="left-down-expenses-section">
                    <span>
                        Date: {{x.createdAt | date : 'dd MMM, yyyy'}}
                    </span>
                </div>
                <div class="right-down-expenses-section">
                    <span>
                        Customer: {{x.CustomerId.fullName}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>