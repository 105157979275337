<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                List of Packed/Assigned Orders
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="filterData" autocomplete="off" [(ngModel)]="filterData" 
                            placeholder="Filter current record"  />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'" [ngbPopover]="popContent" 
            [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Order details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-list-section">
                        <div class="item-section" *ngFor="let x of selectedRecord.products">
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Product
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Measurement
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.unitOfMeasurement}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Selling Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.quantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Total
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.totalAmount | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Invoice ID
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.invoiceId}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Receipt ID
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.receiptId}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Total amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.totalAmount | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Shipping fee
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.shippingFee | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                VAT
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.vat | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Payment Option
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.paymentOption == 'Approve'">
                                Card
                            </span>
                            <span *ngIf="selectedRecord.paymentOption !== 'Approve'">
                                {{selectedRecord.paymentOption}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date Ordered
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Customer
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.customer.name}} ({{selectedRecord.customer.phoneNumber}})
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Delivery Location
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.deliveryLocation.locationName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Order Approved?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.storeApproval">YES</b>
                                <b *ngIf="!selectedRecord.storeApproval">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Order Packed?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.itemPacked">YES</b>
                                <b *ngIf="!selectedRecord.itemPacked">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date Packed
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.itemPackedTime | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Item Status
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.itemStatus}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Order Delivered?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.deliveryCompleted">YES</b>
                                <b *ngIf="!selectedRecord.deliveryCompleted">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Dispatcher
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.dispatcher.personalInfo.firstName}} 
                                {{selectedRecord.dispatcher.personalInfo.lastName}} 
                                ({{selectedRecord.dispatcher.personalInfo.phoneNumber}})
                            </span>
                        </div>
                    </div>
                    <div class="btn-details-section" *ngIf="selectedRecord.storeApproval && selectedRecord.itemPacked">
                        <div class="left-btn-details-section">
                            <div class="icon-section" (click)="togglePopover(popOver, true)">
                                <fa-icon icon="draw-polygon"></fa-icon>
                            </div>
                        </div>
                        <div class="right-btn-details-section">
                            <div class="icon-section" (click)="printBtn.click()" >
                                <fa-icon icon="sticky-note"></fa-icon>
                            </div>
                            <button hidden #printBtn printSectionId="print-section" ngxPrint></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:filterData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord.id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        &#8358;{{x.totalAmount | number: '.2'}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        <b *ngFor="let y of x.productDetails;">
                                            {{y.name}}, 
                                        </b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Add an issue to Order
            </span>
        </div>
        <div class="down-popover-section">
            <div class="rider-section" *ngIf="selectedRecord.action">
                <form name="issueForm" #issueForm="ngForm" (ngSubmit)="issueForm.form.valid && addIssueToOrder(issueForm)">
                    <div class="text-section">
                        <span>
                            Please explain the issue with
                            the order with invoice id as <b>{{selectedRecord.invoiceId}}</b>.
                        </span>
                    </div>
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Comment
                            </span>
                        </div>
                        <div class="control-section">
                            <mat-form-field appearance="none">
                                <textarea matInput type="text" name="comment" required
                                [(ngModel)]="issueDetails.comment" #comment="ngModel" rows="3"></textarea>
                                <mat-error *ngIf="issueForm.submitted && comment.invalid">
                                    Please enter a valid comment
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button>
                            Add
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<div id="print-section" >
    <div style="height: 100%; width: 100%; display: flex; flex-direction: column;">
        <div style="height: 80%; width: 100%;  display: flex; flex-direction: column; border-bottom: 1px solid black;">
            <div style="height: 40%; width: 100%; display: flex; justify-content: center; align-items: center; 
            text-align: center; padding: 10px 0">
                <span style="text-transform: uppercase; font-size: 25px; font-family: normal; ">
                    {{stickerDetails.name}}
                </span>
            </div>
            <div style="height: 40%; width: 100%; display: flex; justify-content: center; align-items: center; 
            text-align: center; padding: 10px 0">
                <span style="text-transform: capitalize; font-size: 15px; font-family: normal; ">
                    {{stickerDetails.address}}
                </span>
            </div>
            <div style="height: 20%; width: 100%; display: flex; flex-direction: row; padding: 10px 0">
                <div style="height: 100%; width: 50%; display: flex; justify-content: flex-start; align-items: center; 
                text-align: left; ">
                    <span style="text-transform: uppercase; font-size: 12px; font-family: normal; ">
                            RECEIPT: {{stickerDetails.receiptId}}
                    </span>
                </div>
                <div style="height: 100%; width: 50%; display: flex; justify-content: flex-end; align-items: center; 
                text-align: right; ">
                    <span style="text-transform: uppercase; font-size: 12px; font-family: normal; ">
                            PHONE NUMBER: {{stickerDetails.phoneNumber}}
                    </span>
                </div>
            </div>
        </div>
        <div style="height: 20%; width: 100%; display: flex; flex-direction: row;">
            <div style="height: 100%; width: 40%; display: flex; justify-content: flex-start;
            align-items: center;">
                <img src="./assets/img/logo.png" alt="image" style="height: 44.5px; width: 131px;">
            </div>
            <div style="height: 100%; width: 60%; display: flex; justify-content: flex-end;
            align-items: center; text-align: right;">
                <div style="font-size: 12px; font-family: normal; text-align: right; ">
                    Suite AO10 & AO11 Zitel Plaza, Plot 227, P.O.W. Mafemi Crescent, Utako, Abuja.
                </div>
            </div>
        </div>
    </div>
</div>

<div id="print-vertical-section" style="display: none">
    <div style="height: 100%; display: flex; flex-direction: row;">
        <div style="height: 100%; width: 25%; border-right: 1px solid black; display: flex; flex-direction: column;">
            <div style="height: 40%; width: 100%; position: relative; ">
                <div style="position: absolute; top:50%; left:50%; transform: translate(-50%,-50%);">
                    <img src="./assets/img/logo.png" alt="image" style="height: 44.5px; width: 131px; transform: rotate(90deg);">
                </div>
            </div>
            <div style="height: 60%; width: 100%; text-align: right;">
                <div style="font-size: 12px; font-family: normal; text-align: right; writing-mode: vertical-rl;
                text-orientation: mixed;">
                    Suite AO10 & AO11 Zitel Plaza, Plot 227, P.O.W. Mafemi Crescent, Utako, Abuja.
                </div>
            </div>
        </div>
        <div style="height: 100%; width: 75%;  display: flex;
        flex-direction: row;">
            <div style="height: 100%; width: 20%; display: flex; justify-content: center; align-items: center; 
            text-align: center; padding: 0">
                <span style="text-transform: uppercase; font-size: 12px; font-family: normal; writing-mode: vertical-rl;
                text-orientation: mixed;">
                        RECEIPT: {{stickerDetails.receiptId}}
                </span>
            </div>
            <div style="height: 100%; width: 40%; display: flex; justify-content: center; align-items: center; 
            text-align: center; padding: 0">
                <span style="text-transform: capitalize; font-size: 15px; font-family: normal; writing-mode: vertical-rl;
                text-orientation: mixed;">
                    {{stickerDetails.address}}
                </span>
            </div>
            <div style="height: 100%; width: 40%; display: flex; justify-content: center; align-items: center; 
            text-align: center; padding: 0">
                <span style="text-transform: uppercase; font-size: 25px; font-family: normal; writing-mode: vertical-rl;
                text-orientation: mixed;">
                    {{stickerDetails.name}}
                </span>
            </div>
        </div>
    </div>
</div>