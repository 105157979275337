import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { RequestService } from '../../functions/request.service';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-near-expired',
  templateUrl: './near-expired.component.html',
  styleUrls: ['./near-expired.component.scss']
})
export class NearExpiredComponent implements OnInit {

  // sales records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  /// date picker variable
  public selectedFromDate: any = null;
  public selectedToDate: any = null;
  public dateFilterShow = false;

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>

  constructor(
    private functionService: RequestService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.selectedToDate = moment(new Date()).format('YYYY-MM-D')
    this.selectedFromDate = moment(new Date()).subtract(30, "days").format('YYYY-MM-D')

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/ExpiryInventoryInfo';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.expiryInventory;
          for (let i = 0; i < this.records.length; i++) {
            
            this.records[i].firstLetter = this.records[i].name ? this.records[i].name.substring(0,1) : null;
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select record fnc
  selectRecord(record, initiator: any, position: any, popOver?: any){

    this.selectedRecord._id = record._id ? record._id : null
    this.selectedRecord.name = record.name ? record.name : null
    this.selectedRecord.expiryDate = record.expiryDate ? record.expiryDate : null

    this.togglePopover(position, initiator, popOver)

  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'
  
        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'
  
        }else{
  
          popOver.placement = 'bottom-left'
  
        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

  // date event
  dateChangeFnc(event, selector){

    this.checkFilter();

  }

  // check filter date range
  checkFilter(){

    if(this.selectedFromDate == null || this.selectedToDate == null){

      var requestResponse = 'Select from date and to date';
      this.toastNotification(requestResponse);

    }else if(this.selectedFromDate !== null && this.selectedToDate !== null){

      this.getDateRangeRecords();

    }else{
      
      this.selectedFromDate = null;
      this.selectedToDate = null;

      this.dateFilterShow = false;

    }

  }
  
  // get record between date range
  getDateRangeRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/ExpiryDateByDateRange';
    var requestData = {
      startDate: this.selectedFromDate,
      endDate: this.selectedToDate,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.expiryInventory;
          for (let i = 0; i < this.records.length; i++) {
            
            this.records[i].firstLetter = this.records[i].name ? this.records[i].name.substring(0,1) : null;
            
          }

          this.dateFilterShow = true;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // expire inventory
  expireInventory(expiryForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/InventorySoldOut';

    var requestData = {
      inventoryId: this.selectedRecord._id
    };

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Inventory has been expired successfully.';
          this.toastNotification(requestResponse);

          this.popOver.close();
          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
