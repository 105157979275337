import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './functions/auth/auth-guard.service';

import { LoginComponent } from './account/login/login.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { IncomeComponent } from './account/income/income.component';
import { ProfileComponent } from './account/profile/profile.component';
import { SellComponent } from './products/sell/sell.component';
import { ActivitiesComponent } from './account/activities/activities.component';
import { AdvertsComponent } from './account/adverts/adverts.component';
import { PasswordChangeComponent } from './account/password-change/password-change.component';
import { CartComponent } from './products/cart/cart.component';
import { CheckoutComponent } from './products/checkout/checkout.component';
import { PayInvoiceComponent } from './products/pay-invoice/pay-invoice.component';

import { ListComponent } from './products/list/list.component';
import { CategoryComponent } from './products/category/category.component';
import { AddComponent } from './products/add/add.component';
import { ReturnComponent } from './products/return/return.component';
import { UpdateComponent } from './products/update/update.component';
import { DeactivedProductComponent } from './products/deactived-product/deactived-product.component';
import { NearExpiredComponent } from './products/near-expired/near-expired.component';
import { TopSellingComponent } from './products/top-selling/top-selling.component';
import { ReorderLimitComponent } from './products/reorder-limit/reorder-limit.component';
import { PrescriptionProductComponent } from './products/prescription-product/prescription-product.component';

import { SalesRecordComponent } from './records/sales-record/sales-record.component';
import { DebtSaleRecordComponent } from './records/debt-sale-record/debt-sale-record.component';
import { InventoryRecordComponent } from './records/inventory-record/inventory-record.component';
import { ReturnRecordComponent } from './records/return-record/return-record.component';
import { ExpirationRecordComponent } from './records/expiration-record/expiration-record.component';
import { MiscRecordComponent } from './records/misc-record/misc-record.component';
import { StaffInvoiceComponent } from './records/staff-invoice/staff-invoice.component';
import { PendingInvoiceComponent } from './records/pending-invoice/pending-invoice.component';
import { ReversedSalesRecordComponent } from './records/reversed-sales-record/reversed-sales-record.component';

import { ListStaffComponent } from './staff/list-staff/list-staff.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { RanksComponent } from './staff/ranks/ranks.component';
import { SalesStaffComponent } from './staff/activities/sales-staff/sales-staff.component';
import { CreditSalesStaffComponent } from './staff/activities/credit-sales-staff/credit-sales-staff.component';
import { ExpiryStaffComponent } from './staff/activities/expiry-staff/expiry-staff.component';
import { MiscStaffComponent } from './staff/activities/misc-staff/misc-staff.component';
import { ReturnStaffComponent } from './staff/activities/return-staff/return-staff.component';
import { InventoryStaffComponent } from './staff/activities/inventory-staff/inventory-staff.component';
import { DailyReportComponent } from './staff/daily-report/daily-report.component';
import { EcommerceNotificationComponent } from './staff/ecommerce-notification/ecommerce-notification.component';

import { RetailersComponent } from './end-users/retailers/retailers.component';
import { CustomersComponent } from './end-users/customers/customers.component';

import { OnlineProductsComponent } from './ecommerce/online-products/online-products.component';
import { SupersaleProductsComponent } from './ecommerce/supersale-products/supersale-products.component';
import { FeaturedProductsComponent } from './ecommerce/featured-products/featured-products.component';
import { OrderedProductsComponent } from './ecommerce/ordered-products/ordered-products.component';
import { ApprovedOrdersComponent } from './ecommerce/approved-orders/approved-orders.component';
import { ContactMessagesComponent } from './ecommerce/contact-messages/contact-messages.component';
import { TransitOrdersComponent } from './ecommerce/transit-orders/transit-orders.component';
import { StoreDetailsComponent } from './ecommerce/store-details/store-details.component';
import { PackedOrdersComponent } from './ecommerce/packed-orders/packed-orders.component';
import { CompletedOrdersComponent } from './ecommerce/completed-orders/completed-orders.component';
import { IssuesOrdersComponent } from './ecommerce/issues-orders/issues-orders.component';
import { CancelledOrdersComponent } from './ecommerce/cancelled-orders/cancelled-orders.component';
import { OnlineCategoryComponent } from './ecommerce/online-category/online-category.component';
import { WholesaleTokensComponent } from './products/wholesale-tokens/wholesale-tokens.component';

import { RiderListComponent } from './rider/rider-list/rider-list.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always' },
  { path: 'password', component: ResetPasswordComponent, runGuardsAndResolvers: 'always' },
  { 
    path: 'account/dashboard', 
    component: DashboardComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/income', 
    component: IncomeComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/profile', 
    component: ProfileComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/market', 
    component: SellComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/cart', 
    component: CartComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/checkout', 
    component: CheckoutComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'account/customers', 
    component: CustomersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/category', 
    component: CategoryComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/list', 
    component: ListComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/add', 
    component: AddComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/update/:id', 
    component: UpdateComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/deactivated', 
    component: DeactivedProductComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/nearexpired', 
    component: NearExpiredComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/topselling', 
    component: TopSellingComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/lowstock', 
    component: ReorderLimitComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'products/prescription', 
    component: PrescriptionProductComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/new', 
    component: OrderedProductsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/approved', 
    component: ApprovedOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/packed', 
    component: PackedOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/transit', 
    component: TransitOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/issue', 
    component: IssuesOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/cancelled', 
    component: CancelledOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'orders/completed', 
    component: CompletedOrdersComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'ecommerce/products', 
    component: OnlineProductsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'ecommerce/supersales', 
    component: SupersaleProductsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'ecommerce/featured', 
    component: FeaturedProductsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'ecommerce/category', 
    component: OnlineCategoryComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'ecommerce/messages', 
    component: ContactMessagesComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'invoices/tokens/wholesale', 
    component: WholesaleTokensComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'invoices/generated', 
    component: StaffInvoiceComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'invoices/prescription', 
    component: PendingInvoiceComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'invoices/pay', 
    component: PayInvoiceComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/sales', 
    component: SalesRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/credit/:id', 
    component: DebtSaleRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/return/:id', 
    component: ReturnRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/reversed/sales', 
    component: ReversedSalesRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/sales/return', 
    component: ReturnComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/inventory', 
    component: InventoryRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/expiration', 
    component: ExpirationRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'records/misc', 
    component: MiscRecordComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/list', 
    component: ListStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/sales/:id', 
    component: SalesStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/credit/:id', 
    component: CreditSalesStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/expiry/:id', 
    component: ExpiryStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/misc/:id', 
    component: MiscStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/return/:id', 
    component: ReturnStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/activities/inventory/:id', 
    component: InventoryStaffComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/reports/notifications', 
    component: DailyReportComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/ecommerce/notifications', 
    component: EcommerceNotificationComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/ranks', 
    component: RanksComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'staff/riders', 
    component: RiderListComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'store/all', 
    component: PasswordChangeComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'store/details/update', 
    component: StoreDetailsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'store/adverts', 
    component: AdvertsComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
  { 
    path: 'store/activity', 
    component: ActivitiesComponent, 
    runGuardsAndResolvers: 'always',
    canActivate: [ AuthGuardService ] 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
