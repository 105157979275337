import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { AuthService } from '../../functions/auth/auth.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class PasswordChangeComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // stores variables
  public stores: any = [];
  public selectedRecord: any = {};

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;
  
  // user variable
  public storeLocation: any = {
    locationName: null,
    country: 'nigeria',
    state: "federal capital territory",
    longitude: null,
    latitude: null,
    feePerKm: null,
    maxKm: null,
    freeDeliveryKm: null,
    applyWholesalesPriceAtAmount: null,
    bankDetails: [
      {
        bankName: null,
        accountNumber: null,
        accountName: null,
      },
      {
        bankName: null,
        accountNumber: null,
        accountName: null,
      }
    ],
  }
  public listOfStates: any = [
    "abia",
    "adamawa",
    "akwa Ibom",
    "anambra",
    "bauchi",
    "bayelsea",
    "benue",
    "borno",
    "cross River",
    "delta",
    "ebonyi",
    "edo",
    "ekiti",
    "enugu",
    "gombe",
    "imo",
    "jigawa",
    "kaduna",
    "kano",
    "katsina",
    "kebbi",
    "kogi",
    "kwara",
    "lagos",
    "nasarawa",
    "niger",
    "ogun",
    "ondo",
    "osun",
    "oyo",
    "plateau",
    "rivers",
    "sokoto",
    "taraba",
    "yobe",
    "zamfara",
    "federal capital territory"
  ];

  constructor(
    private functionService: RequestService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  
  // get activities record
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'StoreLocation/GetAllStore';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.stores = res.storeLocation;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.applyWholesalesPriceAtAmount = record.applyWholesalesPriceAtAmount ? record.applyWholesalesPriceAtAmount : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.staff = record.Staff ? record.Staff : null
    this.selectedRecord.bankDetails = record.bankDetails ? record.bankDetails : null
    this.selectedRecord.locationName = record.locationName ? record.locationName : null
    this.selectedRecord.feePerKm = record.feePerKm ? record.feePerKm : null
    this.selectedRecord.maxKm = record.maxKm ? record.maxKm : null
    this.selectedRecord.freeDeliveryKm = record.freeDeliveryKm ? record.freeDeliveryKm : null
    this.selectedRecord.updatedAt = record.updatedAt ? record.updatedAt : null
    this.selectedRecord.activeStore = false;

    var pharm_user = JSON.parse(localStorage.getItem('pharm_user'))
    if(pharm_user){

      if(pharm_user.Store._id == this.selectedRecord.id){
        this.selectedRecord.activeStore = true;
      }
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // edit storeLocation
  addStoreLocation(storeLocationForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var bankDetails = [];
    for (let i = 0; i < this.storeLocation.bankDetails.length; i++) {
      
      bankDetails.push({
        bankName: this.storeLocation.bankDetails[i].bankName,
        accountNumber: this.storeLocation.bankDetails[i].accountNumber,
        accountName: this.storeLocation.bankDetails[i].accountName,
      })
      
    }

    var route = 'StoreLocation/AddStoreLocation';
    var requestData = {
      locationName: this.storeLocation.locationName,
      street: this.storeLocation.locationName,
      country: this.storeLocation.country,
      state: this.storeLocation.state,
      longitude: '',
      latitude: '',
      feePerKm: this.storeLocation.feePerKm,
      maxKm: this.storeLocation.maxKm,
      freeDeliveryKm: this.storeLocation.freeDeliveryKm,
      applyWholesalesPriceAtAmount: this.storeLocation.applyWholesalesPriceAtAmount,
      bankDetails: bankDetails,
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'stored added successfully.';
          this.toastNotification(requestResponse);

          storeLocationForm.resetForm()
          this.toggleInnerMenu('list')
          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
