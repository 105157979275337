import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-top-selling',
  templateUrl: './top-selling.component.html',
  styleUrls: ['./top-selling.component.scss']
})
export class TopSellingComponent implements OnInit {

  // top selling items variables
  public topSellingItems: any = [];
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getDashboard()

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get dashboard
  getDashboard(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/BasicSalesInfo';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.structureTopSellingProduct(res.TopSelling)
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }
  
  // structure top selling items
  structureTopSellingProduct(soldItems){

    this.topSellingItems = [];

    var topSellingItems = [];

    var totalProductSales = 0;
    for (let i = 0; i < soldItems.length; i++) {
      
      var product = {
        name: soldItems[i].productName,
        totalSales: soldItems[i].TotalSales,
        width: 0,
        color: null,
        firstLetter : soldItems[i].productName ? soldItems[i].productName.substring(0,1) : null,
        measurements: []
      }

      // convert object to array
      var objectKeys = Object.keys(soldItems[i].quantity);
      for (let j = 0; j < objectKeys.length; j++) {
            
        var unit = objectKeys[j];

        if(unit !== 'upDated'){

          var measurement = {
            name: unit,
            sellingPrice: soldItems[i].sellingPrice[unit],
            quantity: soldItems[i].quantity[unit]
          }
  
          product.measurements.push(measurement);

        }        
        
      }

      totalProductSales = Number(totalProductSales) + Number(soldItems[i].TotalSales);

      topSellingItems.push(product);
      
    }

    topSellingItems.sort(this.compareSalesAmount);

    // assign width and color
    for (let i = 0; i < 30; i++) {

      if(topSellingItems[i]){
      
        topSellingItems[i].color = this.getRandomColor();

        var width = (topSellingItems[i].totalSales / totalProductSales) * 100;
        topSellingItems[i].width = width.toFixed(2)+'%';


        this.topSellingItems.push(topSellingItems[i]);

      }
      
    }

  }

  // compare total sales between products, arrange in descending order
  compareSalesAmount(a, b) {

    const bandA = a.totalSales;
    const bandB = b.totalSales;

    let comparison = 0;

    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }

    return comparison * -1;
  }

  // generate random colors
  getRandomColor() {

    var letters = '0123456789ABCDEF';
    var color = '#';

    for (var i = 0; i < 6; i++) {

      color += letters[Math.floor(Math.random() * 16)];

    }

    return color;

  }

  // select record fnc
  selectRecord(record, initiator: any, position: any, popOver?: any){

    this.selectedRecord.name = record.name;
    this.selectedRecord.totalSales = record.totalSales;
    this.selectedRecord.width = record.width;
    this.selectedRecord.color = record.color;
    this.selectedRecord.firstLetter = record.firstLetter;
    this.selectedRecord.measurements = record.measurements;
    
    this.togglePopover(position, initiator, popOver)

  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'
  
        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'
  
        }else{
  
          popOver.placement = 'bottom-left'
  
        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

}
