<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Product category
            </span>
        </div>
        <div class="btn-section">
            
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Category list
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="plus"></fa-icon> Add Category
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of Categories</b>
                    <b *ngIf="activeInnerMenu == 'add'">add category/subcategory</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="no-data-section"  *ngIf="records.length <= 0">
                            <div class="img-section">
                                <fa-icon icon="database"></fa-icon>
                            </div>
                            <div class="text-section">
                                <span>
                                    No category Found
                                </span>
                            </div>
                        </div>
                        <div class="data-section" *ngIf="records.length > 0">
                            <div class="list-section">
                                <div class="list-item-section" *ngFor="let x of records; let i = index" >
                                    <div class="list-item-content" (click)="selectRecord(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                                        <div class="icon-section">
                                            <div class="circle">
                                                <span>
                                                    {{x.firstLetter}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="details-section">
                                            <div class="top-details-section">
                                                <span>
                                                    {{x.name}}
                                                </span>
                                            </div>
                                            <div class="middle-details-section">
                                                <span>
                                                    <b>
                                                        {{x.products.length |number}} item(s)
                                                    </b>
                                                </span>
                                            </div>
                                            <div class="down-details-section">
                                                <span>
                                                    {{x.subCategories.length |number}} sub-category
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <form name="addCategoryForm" #addCategoryForm="ngForm" *ngIf="!quickAddCategoryDetails.subStatus"
                    (ngSubmit)="addCategoryForm.form.valid && quickAddCategory(addCategoryForm)" class="form">
                        <div class="checkbox-section">
                            <mat-checkbox name="subStatus" [(ngModel)]="quickAddCategoryDetails.subStatus"
                            [color]="'primary'">
                                Switch to Subcategory
                            </mat-checkbox>
                        </div>
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Category Name
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="categoryName" required
                                    [(ngModel)]="quickAddCategoryDetails.categoryName" #categoryName="ngModel"/>
                                    <mat-error *ngIf="addCategoryForm.submitted && categoryName.invalid">
                                        Please enter a valid category name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Add
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        </div>
                    </form>
                    <form name="addSubCategoryForm" #addSubCategoryForm="ngForm" *ngIf="quickAddCategoryDetails.subStatus"
                    (ngSubmit)="addSubCategoryForm.form.valid && quickAddSubCategory(addSubCategoryForm)" class="form">
                        <div class="checkbox-section">
                            <mat-checkbox name="subStatus" [(ngModel)]="quickAddCategoryDetails.subStatus"
                            [color]="'primary'">
                                Switch to category
                            </mat-checkbox>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Category
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <mat-select name="category" [(ngModel)]="quickAddCategoryDetails.categoryId" required
                                    #category="ngModel">
                                        <mat-option [value]="x._id" *ngFor="let x of records">{{x.name}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="addSubCategoryForm.submitted && category.invalid">
                                        Please enter a valid category
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Subcategory Name
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="subName" 
                                    [(ngModel)]="quickAddCategoryDetails.subCategoryName" #subName="ngModel" required/>
                                    <mat-error *ngIf="addSubCategoryForm.submitted && subName.invalid">
                                        Please enter a valid subcategory name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Add
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Category Details
            </span>
        </div>
        <div class="down-popover-section">
            <form name="editCategoryForm" (ngSubmit)="editCategoryForm.form.valid && editCategory(editCategoryForm)"
            #editCategoryForm="ngForm" class="popoevr-form">
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            Name
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="name"
                            [(ngModel)]="selectedRecord.name" #name="ngModel"/>
                            <mat-error *ngIf="editCategoryForm.submitted && name.invalid">
                                Please enter a valid name
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="product-list-section">
                    <div class="label-section">
                        <span>
                            Products
                        </span>
                    </div>
                    <div class="value-section">
                        <span>
                            <b *ngFor="let y of selectedRecord.products">{{y.name}}, </b>
                        </span>
                    </div>
                </div>
                <div class="product-list-section">
                    <div class="label-section">
                        <span>
                            Sub-categories
                        </span>
                    </div>
                    <div class="value-section">
                        <span>
                            <b *ngFor="let x of selectedRecord.subCategories">{{x.name}}, </b>
                        </span>
                    </div>
                </div>
                <div class="popover-btn-section">
                    <button mat-flat-button>
                        Save
                        <fa-icon icon="angle-right"></fa-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>