import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-reorder-limit',
  templateUrl: './reorder-limit.component.html',
  styleUrls: ['./reorder-limit.component.scss']
})
export class ReorderLimitComponent implements OnInit {

  
  // menu variables
  public activeInnerMenu: any = 'list';

  // low stock variables
  public lowStockItems: any = [];
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  // export variables
  public exportProducts: any = [];


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getDashboard()

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get dashboard
  getDashboard(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Account/BasicSalesInfo';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.structureTopSellingProduct(res.lowStock)
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }
  
  // structure top selling items
  structureTopSellingProduct(lowStock){

    this.lowStockItems = [];
    for (let i = 0; i < lowStock.length; i++) {
      
      var product = {
        name: lowStock[i].name,
        firstLetter : lowStock[i].name ? lowStock[i].name.substring(0,1) : null,
        measurements: []
      }

      // convert object to array
      var objectKeys = Object.keys(lowStock[i].quantity);
      for (let j = 0; j < objectKeys.length; j++) {
            
        var unit = objectKeys[j];

        if(unit !== 'upDated'){

          var measurement = {
            name: unit,
            reOrderLimit: lowStock[i].reOrderLimit[unit],
            quantity: lowStock[i].quantity[unit]
          }
  
          product.measurements.push(measurement);

        }        
        
      }

      this.lowStockItems.push(product);
      
    }

  }

  // export data
  exportData(){

    var addedProducts = [];

    for (let i = 0; i < this.lowStockItems.length; i++) {
      
      if(this.lowStockItems[i]){

        for (let j = 0; j < this.lowStockItems[i].measurements.length; j++) {

          const productMesurement = this.lowStockItems[i].measurements[j];

          if(addedProducts.includes(this.lowStockItems[i].name)){

            var product_empty = {
              name: '',
              unitOfMeasurement: productMesurement.name,
              quantity: productMesurement.quantity,
              reOrderLimit: productMesurement.reOrderLimit,
            }

            this.exportProducts.push(product_empty)

          }else{

            var product_full = {
              name: this.lowStockItems[i].name,
              unitOfMeasurement: productMesurement.name,
              quantity: productMesurement.quantity,
              reOrderLimit: productMesurement.reOrderLimit,
            }

            this.exportProducts.push(product_full)

          }

          addedProducts.push(this.lowStockItems[i].name)
          
        }

      }
      
    }

    if(this.exportProducts.length > 0){

      var json: any[] =  this.exportProducts
      var excelFileName: string = 'low_product_stock';

      const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
      const workbook: xlsx.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);

    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    var excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var ext = '.xlsx';
    var time = moment().format('d/MMM/YYYY');

    const data: Blob = new Blob([buffer], {type: excelType});
    fileSaver.saveAs(data, fileName + '_export_' + time + ext);
  }

  // select record fnc
  selectRecord(record, initiator: any, position: any, popOver?: any){

    this.selectedRecord.name = record.name;
    this.selectedRecord.firstLetter = record.firstLetter;
    this.selectedRecord.measurements = record.measurements;
    
    this.togglePopover(position, initiator, popOver)

  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'
  
        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'
  
        }else{
  
          popOver.placement = 'bottom-left'
  
        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

}
