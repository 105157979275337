<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Receipt Advert List
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> All Adverts
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="user"></fa-icon> Add Advert
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of Adverts</b>
                    <b *ngIf="activeInnerMenu == 'add'">Add a Advert</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            advert details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section" [ngbPopover]="popContent"
                                triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                advert text
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.advert}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="btn-details-section" >
                                        <div class="full-btn-details-section">
                                            <button mat-flat-button (click)="deleteAdvert()">
                                                Delete
                                                
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="records.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="records.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of records; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                                    [class.activeRecord]="x._id == selectedRecord.id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.advert }}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span>
                                                        Advert {{i + 1}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="addAdvertForm" #addAdvertForm="ngForm" class="form" 
                            (ngSubmit)="addAdvertForm.form.valid && addAdvert(addAdvertForm)">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Advert Text
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <textarea matInput name="advert" [(ngModel)]="quickAddAdvertDetails.advert" 
                                            #advert="ngModel" required cols="10" rows="3"></textarea>
                                            <mat-error *ngIf="addAdvertForm.submitted && advert.invalid">
                                                Please enter a valid advert text
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Add
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>