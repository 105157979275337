<div class="passed-screen-section" *ngIf="passScreenSize">
  <div class="main-screen-section">
    <div class="menu-list-section" *ngIf="isAuthenticated">
      <div class="header-section">
        <div class="top-header-section">
          <div class="img-section">
            <img src="./assets/img/avatar.png" alt="image">
          </div>
          <div class="details-section">
            <div class="top-details-section">
              <span>
                {{user_details.name}}
              </span>
            </div>
            <div class="down-details-section">
              <span>
                {{user_details.phoneNumber}}
              </span>
            </div>
            <div class="store-details-section">
              <span>
                {{user_details.store}}
              </span>
            </div>
          </div>
          <div class="icon-section">
            <div class="icon-holder" (click)="toggleHeaderMenu()">
              <fa-icon icon="angle-up" *ngIf="showHeaderMenu"></fa-icon>
              <fa-icon icon="angle-down" *ngIf="!showHeaderMenu"></fa-icon>
            </div>
          </div>
        </div>
        <div class="down-header-section" *ngIf="showHeaderMenu">
          <div class="header-menu-section" (click)="goToPage('account/profile')"
          [class.activeHeaderMenu]="bigActiveMenu == 'profile'">
            <span>
              <fa-icon icon="user"></fa-icon> 
              profile
            </span>
          </div>
          <div class="header-menu-section" (click)="logoutUser()">
            <span>
              <fa-icon icon="sign-out-alt"></fa-icon> 
              Logout
            </span>
          </div>
        </div>
      </div>
      <div class="body-section">
        <div class="body-category-section">
          <span *ngIf="activeMenuCategory !== 'account'" (click)="toggleMenuCategory('account')">
            <fa-icon icon="angle-down" ></fa-icon>
            General
          </span>
          <span *ngIf="activeMenuCategory == 'account'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up" ></fa-icon>
            General
          </span>
        </div>
        <div class="body-menu-section account" id="dropdown" (click)="goToPage('account/dashboard')" 
        [class.activeBodyMenu]="bigActiveMenu == 'dashboard'">
          <span>
            <fa-icon icon="home"></fa-icon> 
            dashboard
          </span>
        </div>
        <div class="body-menu-section account" id="dropdown" (click)="goToPage('account/income')"
        [class.activeBodyMenu]="bigActiveMenu == 'income'">
          <span>
            <fa-icon icon="wallet"></fa-icon> 
            income
          </span>
        </div>
        <div class="body-menu-section account" id="dropdown" (click)="goToPage('account/market')"
        [class.activeBodyMenu]="bigActiveMenu == 'market'">
          <span>
            <fa-icon icon="shopping-cart"></fa-icon> 
            Market
          </span>
        </div>
        <div class="body-menu-section account" id="dropdown" (click)="goToPage('account/customers')"
        [class.activeBodyMenu]="bigActiveMenu == 'customers'">
          <span>
            <fa-icon icon="users"></fa-icon> 
            Customers
          </span>
        </div>
        <div class="body-category-section" >
          <span *ngIf="activeMenuCategory !== 'products'" (click)="toggleMenuCategory('products')">
            <fa-icon icon="angle-down" ></fa-icon>
            Product management
          </span>
          <span *ngIf="activeMenuCategory == 'products'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up"></fa-icon>
            Product management
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/category')"
        [class.activeBodyMenu]="bigActiveMenu == 'category'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            All categories
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/list')"
        [class.activeBodyMenu]="bigActiveMenu == 'list'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            All products
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/deactivated')"
        [class.activeBodyMenu]="bigActiveMenu == 'deactivated'">
          <span>
            <fa-icon icon="ban"></fa-icon> 
            Deactivated Products
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/nearexpired')"
        [class.activeBodyMenu]="bigActiveMenu == 'nearexpired'">
          <span>
            <fa-icon icon="clock"></fa-icon> 
            Near Expired Products
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/topselling')"
        [class.activeBodyMenu]="bigActiveMenu == 'topselling'">
          <span>
            <fa-icon icon="chart-line"></fa-icon> 
            Top Selling Products
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/lowstock')"
        [class.activeBodyMenu]="bigActiveMenu == 'lowstock'">
          <span>
            <fa-icon icon="sort-numeric-down"></fa-icon> 
            Low Stock Products
          </span>
        </div>
        <div class="body-menu-section products" id="dropdown" (click)="goToPage('products/prescription')"
        [class.activeBodyMenu]="bigActiveMenu == 'prescription'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Prescription Products
          </span>
        </div>
        <div class="body-category-section">
          <div class="inner-body-menu-section" *ngIf="activeMenuCategory !== 'orders'" (click)="toggleMenuCategory('orders')">
            <div class="left-inner-body-menu-section">
              <span >
                <fa-icon icon="angle-down" ></fa-icon>
                order management
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="totalOrders">
                <span>
                  {{totalOrders | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="inner-body-menu-section" *ngIf="activeMenuCategory == 'orders'" (click)="toggleMenuCategory('null')">
            <div class="left-inner-body-menu-section">
              <span >
                <fa-icon icon="angle-up" ></fa-icon>
                order management
              </span>
            </div>
            <div class="right-inner-body-menu-section">
            </div>
          </div>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/new')"
        [class.activeBodyMenu]="bigActiveMenu == 'new'">
          <div class="inner-body-menu-section">
            <div class="left-inner-body-menu-section">
              <span>
                <fa-icon icon="cubes"></fa-icon> 
                new orders
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfNewOrders">
                <span>
                  {{numberOfNewOrders | number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/approved')"
        [class.activeBodyMenu]="bigActiveMenu == 'approved'">
          <div class="inner-body-menu-section">
            <div class="left-inner-body-menu-section">
              <span>
                <fa-icon icon="cubes"></fa-icon> 
                approved orders
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfApprovedOrders">
                <span>
                  {{numberOfApprovedOrders | number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/packed')"
        [class.activeBodyMenu]="bigActiveMenu == 'packed'">
          <div class="inner-body-menu-section">
            <div class="left-inner-body-menu-section">
              <span>
                <fa-icon icon="boxes"></fa-icon> 
                packed orders
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfPackedOrders">
                <span>
                  {{numberOfPackedOrders | number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/transit')"
        [class.activeBodyMenu]="bigActiveMenu == 'transit'">
          <div class="inner-body-menu-section">
            <div class="left-inner-body-menu-section">
              <span>
                <fa-icon icon="shipping-fast"></fa-icon> 
                orders in transit
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfTransitOrders">
                <span>
                  {{numberOfTransitOrders | number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/issue')"
        [class.activeBodyMenu]="bigActiveMenu == 'issue'">
          <span>
            <fa-icon icon="box-open"></fa-icon> 
            orders with issues
          </span>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/cancelled')"
        [class.activeBodyMenu]="bigActiveMenu == 'cancelled'">
          <span>
            <fa-icon icon="ban"></fa-icon> 
            cancelled orders
          </span>
        </div>
        <div class="body-menu-section orders" id="dropdown" (click)="goToPage('orders/completed')"
        [class.activeBodyMenu]="bigActiveMenu == 'completed'">
          <span>
            <fa-icon icon="boxes"></fa-icon> 
            completed orders
          </span>
        </div>
        <div class="body-category-section" >
          <span *ngIf="activeMenuCategory !== 'ecommerce'" (click)="toggleMenuCategory('ecommerce')">
            <fa-icon icon="angle-down" ></fa-icon>
            e-commerce management
          </span>
          <span *ngIf="activeMenuCategory == 'ecommerce'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up" ></fa-icon>
            e-commerce management
          </span>
        </div>
        <div class="body-menu-section ecommerce" id="dropdown" (click)="goToPage('ecommerce/products')"
        [class.activeBodyMenu]="bigActiveMenu == 'products'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Online Products
          </span>
        </div>
        <div class="body-menu-section ecommerce" id="dropdown" (click)="goToPage('ecommerce/supersales')"
        [class.activeBodyMenu]="bigActiveMenu == 'supersales'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Supersale Products
          </span>
        </div>
        <div class="body-menu-section ecommerce" id="dropdown" (click)="goToPage('ecommerce/featured')"
        [class.activeBodyMenu]="bigActiveMenu == 'featured'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Featured Products
          </span>
        </div>
        <div class="body-menu-section ecommerce" id="dropdown" (click)="goToPage('ecommerce/category')"
        [class.activeBodyMenu]="bigActiveMenu == 'category'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Online Categories
          </span>
        </div>
        <div class="body-menu-section ecommerce" id="dropdown" (click)="goToPage('ecommerce/messages')"
        [class.activeBodyMenu]="bigActiveMenu == 'messages'">
          <span>
            <fa-icon icon="envelope"></fa-icon> 
            contact messages
          </span>
        </div>
        <div class="body-category-section" >
          <div class="inner-body-menu-section" *ngIf="activeMenuCategory !== 'invoices'" (click)="toggleMenuCategory('invoices')">
            <div class="left-inner-body-menu-section">
              <span >
                <fa-icon icon="angle-down" ></fa-icon>
                invoice management
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfPrescriptionInvoices">
                <span>
                  {{numberOfPrescriptionInvoices | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="inner-body-menu-section" *ngIf="activeMenuCategory == 'invoices'" (click)="toggleMenuCategory('null')">
            <div class="left-inner-body-menu-section">
              <span >
                <fa-icon icon="angle-up"></fa-icon>
                invoice management
              </span>
            </div>
            <div class="right-inner-body-menu-section">
            </div>
          </div>
        </div>
        <div class="body-menu-section invoices" id="dropdown" (click)="goToPage('invoices/tokens/wholesale')"
        [class.activeBodyMenu]="bigActiveMenu == 'tokens'">
          <span>
            <fa-icon icon="key"></fa-icon> 
            Tokens for Wholesale 
          </span>
        </div>
        <div class="body-menu-section invoices" id="dropdown" (click)="goToPage('invoices/generated')"
        [class.activeBodyMenu]="bigActiveMenu == 'generated'">
          <span>
            <fa-icon icon="file-invoice"></fa-icon> 
             my pending Invoices
          </span>
        </div>
        <div class="body-menu-section invoices" id="dropdown" (click)="goToPage('invoices/prescription')"
        [class.activeBodyMenu]="bigActiveMenu == 'prescription'">
          <div class="inner-body-menu-section">
            <div class="left-inner-body-menu-section">
              <span>
                <fa-icon icon="file-prescription"></fa-icon> 
                prescription Inv.  
              </span>
            </div>
            <div class="right-inner-body-menu-section">
              <div class="circle" *ngIf="numberOfPrescriptionInvoices">
                <span>
                  {{numberOfPrescriptionInvoices | number}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body-category-section" >
          <span *ngIf="activeMenuCategory !== 'records'" (click)="toggleMenuCategory('records')">
            <fa-icon icon="angle-down"></fa-icon>
            record management
          </span>
          <span *ngIf="activeMenuCategory == 'records'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up"></fa-icon>
            record management
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/sales')"
        [class.activeBodyMenu]="bigActiveMenu == 'sales'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Sales records  
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/credit/all')"
        [class.activeBodyMenu]="bigActiveMenu == 'credit'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Credit Sales records
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/return/all')"
        [class.activeBodyMenu]="bigActiveMenu == 'return'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            sale return records
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/reversed/sales')"
        [class.activeBodyMenu]="bigActiveMenu == 'reversed'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            reversed Sales records
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/inventory')"
        [class.activeBodyMenu]="bigActiveMenu == 'inventory'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Inventory records  
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/expiration')"
        [class.activeBodyMenu]="bigActiveMenu == 'expiration'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            expiration records 
          </span>
        </div>
        <div class="body-menu-section records" id="dropdown" (click)="goToPage('records/misc')"
        [class.activeBodyMenu]="bigActiveMenu == 'misc'">
          <span>
            <fa-icon icon="clipboard-list"></fa-icon> 
            Misc. event records  
          </span>
        </div>
        <div class="body-category-section" >
          <span *ngIf="activeMenuCategory !== 'staff'" (click)="toggleMenuCategory('staff')">
            <fa-icon icon="angle-down"></fa-icon>
            staff management
          </span>
          <span *ngIf="activeMenuCategory == 'staff'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up"></fa-icon>
            staff management
          </span>
        </div>
        <div class="body-menu-section staff" id="dropdown" (click)="goToPage('staff/list')"
        [class.activeBodyMenu]="bigActiveMenu == 'list'">
          <span>
            <fa-icon icon="users"></fa-icon> 
            All staff 
          </span>
        </div>
        <div class="body-menu-section staff" id="dropdown" (click)="goToPage('staff/reports/notifications')"
        [class.activeBodyMenu]="bigActiveMenu == 'reports'">
          <span>
            <fa-icon icon="user-lock"></fa-icon> 
            daily reports Access
          </span>
        </div>
        <div class="body-menu-section staff" id="dropdown" (click)="goToPage('staff/ecommerce/notifications')"
        [class.activeBodyMenu]="bigActiveMenu == 'ecommerce'">
          <span>
            <fa-icon icon="user-lock"></fa-icon> 
            Order Notice Access
          </span>
        </div>
        <div class="body-menu-section staff" id="dropdown" (click)="goToPage('staff/ranks')"
        [class.activeBodyMenu]="bigActiveMenu == 'ranks'">
          <span>
            <fa-icon icon="users-cog"></fa-icon> 
            staff positions
          </span>
        </div>
        <div class="body-menu-section staff" id="dropdown" (click)="goToPage('staff/riders')"
        [class.activeBodyMenu]="bigActiveMenu == 'riders'">
          <span>
            <fa-icon icon="biking"></fa-icon> 
            all riders
          </span>
        </div>
        <div class="body-category-section" >
          <span *ngIf="activeMenuCategory !== 'store'" (click)="toggleMenuCategory('store')">
            <fa-icon icon="angle-down"></fa-icon>
            store management
          </span>
          <span *ngIf="activeMenuCategory == 'store'" (click)="toggleMenuCategory('null')">
            <fa-icon icon="angle-up"></fa-icon>
            store management
          </span>
        </div>
        <div class="body-menu-section store" id="dropdown" (click)="goToPage('store/all')"
        [class.activeBodyMenu]="bigActiveMenu == 'all'">
          <span>
            <fa-icon icon="store"></fa-icon> 
            all store
          </span>
        </div>
        <div class="body-menu-section store" id="dropdown" (click)="goToPage('store/adverts')"
        [class.activeBodyMenu]="bigActiveMenu == 'adverts'">
          <span>
            <fa-icon icon="receipt"></fa-icon> 
            Receipt Adverts
          </span>
        </div>
        <div class="body-menu-section store" id="dropdown" (click)="goToPage('store/activity')"
        [class.activeBodyMenu]="bigActiveMenu == 'activity'">
          <span>
            <fa-icon icon="scroll"></fa-icon> 
            platform activities
          </span>
        </div>
      </div>
    </div>
    <div class="view-section" [class.authScreen]="!isAuthenticated">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="failed-screen-section" *ngIf="!passScreenSize">
  <div class="failed-text-section">
    <span>
      Please view this site using a bigger screen device (eg, a desktop, laptop or a tablet)
    </span>
  </div>
</div>

<ngx-spinner bdColor="rgba(102, 45, 108, .8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white; font-family: 'PoppinsBold'; font-size: 11px;" > Loading... </p>
</ngx-spinner>