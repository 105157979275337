<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Staff Inventory record
            </span>
        </div>
        <div class="filter-section">
        </div>
        <div class="btn-section">
            <div class="left-btn-section">
                <div class="search-section">
                    <div class="search-holder">
                        <div class="icon-section">
                            <fa-icon icon="search" matPrefix></fa-icon>
                        </div>
                        <div class="left-search-section">
                            <mat-form-field appearance="none" class="searchId">
                                <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                                placeholder="Filter current record"  />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-btn-section">
                <button mat-flat-button (click)="goToPage('staff/list')">
                    Staff List
                    
                </button>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Inventory details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Product
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.name}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Category
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.category}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Type of Inventory
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.inventoryTypes}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Expiry date
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.expiryDate | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="item-list-section">
                        <div class="item-section" *ngFor="let x of selectedRecord.unitOfMeasurement">
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Unit
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        added quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.quantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        total quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.availableQuantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        re-order quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.reorderLimit | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Cost Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.costPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Wholesale Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.limitPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Retail Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Inventory Status
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.soldOut">
                                Sold out
                            </span>
                            <span *ngIf="!selectedRecord.soldOut">
                                in stock
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Staff
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.staff.name}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date of Inventory
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:searchData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord._id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        {{x.name}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        <b>
                                            {{x.inventoryTypes}}
                                        </b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>