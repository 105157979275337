import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from  'rxjs';
import { Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../functions/auth/auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RequestService {

  baseUrl = environment.baseUrl;
  ecommerceUrl = environment.ecommerceUrl;

  activeMenu$: any = new BehaviorSubject(undefined);
  isLoading$: any = new BehaviorSubject(undefined);
  cart$: any = new BehaviorSubject([
    {
      status: true,
      cart:[]
    }
  ]);
  selectedCart$: any = new BehaviorSubject([]);
  checkout$: any = new BehaviorSubject([]);
  invoice$: any = new BehaviorSubject([]);
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private toast: MatSnackBar, 
    public jwtHelper: JwtHelperService,
    private authService: AuthService,
  ) { }

  // set and get menu functions
  getMenu(){
    return this.activeMenu$.asObservable();
  }

  setMenu(menu){
    this.activeMenu$.next(menu);
    this.router.navigate([menu]);
  }

  // show toast
  showNotification(message){

    if(message){

      this.toast.open(message, 'x' ,{duration: 5000});

    }else{

      var finalMesg = 'Unable to reach the server on this page';
      this.toast.open(finalMesg, 'x' ,{duration: 5000});

    }
  }

  // loading functions
  getLoading() {
    return this.isLoading$.asObservable();
  }

  setLoading(loading){
    this.isLoading$.next(loading);
  }

  // set and get cart functions
  getCart(){
    return this.cart$.asObservable();
  }

  setCart(cart){
    this.cart$.next(cart);
  }

  // set and get selected cart functions
  getSelectedCart(){
    return this.selectedCart$.asObservable();
  }

  setSelectedCart(cart){
    this.selectedCart$.next(cart);
  }

  // set and get checkout functions
  getCheckout(){
    return this.checkout$.asObservable();
  }

  setCheckout(checkout){
    this.checkout$.next(checkout);
  }

  // set and get invoice functions
  getInvoice(){
    return this.invoice$.asObservable();
  }

  setInvoice(invoice){
    this.invoice$.next(invoice);
  }

  // HTTP REQUESTs

  putHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var headers = {
      'Accept': 'application/json'
    }

    var requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headers), 
    }

    return this.http.put(route, requestData, requestOptions);

  }

  putTokenHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var token = localStorage.getItem('pharm_token')

    if(this.jwtHelper.isTokenExpired(token) == false){

      var headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token
      }
  
      var requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headers), 
      }
  
      return this.http.put(route, requestData, requestOptions);

    }else{
    
      this.authService.logout();
    
    }

  }

  postHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var headers = {
      'Accept': 'application/json'
    }

    var requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headers), 
    }

    return this.http.post(route, requestData, requestOptions);

  }

  postTokenHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var token = localStorage.getItem('pharm_token')

    if(this.jwtHelper.isTokenExpired(token) == false){

      var headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token
      }
  
      var requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headers), 
      }
  
      return this.http.post(route, requestData, requestOptions);

    }else{
    
      this.authService.logout();
    
    }

  }

  getHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var headers = {
      'Accept': 'application/json',
    }

    var requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headers),
      params: requestData 
    }

    return this.http.get(route, requestOptions );

  }

  getTokenHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var token = localStorage.getItem('pharm_token')

    if(this.jwtHelper.isTokenExpired(token) == false){

      var headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token,
      }
  
      var requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headers),
        params: requestData 
      }
  
      return this.http.get(route, requestOptions );

    }else{
    
      this.authService.logout();
    
    }

  }

  deleteTokenHttpRequestService(path, requestData?){

    var route = this.baseUrl+path;

    var token = localStorage.getItem('pharm_token')

    if(this.jwtHelper.isTokenExpired(token) == false){

      var headers = {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token,
      }
  
      var requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headers),
        params: requestData 
      }
  
      return this.http.delete(route, requestOptions );

    }else{
    
      this.authService.logout();
    
    }

  }

  // ECOMMERCE REQUESTS
  getEcommerceHttpRequestService(path, requestData?){

    var route = this.ecommerceUrl+path;

    var headers = {
      'Accept': 'application/json',
    }

    var requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headers),
      params: requestData 
    }

    return this.http.get(route, requestOptions );

  }

  

}
