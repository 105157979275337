import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { RequestService } from '../../functions/request.service';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-sales-record',
  templateUrl: './sales-record.component.html',
  styleUrls: ['./sales-record.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class SalesRecordComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // sales records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}
  public popOver: NgbPopover;
  public popOverAction: any = null;

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  // date picker variable
  public selectedFromDate: any = null;
  public selectedToDate: any = null;
  public dateFilterShow = false;

  // export records
  public exportStatus: any = false;
  public exportDetails: any = {
    exportDateFrom: null,
    exportDateTo: null,
    email: null
  }
  
  //filter variables
  public searchData: any;

  // export variables
  public exportRecords: any = [];

  
  public receiptData: any = {
    receiptId: '',
    cashier: '',
    products: [],
    adverts: [],
    netTotal: 0,
    vatPercentage: 0,
    vatAmount: 0,
    cartTotalAmount: 0,
    discountTotal: 0,
    date: null,
    cartTotalWithoutDiscount: null,
    shippingFee: null
  }

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetSales';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.sales;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.customer = record.CustomerId ? record.CustomerId : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.receiptId = record.receiptId ? record.receiptId : null
    this.selectedRecord.returnProduct = record.returnProduct ? record.returnProduct : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id,
      receiptName: record.staffId.personalInfo.firstName
    } : {
      name: null,
      phoneNumber: null,
      id: null,
      receiptName: null
    }
    this.selectedRecord.totalAmount = record.totalAmount ? Number(record.totalAmount.toFixed(2)) : null
    this.selectedRecord.typeOfsales = record.typeOfsales ? record.typeOfsales : null
    this.selectedRecord.vat = record.vat ? record.vat : null
    this.selectedRecord._id = record._id ? record._id : null
    this.selectedRecord.products = [];
    this.selectedRecord.totalAmountAfterReturn = 0;

    for (let i = 0; i < record.productDetails.length; i++) {
      
      if(record.productDetails[i]){

        var soldProduct = record.productDetails[i];

        var product = {
          measurement: [],
        }
        
        // convert object to array
        var objectKeys = Object.keys(soldProduct.quantity);
        for (let j = 0; j < objectKeys.length; j++) {
          
          var unit = objectKeys[j];

          var measurement = {
            id: soldProduct.id,
            productName: soldProduct.productName,
            productUniqueId: soldProduct.productUniqueId,
            totalAmount: soldProduct.totalAmount,
            unitOfMeasurement: unit,
            sellingPrice: soldProduct.sellingPrice[unit],
            quantity: soldProduct.quantity[unit]
          }

          this.selectedRecord.products.push(measurement);
          
        }

      }
      
    }

    if(this.selectedRecord.returnProduct && this.selectedRecord.returnProduct.length > 0){

      var refundedAmount = 0;

      for (let i = 0; i < this.selectedRecord.returnProduct.length; i++) {
        
        if(this.selectedRecord.returnProduct[i].returnType.toLowerCase() == 'Refund'.toLowerCase()){

          refundedAmount = Number(refundedAmount) + Number(this.selectedRecord.returnProduct[i].amountReturn)

        }
        
      }

      this.selectedRecord.totalAmountAfterReturn = this.selectedRecord.totalAmount - refundedAmount;

    }

    if(record.paymentType[0].paymentType){

      this.selectedRecord.paymentType = record.paymentType ? record.paymentType : []

    }else{

      var paymentType = [
        {
          paymentType: record.paymentType,
          amount: this.selectedRecord.totalAmount
        }
      ]

      this.selectedRecord.paymentType = paymentType;

    }
    
    this.getReceiptData()

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // structure receipt data
  getReceiptData(){

    // set cahsier name
    this.receiptData.cashier = this.selectedRecord.staff.receiptName;

    // set customer name
    this.receiptData.customer = this.selectedRecord.customer ? this.selectedRecord.customer.personalInfo.fullName : null;

    // set total price after tax, cart total amount and vat
    this.receiptData.receiptId = this.selectedRecord.receiptId
    this.receiptData.vatAmount = this.selectedRecord.vat
    this.receiptData.cartTotalAmount = 0
    this.receiptData.netTotal = this.selectedRecord.totalAmount

    // set products and discount
    this.receiptData.products = [];
    for (let i = 0; i < this.selectedRecord.products.length; i++) {
      
      if(this.selectedRecord.products[i]){

        var product = this.selectedRecord.products[i];

        var purcahsedProduct = {
          name: product.productName,
          measurement: product.unitOfMeasurement,
          quantity: product.quantity,
          sellingPrice: product.sellingPrice,
          totalAmount: 0
        }

        purcahsedProduct.totalAmount = purcahsedProduct.quantity * purcahsedProduct.sellingPrice
        this.receiptData.cartTotalAmount = this.receiptData.cartTotalAmount + purcahsedProduct.totalAmount

        this.receiptData.products.push(purcahsedProduct)

      }
      
    }
    
    this.receiptData.discount = this.receiptData.cartTotalAmount - (this.receiptData.netTotal - this.receiptData.vatAmount);

    // set date
    this.receiptData.date = moment(this.selectedRecord.createdAt).format('YYYY-MM-D, hh:mm:ss A')
  }

  // date event
  dateChangeFnc(event, selector){

    this.checkFilter();

  }

  // check filter date range
  checkFilter(){

    if(this.selectedFromDate == null || this.selectedToDate == null){

      var requestResponse = 'Select from date and to date';
      this.toastNotification(requestResponse);

    }else if(this.selectedFromDate !== null && this.selectedToDate !== null){

      this.getDateRangeRecords();

    }else{
      
      this.selectedFromDate = null;
      this.selectedToDate = null;

      this.dateFilterShow = false;

    }

  }
  
  // get record between date range
  getDateRangeRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    this.pageIndex = 1;

    var route = 'Sales/GetSalesByDateRange';
    var requestData = {
      page: this.pageIndex,
      startDate: this.selectedFromDate,
      endDate: this.selectedToDate,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.sales;

          this.dateFilterShow = true;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }
  
  // clear filter 
  clearFilter(){

    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.dateFilterShow = false;

    this.toggleCartView()

    this.pageIndex = 1;
    this.getRecords()

  }

  // go to debt
  gotodebt(debtId){

    var pageLink = 'debt/debt/'+debtId

    this.functionService.setMenu(pageLink);

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover, popOverAction?){

    if(popOver){

      if(popOverAction == 'export'){

        popOver.placement = 'bottom-right'

      }else if(popOverAction == 'reverse'){

        popOver.placement = 'left-top'

      }

      popOver.autoClose = 'outside';

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;
      this.popOverAction = popOverAction;

    }
  }

  // export records function
  exportRecord(exportForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetSalesByDateRangeWithOutPagination';
    var requestData = {
      startDate: this.exportDetails.exportDateFrom,
      endDate: this.exportDetails.exportDateTo,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          exportForm.resetForm();

          this.structureExportData(res.sales)

          if(this.popOver){
            this.popOver.close()
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure export data
  structureExportData(record){

    var addedReceipt = [];
    this.exportRecords = [];

    for (let i = 0; i < record.length; i++) {
      
      if(record[i]){

        for (let j = 0; j < record[i].productDetails.length; j++) {

          const product = record[i].productDetails[j];

          if(addedReceipt.includes(record[i].receiptId)){

            var addedProduct = [];
            var objectKeys = Object.keys(product.quantity);
            for (let k = 0; k < objectKeys.length; k++) {

              var key = objectKeys[k];

              if(addedProduct.includes(product.productName)){

                var sales_exist_unit_exist = {
                  receiptId: '',
                  totalSaleAmount: '',
                  vat: '',
                  typeOfSales: '',
                  customer: '',
                  product: '',
                  unitOfMeasurement: key,
                  quantity: product.quantity[key],
                  sellingPrice: product.sellingPrice[key],
                  total: product.quantity[key] * product.sellingPrice[key]
                }
                
                this.exportRecords.push(sales_exist_unit_exist);
                
              }else{
                
                var sales_exist_unit_first = {
                  receiptId: '',
                  totalSaleAmount: '',
                  vat: '',
                  typeOfSales: '',
                  customer: '',
                  product: product.productName,
                  unitOfMeasurement: key,
                  quantity: product.quantity[key],
                  sellingPrice: product.sellingPrice[key],
                  total: product.quantity[key] * product.sellingPrice[key]
                }
                
                this.exportRecords.push(sales_exist_unit_first);
                addedReceipt.push(product.productName)
              }
              
            }

          }else{

            var addedProduct = [];
            var objectKeys = Object.keys(product.quantity);
            for (let k = 0; k < objectKeys.length; k++) {

              var key = objectKeys[k];

              if(addedProduct.includes(product.productName)){

                var sales_first_unit_exist = {
                  receiptId: '',
                  totalSaleAmount: '',
                  vat: '',
                  typeOfSales: '',
                  methodOfPayment: '',
                  customer: '',
                  product: '',
                  unitOfMeasurement: key,
                  quantity: product.quantity[key],
                  sellingPrice: product.sellingPrice[key],
                  total: product.quantity[key] * product.sellingPrice[key]
                }

                this.exportRecords.push(sales_first_unit_exist);
                
              }else{
                
                var sales_first_unit_first = {
                  receiptId: record[i].receiptId,
                  totalSaleAmount: record[i].totalAmount,
                  vat: record[i].vat,
                  typeOfSales: record[i].typeOfsales.toLowerCase() == 'fullPayment'.toLowerCase() ? 'FULL' : 'CREDIT',
                  methodOfPayment: '',
                  customer: record[i].CustomerId ? record[i].CustomerId.fullName+" ("+record[i].CustomerId.phoneNumber+")" : '',
                  product: product.productName,
                  unitOfMeasurement: key,
                  quantity: product.quantity[key],
                  sellingPrice: product.sellingPrice[key],
                  total: product.quantity[key] * product.sellingPrice[key]
                }
                
                this.exportRecords.push(sales_first_unit_first);
                addedReceipt.push(product.productName)
              }
              
            }

            addedReceipt.push(record[i].receiptId)

          }
          
        }

      }
      
    }

    this.exportData()

  }

  // export data
  exportData(){

    if(this.exportRecords.length > 0){

      var requestResponse = 'Your download will begin soon.';
      this.toastNotification(requestResponse);

      var json: any[] =  this.exportRecords
      var excelFileName: string = 'sales_record';

      const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
      const workbook: xlsx.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);

    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    var excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var ext = '.xlsx';
    var time = moment().format('d/MMM/YYYY')

    const data: Blob = new Blob([buffer], {type: excelType});
    fileSaver.saveAs(data, fileName + '_export_' + time + ext);
  }

  // go to retyurn
  goToReturnPage(returnId){

    var pageLink = 'records/return/'+returnId

    this.functionService.setMenu(pageLink);

  }

  // reverse sales function
  reverseSales(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/ReverseSales';
    var requestData = {
      salesId: this.selectedRecord._id,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Sale has been reversed successfully.';
          this.toastNotification(requestResponse);
          this.toggleCartView();
          
          this.getRecords();

          if(this.popOver){
            this.popOver.close()
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  

}
