<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Tokens used for activating wholesale sales
            </span>
        </div>
        <div class="btn-section" >
            <button mat-flat-button (click)="generateToken(popOver)" [ngbPopover]="popContent" 
            triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                Generate Token
                
            </button>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Token details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Token
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.salesToken}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                generated By
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.generatedBy">
                                {{selectedRecord.generatedBy.personalInfo.firstName +' '+ 
                                selectedRecord.generatedBy.personalInfo.lastName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Token Valid?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.valid">YES</b><b *ngIf="!selectedRecord.valid">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Token Expired?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.expired">YES</b><b *ngIf="!selectedRecord.expired">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Token Used?
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.used">YES</b><b *ngIf="!selectedRecord.used">NO</b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Used By
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.usedBy">
                                {{selectedRecord.usedBy.personalInfo.firstName +' '+ 
                                selectedRecord.usedBy.personalInfo.lastName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date Generated
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="tokens.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of tokens; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord.id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        {{x.salesToken}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        Used: <b *ngIf="x.used">YES</b><b *ngIf="!x.used">NO</b>, 
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="tokens.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Generated Token
            </span>
        </div>
        <div class="down-popover-section">
            <div class="popover-no-data-section">
                <span>
                    {{generatedToken}}
                </span>
            </div>
        </div>
    </div>
</ng-template>