import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { AuthService } from '../../functions/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public userdata: any = {
    phoneNumber: '',
    code: '',
    password: ''
  }
  
  public page = 'password';

  public resetPhoneNumber: any = '';

  public passwordStrength = 'none';

  constructor(
    private functionService: RequestService,
    private authService: AuthService,
  ) { 

    this.page = 'password';

  }

  ngOnInit(): void {
  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // send code to phone number
  resetPasswordByPhone(passwordForm: NgForm){

    var numberPrefix = '+234';
    var extractedNumber = this.userdata.phoneNumber.substring(1, this.userdata.phoneNumber.length);
    this.resetPhoneNumber = numberPrefix+extractedNumber;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/RestPassword';
    var requestData = {
      phoneNumber: this.resetPhoneNumber
    }

    this.functionService.putHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.page = 'code';
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // check password strength
  checkPassword(event){

    if(event.target.value.toString().length >= 5){

      var letter = /[a-zA-Z]/; 
      
      var number = /[0-9]/;

      var status = number.test(event.target.value) && letter.test(event.target.value)

      if(status){

        this.passwordStrength = 'strong';
  
      }else{
  
        this.passwordStrength = 'weak';
  
      }

    }else{

      this.passwordStrength = 'weak';

    }

  }

  // send code to phone number
  resetPassword(resetForm: NgForm){

    var numberPrefix = '+234';
    var extractedNumber = this.userdata.phoneNumber.substring(1, this.userdata.phoneNumber.length);
    this.resetPhoneNumber = numberPrefix+extractedNumber;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/ConfirmRestPassword';
    var requestData = {
      phoneNumber: this.resetPhoneNumber,
      password: this.userdata.password,
      activationCode: this.userdata.code,
    }

    this.functionService.putHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Passsword reset successful.';
          this.toastNotification(requestResponse);

          resetForm.resetForm();

          this.goToPage('login');
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
