<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Product list
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Search by name" (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="right-search-section">
                        <button mat-flat-button (click)="searchRequest();">
                            Search
                        </button>
                    </div>
                </div>
                <div class="search-list-holder" *ngIf="showSearchItems" >
                    <div class="top-search-section">
                        <span (click)="closeSearch();">
                            close
                        </span>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length > 0">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                        (click)="selectProduct(x)">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.category.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length < 1">
                        <div class="loading-section">
                            <span class="no-data-holder" *ngIf="!showSearchLoading">
                                no result found for "{{searchData}}"
                            </span>
                            <span class="loader-holder" *ngIf="showSearchLoading">
                                loading...
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Product list
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('restock')" [class.activeMenu]="activeInnerMenu == 'restock'">
                <span>
                    <fa-icon icon="exchange-alt"></fa-icon> Restock Product
                </span>
            </div>
            <div class="menu-section" (click)="exportRequest()">
                <span>
                    <fa-icon icon="file-export"></fa-icon> Export Product List
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('products/add');">
                <span>
                    <fa-icon icon="plus"></fa-icon> Add Product
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of Products</b>
                    <b *ngIf="activeInnerMenu == 'restock'">restock Product</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="no-data-section"  *ngIf="products.length <= 0">
                            <div class="img-section">
                                <fa-icon icon="database"></fa-icon>
                            </div>
                            <div class="text-section">
                                <span>
                                    No Item Found
                                </span>
                            </div>
                            <div class="btn-section">
                                <button mat-flat-button (click)="goToPage('products/add');">
                                    Start Adding
                                    
                                </button>
                            </div>
                        </div>
                        <div class="data-section" *ngIf="products.length > 0">
                            <div class="list-section">
                                <div class="list-item-section" *ngFor="let x of products; let i = index" >
                                    <div class="list-item-content" (click)="selectProduct(x)">
                                        <div class="icon-section">
                                            <div class="circle">
                                                <span>
                                                    {{x.firstLetterOfCategory}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="details-section">
                                            <div class="top-details-section">
                                                <span>
                                                    {{x.name}}
                                                </span>
                                            </div>
                                            <div class="middle-details-section">
                                                <span>
                                                    <b *ngFor="let y of x.unitOfMeasurement">
                                                        {{y}}, 
                                                    </b>
                                                </span>
                                            </div>
                                            <div class="down-details-section">
                                                <span>
                                                    {{x.category.name}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pagination-section">
                                <div class="pagination-text" (click)="getPage(1)">
                                    <span>
                                        First
                                    </span>
                                </div>
                                <div class="pagination-text" (click)="paginate(-1)">
                                    <span>
                                        Prev
                                    </span>
                                </div>
                                <div class="pagination-text" [class.activePage]="pageIndex == x"
                                *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                    <span>
                                        {{x}}
                                    </span>
                                </div>
                                <div class="pagination-text" (click)="paginate(1)">
                                    <span>
                                        Next
                                    </span>
                                </div>
                                <div class="pagination-text" (click)="getPage(noOfPages)">
                                    <span>
                                        Last
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'restock'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchRestockData" autocomplete="off" 
                                    [(ngModel)]="searchRestockData" placeholder="Search by product name" 
                                    (keyup)="searchRestockEvent($event)" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of searchRestockResult; let i = index" 
                                (click)="chooseProduct(x)" [class.activeSearchItemSection]="x._id == restockDetails.item">
                                    <div class="top-search-item-section">
                                        <span>
                                            {{x.name}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            {{x.category.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="restockForm" #restockForm="ngForm" class="form" *ngIf="restockDetails.item"
                            (ngSubmit)="restockForm.form.valid && restockItem(restockForm)">
                                <div *ngFor="let x of restockDetails.unitOfMeasurement; let i = index"
                                class="measurement-section">
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Unit Name
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="name{{i}}" readonly
                                                [(ngModel)]="x.name" #name="ngModel" required/>
                                                <mat-error *ngIf="restockForm.submitted && name.invalid">
                                                    Please enter a valid unit name
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Quantity
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="number" name="quantity{{i}}" 
                                                [(ngModel)]="x.quantity" #quantity="ngModel"/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Cost Price (per {{x.name}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="number" name="costPrice{{i}}" 
                                                [(ngModel)]="x.costPrice" #costPrice="ngModel" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Expiry Date
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput [matDatepicker]="picker" name="expiryDate" #expiryDate="ngModel"
                                            [(ngModel)]="restockDetails.expiryDate" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                                            <mat-error *ngIf="restockForm.submitted && expiryDate.invalid">
                                                Please enter a valid date
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Restock
                                        <fa-icon icon="exchange-alt"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>