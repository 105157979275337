import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {

  // currency variables
  public currency: any = 'NGN';

  // profit/loss analysis variables
  public debtSales: any = [];
  public analysis: any = {
    totalDebt: 0,
    totalSales: 0,
    totalCost: 0,
    value: 0,
    result: null,
  };

  public role: boolean = false;

  // date picker variable
  public selectedFromDate: any = null;
  public selectedToDate: any = null;

  public selectedFromDateString: string = null;
  public selectedToDateString: string = null;

  public dateFilterShow = false;

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.selectedToDate = moment(new Date())
    this.selectedFromDate = moment(new Date()).subtract(30, "days")

    this.selectedFromDateString = this.selectedFromDate.format('YYYY-MM-D');
    this.selectedToDateString = this.selectedToDate.format('YYYY-MM-D')

    this.getDateRangeRecords()
  }

  // start and stop loading funx
  startLoading() {

    this.functionService.setLoading(true);

  }

  stopLoading() {

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // goto selected page
  goToPage(page) {

    this.functionService.setMenu(page);

  }

  // date event
  dateChangeFnc(event: any, selector: string) {

    const formattedDate = event.target.value.format('YYYY-MM-D');
    if (selector == 'to') this.selectedToDateString = formattedDate
    else this.selectedFromDateString = formattedDate;
    this.checkFilter();
  }

  // check filter date range
  checkFilter() {

    if (this.selectedFromDate == null || this.selectedToDate == null) {

      var requestResponse = 'Select from date and to date';
      this.toastNotification(requestResponse);

    } else if (this.selectedFromDate !== null && this.selectedToDate !== null) {

      this.getDateRangeRecords();

    } else {
      this.selectedFromDate = null;
      this.selectedToDate = null;
      this.dateFilterShow = false;
    }

  }

  private formatDateString(date: string): string {
    const [year, month, day] = date.split('-');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  private convertToServerTimeZone(date: string, userTimeZone: string, serverTimeZone: string): string {

    console.log({ date });

    // Ensure the date string is in the correct format
    const formattedDate = this.formatDateString(date);

    let userDateWithCurrentTime = DateTime.fromISO(formattedDate, { zone: userTimeZone });

    if (!userDateWithCurrentTime.isValid) {
      console.error("User date-time is invalid:", userDateWithCurrentTime.invalidExplanation);
      // Handle error...
      return "";
    }

    let now = DateTime.local().setZone(userTimeZone);

    if (!now.isValid) {
      console.error("Current date-time is invalid:", now.invalidExplanation);
      // Handle error...
      return "";
    }

    userDateWithCurrentTime = userDateWithCurrentTime.set({
      hour: now.hour,
      minute: now.minute,
      second: now.second
    });

    console.log({ userDateWithCurrentTime });

    const serverDate = userDateWithCurrentTime.setZone(serverTimeZone);

    if (!serverDate.isValid) {
      console.error("Server date-time is invalid:", serverDate.invalidExplanation);
      // Handle error...
      return "";
    }

    return serverDate.toISODate();
  }

  // get record between date range
  getDateRangeRecords() {

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    // console.log('this.selectedFromDate: ', this.selectedFromDateString);
    // console.log('this.selectedToDate: ', this.selectedToDateString);

    const serverTimeZone = "Africa/Lagos"; // WAT timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const serverStartDate = this.convertToServerTimeZone(this.selectedFromDateString, userTimeZone, serverTimeZone);
    const serverEndDate = this.convertToServerTimeZone(this.selectedToDateString, userTimeZone, serverTimeZone);

    var route = 'Account/GetBalanceSheetByDateRange';
    var requestData = {
      startDate: serverStartDate,
      endDate: serverEndDate,
    }

    // console.log('User Local Time: ', userTimeZone, this.selectedFromDateString, this.selectedToDateString);
    // console.log('Server Local Time: ', serverTimeZone, serverStartDate, serverEndDate);

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          this.analyzeBalanceSheet(res.BalanceSheetSales, res.DebitSales)
          this.role = res.allowViewTotalSales;
          console.log(this.role);

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // analyse balance sheet
  analyzeBalanceSheet(balanceSheet, debitSales) {

    this.analysis.totalSales = 0;
    this.analysis.totalCost = 0
    this.analysis.value = 0
    this.analysis.result = null

    for (let i = 0; i < balanceSheet.length; i++) {

      this.analysis.totalSales = Number(this.analysis.totalSales) + Number(balanceSheet[i].TotalSales)
      this.analysis.totalCost = Number(this.analysis.totalCost) + Number(balanceSheet[i].TotalCostPrice)

    }

    this.analysis.value = this.analysis.totalSales - this.analysis.totalCost;

    if (this.analysis.value < 0) {

      this.analysis.result = 'loss';

    } else {

      this.analysis.result = 'profit';

    }

    this.analysis.totalDebt = 0
    this.debtSales = debitSales;
    for (let i = 0; i < this.debtSales.length; i++) {

      this.analysis.totalDebt = Number(this.analysis.totalDebt) + Number(this.debtSales[i].remainingAmount)

    }

  }


}
