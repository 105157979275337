<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                E-commerce Featured Products
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Featured Products
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('online')" [class.activeMenu]="activeInnerMenu == 'online'">
                <span>
                    <fa-icon icon="sliders-h"></fa-icon> Toggle Product
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of E-commerce Featured Products</b>
                    <b *ngIf="activeInnerMenu == 'online'">set Product Featured status </b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="no-data-section"  *ngIf="products.length <= 0">
                            <div class="img-section">
                                <fa-icon icon="database"></fa-icon>
                            </div>
                            <div class="text-section">
                                <span>
                                    No Item Found
                                </span>
                            </div>
                            <div class="btn-section">
                                <button mat-flat-button (click)="toggleInnerMenu('online');">
                                    Toggle Product
                                    
                                </button>
                            </div>
                        </div>
                        <div class="data-section" *ngIf="products.length > 0">
                            <div class="list-section">
                                <div class="list-item-section" *ngFor="let x of products; let i = index" >
                                    <div class="list-item-content" (click)="selectProduct(x)">
                                        <div class="icon-section">
                                            <div class="circle">
                                                <span>
                                                    {{x.firstLetterOfCategory}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="details-section">
                                            <div class="top-details-section">
                                                <span>
                                                    {{x.name}}
                                                </span>
                                            </div>
                                            <div class="middle-details-section">
                                                <span>
                                                    <b *ngFor="let y of x.unitOfMeasurement">
                                                        {{y}}, 
                                                    </b>
                                                </span>
                                            </div>
                                            <div class="down-details-section">
                                                <span>
                                                    {{x.category.name}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'online'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchData" autocomplete="off" 
                                    [(ngModel)]="searchData" placeholder="Search by product name" 
                                    (keyup)="searchEvent($event)" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                                (click)="chooseProduct(x)" [class.activeSearchItemSection]="x._id == productDetails.id">
                                    <div class="top-search-item-section">
                                        <span>
                                            {{x.name}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            {{x.category.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="productForm" #productForm="ngForm" class="form" *ngIf="productDetails.id"
                            (ngSubmit)="productForm.form.valid && toggleFeaturedProducts(productForm)">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Product Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="name" readonly
                                            [(ngModel)]="productDetails.name" #name="ngModel" required/>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="checkbox-section">
                                    <mat-checkbox name="status" [(ngModel)]="productDetails.status"
                                    [color]="'primary'">
                                        New Status: <b *ngIf="productDetails.status">ENABLED</b>
                                        <b *ngIf="!productDetails.status">DISABLED</b>
                                    </mat-checkbox>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Change
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>