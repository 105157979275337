<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                E-commerce categories
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Category list
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('online')" [class.activeMenu]="activeInnerMenu == 'online'">
                <span>
                    <fa-icon icon="sliders-h"></fa-icon> Toggle Category
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of E-commerce categories</b>
                    <b *ngIf="activeInnerMenu == 'online'">set E-commerce category status </b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="no-data-section"  *ngIf="categories.length <= 0">
                            <div class="img-section">
                                <fa-icon icon="database"></fa-icon>
                            </div>
                            <div class="text-section">
                                <span>
                                    No category Found
                                </span>
                            </div>
                            <div class="btn-section">
                                <button mat-flat-button (click)="toggleInnerMenu('online');">
                                    Toggle category
                                    
                                </button>
                            </div>
                        </div>
                        <div class="data-section" *ngIf="categories.length > 0">
                            <div class="list-section">
                                <div class="list-item-section" *ngFor="let x of categories; let i = index" >
                                    <div class="list-item-content">
                                        <div class="icon-section">
                                            <div class="circle">
                                                <span>
                                                    {{x.firstLetter}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="details-section">
                                            <div class="top-details-section">
                                                <span>
                                                    {{x.name}}
                                                </span>
                                            </div>
                                            <div class="middle-details-section">
                                                <span>
                                                    {{x.subCategories.length |number}} sub-category
                                                </span>
                                            </div>
                                            <div class="down-details-section">
                                                <span>
                                                    
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'online'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="onlineForm" #onlineForm="ngForm" class="form"
                            (ngSubmit)="onlineForm.form.valid && toggleOnlineCategory(onlineForm)">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Category
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="category" [(ngModel)]="onlineCategoryDetails.id" required
                                            #category="ngModel">
                                                <mat-option [value]="x._id" *ngFor="let x of records">{{x.name}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="onlineForm.submitted && category.invalid">
                                                Please enter a valid category
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="checkbox-section">
                                    <mat-checkbox name="status" [(ngModel)]="onlineCategoryDetails.status"
                                    [color]="'primary'">
                                        New Status: <b *ngIf="onlineCategoryDetails.status">ENABLED</b>
                                        <b *ngIf="!onlineCategoryDetails.status">DISABLED</b>
                                    </mat-checkbox>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Change
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>