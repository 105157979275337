<div class="page-section">
    <div class="left-page-section">
        <div class="left-page-content">
            <div class="logo-section">
                <div class="logo-holder">
                    <img src="./assets/img/logo.png" alt="image">
                </div>
            </div>
            <div class="icon-section">
                <div class="icon-holder">
                    <img src="./assets/img/login.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="right-page-section">
        <div class="right-page-content">
            <div class="form-section">
                <div class="form-content">
                    <div class="main-text-section">
                        <span>
                            Sign in to your account
                        </span>
                    </div>
                    <div class="sub-text-section">
                        <span>
                            Please fill in your details to login
                        </span>
                    </div>
                    <form class="form-holder" name="loginForm" #loginForm="ngForm" 
                    (ngSubmit)="loginForm.form.valid && loginUser(loginForm)">
                        <div class="each-form-control">
                            <div class="label-section">
                                <span>
                                    Phone Number *
                                </span>
                            </div>
                            <div class="input-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="tel" name="phoneNumber" required
                                    [(ngModel)]="userdata.phoneNumber" #phoneNumber="ngModel" 
                                    pattern="[0-9]{11}"/>
                                    <mat-error *ngIf="loginForm.submitted && phoneNumber.invalid">
                                        Please enter a valid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="each-form-control">
                            <div class="label-section">
                                <span>
                                    Password *
                                </span>
                            </div>
                            <div class="input-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="password" name="password" [(ngModel)]="userdata.password" 
                                    required #password="ngModel" autocomplete="doNotAutoComplete" />
                                    <mat-error *ngIf="loginForm.submitted && password.invalid">
                                        Please enter a valid password
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <span (click)="goToPage('password')">
                                Forgot Password
                            </span>
                            <button mat-flat-button>
                                Sign In
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>