<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Rider List
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Search by rider number" (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="right-search-section">
                        <button mat-flat-button (click)="searchRequest();">
                            Search
                        </button>
                    </div>
                </div>
                <div class="search-list-holder" *ngIf="showSearchItems" >
                    <div class="top-search-section">
                        <span (click)="closeSearch();">
                            close
                        </span>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length > 0">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                        (click)="selectStaff(x)">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.personalInfo.firstName}} {{x.personalInfo.lastName}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.phoneNumber}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length < 1">
                        <div class="loading-section">
                            <span class="no-data-holder" *ngIf="!showSearchLoading">
                                no result found for "{{searchData}}"
                            </span>
                            <span class="loader-holder" *ngIf="showSearchLoading">
                                loading...
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> All Rider
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="user"></fa-icon> Add Rider
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of Riders</b>
                    <b *ngIf="activeInnerMenu == 'add'">Add a Rider</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Rider details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section" [ngbPopover]="popContent"
                                triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Rider Name
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedStaff.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Phone Number
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedStaff.phoneNumber}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Email Address
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span style="text-transform: lowercase;">
                                                {{selectedStaff.email}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Rider Active?
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                <b *ngIf="selectedStaff.active">YES</b>
                                                <b *ngIf="!selectedStaff.active">NO</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="staffList.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="staffList.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of staffList; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectStaff(x)"
                                    [class.activeRecord]="x._id == selectedStaff.id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.personalInfo.firstName}} {{x.personalInfo.lastName}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            {{x.phoneNumber}}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-section">
                                    <div class="pagination-text" (click)="getPage(1)">
                                        <span>
                                            First
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(-1)">
                                        <span>
                                            Prev
                                        </span>
                                    </div>
                                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                        <span>
                                            {{x}}
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(1)">
                                        <span>
                                            Next
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="getPage(noOfPages)">
                                        <span>
                                            Last
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && addRider(addForm)"
                             class="form" >
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            First Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="firstName" 
                                            [(ngModel)]="addRiderDetails.firstName" #firstName="ngModel" required/>
                                            <mat-error *ngIf="addForm.submitted && firstName.invalid">
                                                Please enter a valid first name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Last Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="lastName" 
                                            [(ngModel)]="addRiderDetails.lastName" #lastName="ngModel" required/>
                                            <mat-error *ngIf="addForm.submitted && lastName.invalid">
                                                Please enter a valid last name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Mobile Number
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="tel" name="phoneNumber" #phoneNumber="ngModel"
                                            [(ngModel)]="addRiderDetails.phoneNumber" required pattern="[0-9]{11}"/>
                                            <mat-error *ngIf="addForm.submitted && phoneNumber.invalid">
                                                Please enter a valid phone number
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Add
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>