<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Product Details
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('data')" [class.activeMenu]="activeInnerMenu == 'data'" >
                <span>
                    <fa-icon icon="info-circle"></fa-icon> Product Data
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('editData')" [class.activeMenu]="activeInnerMenu == 'editData'" >
                <span>
                    <fa-icon icon="edit"></fa-icon> Edit Product Data
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('editUnits')" [class.activeMenu]="activeInnerMenu == 'editUnits'" >
                <span>
                    <fa-icon icon="edit"></fa-icon> Edit Product Units
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('images')" [class.activeMenu]="activeInnerMenu == 'images'" >
                <span>
                    <fa-icon icon="images"></fa-icon> Product Images
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('names')" [class.activeMenu]="activeInnerMenu == 'names'" >
                <span>
                    <fa-icon icon="pen-square"></fa-icon> Change Unit Names
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('products/list');" >
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Product list
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'data'">product details </b>
                    <b *ngIf="activeInnerMenu == 'editData'">edit product details </b>
                    <b *ngIf="activeInnerMenu == 'editUnits'">edit measurement details </b>
                    <b *ngIf="activeInnerMenu == 'images'">product images </b>
                    <b *ngIf="activeInnerMenu == 'names'">change measurement names </b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'data'">
                    <div class="data-content-section">
                        <div class="left-data-content-section">
                            <div class="step-section">
                                <span>
                                    product data
                                </span>
                            </div>
                            <div class="form-section">
                                <div class="img-section">
                                    <fa-icon icon="prescription-bottle-alt" 
                                    *ngIf="!updateProductDetails.displayImage"></fa-icon>
                                    <img src="{{updateProductDetails.displayImage}}" 
                                    *ngIf="updateProductDetails.displayImage" alt="image">
                                </div>
                                <div class="item-details-section">
                                    <div class="label-section">
                                        <span>
                                            Product Name
                                        </span>
                                    </div>
                                    <div class="value-section">
                                        <span>
                                           {{updateProductDetails.name}}
                                        </span>
                                    </div>
                                </div>
                                <div class="item-details-section">
                                    <div class="label-section">
                                        <span>
                                            Product Category
                                        </span>
                                    </div>
                                    <div class="value-section">
                                        <span>
                                           {{updateProductDetails.category.name}}
                                        </span>
                                    </div>
                                </div>
                                <div class="item-details-section">
                                    <div class="label-section">
                                        <span>
                                            Product Shelf Number
                                        </span>
                                    </div>
                                    <div class="value-section">
                                        <span>
                                           {{updateProductDetails.shelfNumber}}
                                        </span>
                                    </div>
                                </div>
                                <div class="big-item-details-section">
                                    <div class="label-section">
                                        <span>
                                            Product Descritpion
                                        </span>
                                    </div>
                                    <div class="value-section">
                                        <span>
                                           {{updateProductDetails.description}}
                                        </span>
                                    </div>
                                </div>
                                <div class="big-item-details-section">
                                    <div class="label-section">
                                        <span>
                                            Details were last updated at
                                        </span>
                                    </div>
                                    <div class="value-section">
                                        <span>
                                            {{updateProductDetails.updatedAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-data-content-section">
                            <div class="step-section">
                                <span >
                                    Unit of measurements
                                </span>
                            </div>
                            <div class="form-section">
                                <div class="measurement-section" *ngFor="let x of updateProductDetails.unitOfMeasurement;">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                unit name
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Cost Price
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                &#8358; {{x.costPrice | number:'.2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Wholesale Selling Price
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                &#8358; {{x.limitPrice | number:'.2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Retail Selling Price
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                &#8358; {{x.sellingPrice | number:'.2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Quantity
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.quantity | number}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Wholesale Quantity
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.wholesalesQuantity | number}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Reorder Limit
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.reorderLimit | number}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Bar Code
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.barCode}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Type of Discount
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.discountType}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section" *ngIf="x.discountType == 'bulk' ">
                                        <div class="label-section">
                                            <span>
                                                Number of {{x.name}}s to apply Bulk discount on
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.discountUnit | number}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section" *ngIf="x.discountType !== 'none'">
                                        <div class="label-section">
                                            <span>
                                                Discount Percentage
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{x.discountPercentage | number}}%
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'editData'">
                    <div class="add-content-section">
                        <div class="left-add-content-section">
                            <div class="step-section">
                                <span>
                                    product data
                                </span>
                            </div>
                            <div class="form-section">
                                <form (ngSubmit)="stepOneForm.form.valid && updateProduct()"
                                name="stepOneForm" #stepOneForm="ngForm">
                                    <div class="img-section">
                                        <fa-icon icon="prescription-bottle-alt" (click)="fileInput.click()"
                                        *ngIf="!updateProductData.displayImage"></fa-icon>
                                        <img src="{{updateProductData.displayImage}}" (click)="fileInput.click()"
                                        *ngIf="updateProductData.displayImage" alt="image">
                                        <input hidden type="file" required accept="image/*" id="file" 
                                        (change)="onFileSelected()" #fileInput/>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Name
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="name" #name="ngModel" required
                                                [(ngModel)]="updateProductData.name" />
                                                <mat-error *ngIf="stepOneForm.submitted && name.invalid">
                                                    Please enter a valid item name
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Category
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="category" [(ngModel)]="updateProductData.category" required
                                                #category="ngModel">
                                                    <mat-option style="text-transform: capitalize;" value="{{x._id}}" 
                                                    *ngFor="let x of categories">{{x.name}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="stepOneForm.submitted && category.invalid">
                                                    Please select a valid category
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="big-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Shelf Number
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="shelfNumber" #shelfNumber="ngModel" 
                                                [(ngModel)]="updateProductData.shelfNumber" maxlength="50" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="big-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Descritpion
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="description" #description="ngModel" 
                                                [(ngModel)]="updateProductData.description" maxlength="50" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="btn-section">
                                        <button mat-flat-button>
                                            Update
                                            
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'editUnits'">
                    <div class="add-content-section">
                        <div class="left-add-content-section">
                            <div class="step-section">
                                <span>
                                    product data
                                </span>
                            </div>
                            <div class="form-section">
                                <form >
                                    <div class="img-section">
                                        <fa-icon icon="prescription-bottle-alt" 
                                        *ngIf="!updateProductDetails.displayImage"></fa-icon>
                                        <img src="{{updateProductDetails.displayImage}}" 
                                        *ngIf="updateProductDetails.displayImage" alt="image">
                                    </div>
                                    <div class="big-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Number of Unit of Measurements
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="noOfMeasurement" #noOfMeasurement="ngModel" required
                                                [(ngModel)]="updateProductMeasurements.noOfMeasurement" 
                                                (selectionChange)="generateMeasurementBoxes()">
                                                    <mat-option value="{{x}}" *ngFor="let x of measurementMaxList;">
                                                        {{x}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="big-form-control-section" 
                                    *ngFor="let x of updateProductMeasurements.unitOfMeasurement; let i = index">
                                        <div class="label-section">
                                            <span>
                                                Unit of Measurement ({{i+1}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="unit{{i}}" 
                                                [disabled]="updateProductMeasurements.unitOfMeasurement[i].costPrice"
                                                [(ngModel)]="updateProductMeasurements.unitOfMeasurement[i].name" required/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="right-add-content-section">
                            <div class="step-section">
                                <span >
                                    Unit of measurements
                                </span>
                            </div>
                            <div class="form-section">
                                <form (ngSubmit)="stepTwoForm.form.valid && goToFinalStep()"
                                name="stepTwoForm" #stepTwoForm="ngForm" >
                                    <div *ngFor="let x of updateProductMeasurements.unitOfMeasurement;"
                                    class="measurement-section">
                                        <div class="left-measurement-section">
                                            <span>
                                                {{x.name}}
                                            </span>
                                        </div>
                                        <div class="right-measurement-section">
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.costPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.limitPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.sellingPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Cost Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="costPrice{{x}}" 
                                                        [(ngModel)]="x.costPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Wholesale Selling Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="limitPrice{{x}}" 
                                                        [(ngModel)]="x.limitPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Retail Selling Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="sellingPrice{{x}}" 
                                                        [(ngModel)]="x.sellingPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Quantity
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="quantity{{x}}" 
                                                        [(ngModel)]="x.quantity" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Wholesale Quantity
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="wholesalesQuantity{{x}}" 
                                                        [(ngModel)]="x.wholesalesQuantity" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Reorder Limit
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="reorderLimit{{x}}" 
                                                        [(ngModel)]="x.reorderLimit" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Bar Code
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="text" name="barCode{{x}}" 
                                                        [(ngModel)]="x.barCode"/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Type of Discount
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <mat-select name="discountType" [(ngModel)]="x.discountType" required>
                                                            <mat-option value="{{y}}" *ngFor="let y of discountTypeList;">
                                                                {{y}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section" *ngIf="x.discountType == 'bulk' ">
                                                <div class="label-section">
                                                    <span>
                                                        Number of {{x.name}}s to apply Bulk discount on
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="discountUnit{{x}}" 
                                                        [(ngModel)]="x.discountUnit" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section" *ngIf="x.discountType !== 'none'">
                                                <div class="label-section">
                                                    <span>
                                                        Discount Percentage
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="discountPercentage{{x}}" 
                                                        [(ngModel)]="x.discountPercentage" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-section">
                                        <button mat-flat-button>
                                            Update
                                            
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'images'">
                    <div class="image-section">
                        <div class="image-item-section" *ngFor="let x of updateProductDetails.moreImages">
                            <div class="top-image-item-section" >
                                <img src="{{x.imgUri}}" alt="image">
                            </div>
                            <div class="down-image-item-section">
                                <fa-icon icon="trash-alt" (click)="deleteImages(x)"></fa-icon>
                            </div>
                        </div>
                        <div class="add-image-section">
                            <div class="no-image-section"  *ngIf="!moreImagesDetails.displayImage" >
                                <fa-icon icon="image" (click)="fileMoreInput.click()"></fa-icon>
                            </div>
                            <div class="image-exist-section" *ngIf="moreImagesDetails.displayImage">
                                <div class="top-image-exist-section" >
                                    <img src="{{moreImagesDetails.displayImage}}" (click)="fileMoreInput.click()"
                                     alt="image">
                                </div>
                                <div class="down-image-exist-section">
                                    <fa-icon icon="plus" (click)="addMoreImages()"></fa-icon>
                                </div>
                            </div>
                            <input hidden type="file" required accept="image/*" id="fileMore" 
                            (change)="onMoreFileSelected()" #fileMoreInput/>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'names'">
                    <div class="add-content-section">
                        <div class="left-add-content-section">
                            <div class="step-section">
                                <span>
                                    product data
                                </span>
                            </div>
                            <div class="form-section">
                                <form name="editForm" #editForm="ngForm" (ngSubmit)="editForm.form.valid && editUnitName(editForm)">
                                    <div class="img-section">
                                        <fa-icon icon="prescription-bottle-alt" 
                                        *ngIf="!updateProductDetails.displayImage"></fa-icon>
                                        <img src="{{updateProductDetails.displayImage}}" 
                                        *ngIf="updateProductDetails.displayImage" alt="image">
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Old Name of Measurement
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="oldUnit" [(ngModel)]="editUnitDetails.oldUnit" required 
                                                #oldUnit="ngModel" >
                                                    <mat-option value="{{x}}" *ngFor="let x of editUnitDetails.measurements;">
                                                        {{x}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="editForm.submitted && oldUnit.invalid">
                                                    Please select a valid name of measurement
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                New Name of Measurement
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="newUnit" [(ngModel)]="editUnitDetails.newUnit" 
                                                required #newUnit="ngModel" />
                                                <mat-error *ngIf="editForm.submitted && newUnit.invalid">
                                                    Please select a valid name of measurement
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="btn-section">
                                        <button mat-flat-button>
                                            Change
                                            
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>