<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Return a Sold Product
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('return')" [class.activeMenu]="activeInnerMenu == 'return'">
                <span>
                    <fa-icon icon="exchange-alt"></fa-icon> Return Product
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('records/sales');">
                <span>
                    <fa-icon icon="money-bill-wave-alt"></fa-icon>  sales record
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('records/return/all');">
                <span>
                    <fa-icon icon="undo-alt"></fa-icon>  return record
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'return'">return a Product</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'return'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchData" autocomplete="off" 
                                    [(ngModel)]="searchData" placeholder="Search by reciept ID" 
                                    (keyup)="search()" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of salesRecords; let i = index" 
                                (click)="chooseReceipt(x)" [class.activeSearchItemSection]="x._id == selectedRecord._id">
                                    <div class="top-search-item-section">
                                        <span>
                                            Receipt ID: {{x.receiptId}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            &#8358;  {{x.totalAmount | number: '.2'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="receiptForm" #receiptForm="ngForm" class="form" *ngIf="selectedRecord._id"
                            (ngSubmit)="receiptForm.form.valid && processReturn(receiptForm)">
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Product
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="productId" [(ngModel)]="returnDetails.productId" required 
                                            #productId="ngModel" (selectionChange)="chooseProduct($event)">
                                                <mat-option value="{{x.id}}" *ngFor="let x of selectedRecord.products">
                                                    {{x.productName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="receiptForm.submitted && productId.invalid">
                                                Please enter a valid product
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Unit
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="unit" [(ngModel)]="returnDetails.unit" required 
                                            #unit="ngModel" (selectionChange)="chooseProductMeasurement($event)">
                                                <mat-option value="{{x.name}}" *ngFor="let x of returnDetails.measurements">
                                                    {{x.name}} (&#8358; {{x.sellingPrice | number:'.2'}})
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="receiptForm.submitted && unit.invalid">
                                                Please enter a valid unit
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Type of Return
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <mat-select name="returnType" name="returnType" 
                                            [(ngModel)]="returnDetails.returnType" #returnType="ngModel" required >
                                                <mat-option value="{{x}}" *ngFor="let x of typeOfReturns">
                                                    {{x}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="receiptForm.submitted && returnType.invalid">
                                                Please enter a valid type of return
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Available Quantity
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="soldQuantity" readonly
                                            [(ngModel)]="returnDetails.activeUnit.soldQuantity" #soldQuantity="ngModel" />
                                            <mat-error *ngIf="receiptForm.submitted && soldQuantity.invalid">
                                                Please enter a valid Quantity
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Return Quantity
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="qty" (keyup)="calculateRefundAmount()"
                                            [(ngModel)]="returnDetails.activeUnit.qty" #qty="ngModel" />
                                            <mat-error *ngIf="receiptForm.submitted && qty.invalid" >
                                                Please enter a valid Quantity
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="big-form-control-section"  *ngIf="returnDetails.returnType == 'Refund'">
                                    <div class="label-section">
                                        <span>
                                            Amount Refunded
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="number" name="amountReturn" readonly
                                            [(ngModel)]="returnDetails.amountReturn" #amountReturn="ngModel" />
                                            <mat-error *ngIf="receiptForm.submitted && amountReturn.invalid">
                                                Please enter a valid Amount
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Return
                                        <fa-icon icon="exchange-alt"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>