<div class="page-section">
    <div class="right-section" [@animateArc]="shoppingCartView">
        <div class="direction-icon-section"  (click)="toggleCartView()">
            <fa-icon icon="shopping-cart"></fa-icon>
        </div>
        <div class="vertical-text-section" *ngIf="shoppingCartView == 'true'" (click)="toggleCartView()">
            <span>
                Click to open cart
            </span>
        </div>
        <div class="cart-list-section" *ngIf="shoppingCartView == 'false'">
            <div class="top-cart-section">
                <div class="left-top-cart-section">
                    <span>
                        list of carts
                    </span>
                </div>
                <div class="right-top-cart-section">
                    <div class="icon-section" (click)="addMoreCart()">
                        <fa-icon icon="plus"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="down-cart-section">
                <div class="cart-list-item-section" *ngFor="let x of listOfCarts; let i = index">
                    <div class="cart-title-btn-section">
                        <div class="cart-title-section">
                            <span>
                                cart {{i+1}}: <i>{{x.cart.length}} item(s)</i>
                            </span>
                        </div>
                        <div class="cart-btn-section">
                            <div class="left-cart-btn-section">
                                <div class="icon-section" (click)="removeCart(i)">
                                    <fa-icon icon="trash"></fa-icon>
                                </div>
                            </div>
                            <div class="right-cart-btn-section">
                                <div class="icon-section" [class.activeCartSection]="x.status" (click)="chooseCart(i)">
                                    <fa-icon icon="check"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-section" *ngFor="let y of x.cart; let j = index;">
                        <div class="left-cart-item-section">
                            <div class="circle">
                                <span>
                                    {{y.firstLetterOfCategory}}
                                </span>
                            </div>
                        </div>
                        <div class="right-cart-item-section">
                            <div class="top-right-cart-item-section">
                                <span>
                                    {{y.name}} ({{y.measurement}})
                                </span>
                            </div>
                            <div class="middle-right-cart-item-section">
                                <span>
                                    AQ: <b>{{y.totalQuantity | number}}</b>
                                </span>
                            </div>
                            <div class="down-right-cart-item-section">
                                <span>
                                    &#8358; {{y.cartAmount | number:'.2'}}
                                </span>
                            </div>
                            <div class="cart-item-input-btn-section">
                                <div class="cart-item-input-section">
                                    <input type="number" autocomplete="off" max="{{y.totalQuantity}}" name="qty" name="cartqty{{j}}"
                                    [(ngModel)]="y.cartQty" (keyup)="calculateUnitAmount(y)" min="1"  >
                                </div>
                                <div class="cart-item-btn-section">
                                    <div class="icon-section" (click)="removeItem(j,i)">
                                        <fa-icon icon="trash"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-list-btn-section" *ngIf="cart.length > 0">
                <button mat-flat-button (click)="proccessCart()">
                    Proceed
                    
                </button>
            </div>
        </div>
    </div>
    <div class="left-section" [@animateOtherArc]="marketView">
        <div class="data-section" *ngIf="products.length > 0">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Search by product name" (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="right-search-section">
                        <button mat-flat-button (click)="searchRequest();">
                            Search
                        </button>
                    </div>
                </div>
                <div class="search-list-holder" *ngIf="showSearchItems" [ngbPopover]="popContent" 
                [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                    <div class="top-search-section">
                        <span (click)="closeSearch();">
                            close
                        </span>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length > 0">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                        (click)="selectProduct(x, i, 'search', popOver)">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.category.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length < 1">
                        <div class="loading-section">
                            <span class="no-data-holder" *ngIf="!showSearchLoading">
                                no result found for "{{searchData}}"
                            </span>
                            <span class="loader-holder" *ngIf="showSearchLoading">
                                loading...
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-section">
                <div class="list-item-section" *ngFor="let x of products; let i = index" 
                [class.newListItemSection]="marketView == 'true'">
                    <div class="list-item-content" (click)="selectProduct(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                        <div class="icon-section">
                            <div class="circle">
                                <span>
                                    {{x.firstLetterOfCategory}}
                                </span>
                            </div>
                        </div>
                        <div class="details-section">
                            <div class="top-details-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="middle-details-section">
                                <span>
                                    <b *ngFor="let y of x.unitOfMeasurement">
                                        {{y}}, 
                                    </b>
                                </span>
                            </div>
                            <div class="down-details-section">
                                <span>
                                    {{x.category.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-section">
                <div class="pagination-text" (click)="getPage(1)">
                    <span>
                        First
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(-1)">
                    <span>
                        Prev
                    </span>
                </div>
                <div class="pagination-text" [class.activePage]="pageIndex == x"
                *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                    <span>
                        {{x}}
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(1)">
                    <span>
                        Next
                    </span>
                </div>
                <div class="pagination-text" (click)="getPage(noOfPages)">
                    <span>
                        Last
                    </span>
                </div>
            </div>
        </div>
        <div class="no-data-section"  *ngIf="products.length <= 0">
            <div class="img-section">
                <fa-icon icon="database"></fa-icon>
            </div>
            <div class="text-section">
                <span>
                    No Item Found
                </span>
            </div>
            <div class="btn-section">
                <button mat-flat-button (click)="goToPage('products/add');">
                    Start Adding
                    
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <div class="shelf-number">
                <span>
                    <b>Shelf number: </b>
                    {{selectedProduct.shelfNumber}}
                </span>
            </div>
            <div class="item-name">
                <span>
                    {{selectedProduct.name}}
                </span>
            </div>
        </div>
        <div class="down-popover-section">
            <div class="popover-data-section" *ngIf="selectedProduct.saleStructure.length > 0">
                <div class="left-popover-section">
                    <div class="popover-list-item-section" *ngFor='let x of selectedProduct.saleStructure; let i = index'
                    [class.activeMeasurementSection]="activeMeasurement.measurement == x.measurement" 
                    (click)="selectActiveMeasurement(x)">
                        <span>
                            {{x.measurement}}
                        </span>
                    </div>
                </div>
                <div class="right-popover-section">
                    <div class="top-right-section">
                        <span>
                            {{activeMeasurement.measurement}}
                        </span>
                    </div>
                    <div class="down-right-section" *ngIf="activeMeasurement.measurement">
                        <div class="popover-details-section">
                            <div class="label-section">
                                <span>
                                    wholesale price
                                </span>
                            </div>
                            <div class="value-section">
                                <span>
                                    &#8358; {{activeMeasurement.limitPrice | number:'.2'}}
                                </span>
                            </div>
                        </div>
                        <div class="popover-details-section">
                            <div class="label-section">
                                <span>
                                    retail price
                                </span>
                            </div>
                            <div class="value-section">
                                <span>
                                    &#8358; {{activeMeasurement.sellingPrice | number:'.2'}}
                                </span>
                            </div>
                        </div>
                        <div class="popover-details-section">
                            <div class="label-section">
                                <span>
                                    available quantity 
                                </span>
                            </div>
                            <div class="value-section">
                                <span>
                                    {{activeMeasurement.totalQuantity | number}}
                                </span>
                            </div>
                        </div>
                        <div class="popover-details-section">
                            <div class="label-section">
                                <span>
                                    wholesale quantity
                                </span>
                            </div>
                            <div class="value-section">
                                <span>
                                    {{activeMeasurement.wholesalesQuantity | number}}
                                </span>
                            </div>
                        </div>
                        <div class="popover-details-section">
                            <div class="label-section">
                                <span>
                                    discount
                                </span>
                            </div>
                            <div class="value-section">
                                <span *ngIf="activeMeasurement.discountType == 'bulk'">
                                    {{activeMeasurement.discountPercentage | number:'.2'}}% 
                                    from {{activeMeasurement.discountUnit | number}} {{activeMeasurement.measurement}}
                                </span>
                                <span *ngIf="activeMeasurement.discountType == 'single'">
                                    {{activeMeasurement.discountPercentage | number:'.2'}}% 
                                    per {{activeMeasurement.measurement}}
                                </span>
                            </div>
                        </div>
                        <div class="popover-amount-section">
                            <span>
                                &#8358; {{activeMeasurement.cartAmount | number:'.2'}}
                            </span>
                        </div>
                        <div class="popover-input-btn-section">
                            <div class="popover-input-section">
                                <input type="number" autocomplete="off" name="qty" (keyup)="calculateUnitAmount(activeMeasurement)" min="1" 
                                max="{{activeMeasurement.totalQuantity}}" [(ngModel)]="activeMeasurement.cartQty" id="cartQty">
                            </div>
                            <div class="popover-btn-section" *ngIf="activeMeasurement.cartQty > 0">
                                <button mat-flat-button (click)="addToCart();">
                                    Add
                                    <fa-icon icon="plus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="down-right-section" *ngIf="!activeMeasurement.measurement">
                        <div class="no-active-measurement">
                            <span>
                                Click on a unit of measurement for more details.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popover-no-data-section" *ngIf="selectedProduct.saleStructure.length < 1">
                <span>
                    Out of stock.
                </span>
            </div>
        </div>
    </div>
</ng-template>

