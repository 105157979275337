<div class="page-section">
    <div class="page-title-section">
        <div class="btn-section">
            <button mat-flat-button (click)="goToPage('account/market');">
                <fa-icon icon="chevron-left"></fa-icon>
                Market
            </button>
        </div>
        <div class="text-section">
            <span>
                Cart
            </span>
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="data-section" *ngIf="cartInitializedArray.length > 0">
                <div class="list-section">
                    <div class="cart-list-item-section" *ngFor="let y of cartInitializedArray; let j = index">
                        <div class="cart-item-section">
                            <div class="right-cart-item-section">
                                <div class="top-right-cart-item-section">
                                    <span>
                                        {{y.name}} ({{y.measurement}})
                                    </span>
                                </div>
                                <div class="middle-right-cart-item-section">
                                    <span>
                                        available qty: <b>{{y.totalQuantity | number}}</b>above
                                    </span>
                                </div>
                                <div class="middle-right-cart-item-section" >
                                    <span>
                                        wholesale qty: <b *ngIf="y.wholesalesQuantity">{{y.wholesalesQuantity | number}}</b>
                                    </span>
                                </div>
                                <div class="middle-right-cart-item-section">
                                    <span>
                                        wholesale price: <b>&#8358; {{y.limitPrice |  number:'.2'}}</b>
                                    </span>
                                </div>
                                <div class="middle-right-cart-item-section">
                                    <span>
                                        retail price: <b>&#8358; {{y.sellingPrice |  number:'.2'}}</b>
                                    </span>
                                </div>
                                <div class="down-right-cart-item-section">
                                    <span>
                                        &#8358; {{y.cartAmount | number:'.2'}}
                                    </span>
                                </div>
                                <div class="cart-item-input-btn-section">
                                    <div class="cart-item-input-section">
                                        <input type="number" autocomplete="off" max="{{y.totalQuantity}}" name="qty" name="cartqty{{j}}"
                                        [(ngModel)]="y.cartQty" (keyup)="calculateUnitAmount(y)" min="1"  >
                                    </div>
                                    <div class="cart-item-btn-section">
                                        <div class="icon-section" (click)="removeItem(j)">
                                            <fa-icon icon="trash"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="cartInitializedArray.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No Item Found
                    </span>
                </div>
            </div>
        </div>
        <div class="right-section">
            <div class="top-cart-section">
                <div class="right-top-cart-section" *ngIf="showCustomerDetailsStatus">
                    <div class="icon-section" (click)="continueAfterCustomer(false)">
                        <fa-icon icon="long-arrow-alt-left"></fa-icon>
                    </div>
                </div>
                <div class="right-top-cart-section" *ngIf="!showCustomerDetailsStatus">
                    <div class="icon-section" *ngIf="!addCustomerDetailsStatus" (click)="toggleAddCustomer(true)">
                        <fa-icon icon="plus"></fa-icon>
                    </div>
                    <div class="icon-section" *ngIf="addCustomerDetailsStatus" (click)="toggleAddCustomer(false)">
                        <fa-icon icon="search"></fa-icon>
                    </div>
                </div>
                <div class="left-top-cart-section">
                    <span>
                        Customer Details
                    </span>
                </div>
            </div>
            <div class="down-cart-section">
                <div class="search-content-section" *ngIf="!addCustomerDetailsStatus && !showCustomerDetailsStatus">
                    <div class="search-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="searchData" autocomplete="off" 
                            [(ngModel)]="searchData" placeholder="Search by customer name" autocomplete="off"
                            (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="search-result-section">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                        (click)="selectCustomer(x)" [class.activeSearchItemSection]="x._id == customerDetails.id">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.fullName}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.phoneNumber}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-section" *ngIf="addCustomerDetailsStatus && !showCustomerDetailsStatus">
                    <form name="addForm" #addForm="ngForm" class="form" (ngSubmit)="addForm.form.valid && addCustomer()">
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Phone Number
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="phoneNumber" #phoneNumber="ngModel"
                                    [(ngModel)]="customerDetails.phoneNumber" required/>
                                    <mat-error *ngIf="addForm.submitted && phoneNumber.invalid">
                                        Please enter a valid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Full Name
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="fullName" 
                                    [(ngModel)]="customerDetails.fullName" #fullName="ngModel" required/>
                                    <mat-error *ngIf="addForm.submitted && fullName.invalid">
                                        Please enter a valid customer full name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Add
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="form-section" *ngIf="showCustomerDetailsStatus">
                    <form name="customerForm" #customerForm="ngForm" class="form" 
                    (ngSubmit)="customerForm.form.valid && processCart()">
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Customer
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="fullName" readonly
                                    [(ngModel)]="processedCheckoutData.customer.fullName" #fullName="ngModel" required/>
                                    <mat-error *ngIf="customerForm.submitted && fullName.invalid">
                                        Please enter a valid customer full name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-checkbox name="status" [(ngModel)]="processedCheckoutData.deliveryStatus" class="checkbox-section"
                        [color]="'primary'">
                            Is there delivery? <b *ngIf="processedCheckoutData.deliveryStatus">YES</b>
                            <b *ngIf="!processedCheckoutData.deliveryStatus">NO</b>
                        </mat-checkbox>
                        <div class="big-form-control-section" *ngIf="processedCheckoutData.deliveryStatus">
                            <div class="label-section">
                                <span>
                                    Delivery Address
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput style="text-transform:capitalize;" type="text" name="locationName" 
                                    [(ngModel)]="processedCheckoutData.locationName" #locationName="ngModel" />
                                    <mat-error *ngIf="customerForm.submitted && locationName.invalid">
                                        Please enter a valid delivery address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="big-form-control-section" *ngIf="processedCheckoutData.deliveryStatus">
                            <div class="label-section">
                                <span>
                                    Delivery State
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <mat-select name="state" [(ngModel)]="processedCheckoutData.state" required 
                                    #state="ngModel">
                                        <mat-option style="text-transform: capitalize;" value="{{x}}" 
                                        *ngFor="let x of listOfStates">{{x}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="customerForm.submitted && state.invalid">
                                        Please enter a valid delivery state
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="big-form-control-section">
                            <div class="label-section">
                                <span>
                                    Wholesale token to use wholesale price
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="wholesaleToken" 
                                    [(ngModel)]="processedCheckoutData.wholesaleToken" #wholesaleToken="ngModel" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button type="submit">
                                Continue
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>