import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../../functions/request.service';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-expiry-staff',
  templateUrl: './expiry-staff.component.html',
  styleUrls: ['./expiry-staff.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class ExpiryStaffComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // staff details
  public staffId: any = null;

  // records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  //filter variables
  public searchData: any;

  

  constructor(
    private functionService: RequestService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((page)=>{

      if(page){

        if(page.id){

          this.staffId = page.id;

          this.getRecords();

        }
        
      }

    });

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetStaffExpiryProduct';
    var requestData = {
      staffId: this.staffId,
      page: this.pageIndex,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.ExpiryProduct;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.product = record.productId ? record.productId : null
    this.selectedRecord.dateExpired = record.expiryDate ? record.expiryDate : null
    this.selectedRecord.comment = record.comment ? record.comment : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    } : {
      name: null,
      phoneNumber: null,
      id: null
    }
    this.selectedRecord.measurements = []

    // convert object to array
    var objectKeys = Object.keys(record.quantity);
    for (let j = 0; j < objectKeys.length; j++) {
      
      var unit = objectKeys[j];

      var measurement = {
        name: unit,
        quantity: record.quantity[unit],
        priceAtExpiration: record.priceAtExpiration[unit]
      }

      this.selectedRecord.measurements.push(measurement);
      
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }


}
