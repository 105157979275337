import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-ranks',
  templateUrl: './ranks.component.html',
  styleUrls: ['./ranks.component.scss']
})
export class RanksComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

  // role variable
  public roles: any = [];

  // staff position variables
  public positions: any = [];
  public showSelectedPosition = false;
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  public editPositionDetails: any = {
    id: '',
    name: '',
    roles: []
  }


  // MODAL VARIABLES
  public createPositionStatus = false;

  public positionDetails: any = {
    name: ''
  };

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRoles();

    this.getPositions();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu
    if(menu == 'add'){
      for (let i = 0; i < this.roles.length; i++) {
        
        this.roles[i].checked = false;
        
      }
    }

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  clearRoles(){
    for (let i = 0; i < this.roles.length; i++) {
        
      this.roles[i].checked = false;
      
    }
  }

  // get roles
  getRoles(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/GetRoles';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.roles = [];

          var roles = res.Role;

          for (let i = 0; i < roles.length; i++) {
            
            if(roles[i]){

              var singleRole = {
                name: roles[i],
                checked: false
              }

              this.roles.push(singleRole);

            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // get staff positions
  getPositions(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/GetAvailableStaffPosition';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.positions = res.ManagersPosition;
          for (let i = 0; i < this.positions.length; i++) {
            
            this.positions[i].firstLetter = this.positions[i].positionName.substring(0,1);
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select record fnc
  selectRecord(record, initiator: any, position: any, popOver?: any){

    this.selectedRecord.positionName = record.positionName;
    this.selectedRecord._id = record._id;
    this.selectedRecord.firstLetter = record.firstLetter;
    this.selectedRecord.roles = record.roles;
    this.clearRoles()
    for (let i = 0; i < this.selectedRecord.roles.length; i++) {

      var existing = this.roles.find(o=> o.name.toLowerCase() == this.selectedRecord.roles[i].toLowerCase())
      if(existing){
        existing.checked = true;
      }
      
    }

    this.togglePopover(position, initiator, popOver)
  
  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'

        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'

        }else{

          popOver.placement = 'bottom-left'

        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }

  // edit position
  editPosition(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var roles = [];

    for (let i = 0; i < this.roles.length; i++) {
      
      if(this.roles[i] && this.roles[i].checked){

        roles.push(this.roles[i].name)

      }
      
    }

    if(roles && roles.length > 0){

      var route = 'Staff/UpdatePosition';
      var requestData = {
        positionId: this.selectedRecord._id,
        positionName: this.selectedRecord.positionName,
        roles: roles
      };

      this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
        (res: any)=>{

          this.stopLoading();

          if(res.status.toLowerCase() == httpSuccessText){

            requestResponse = 'Staff position updated successfully.';
            this.toastNotification(requestResponse);
            
            this.clearRoles();
            if(this.popOver){
              this.popOver.close()
            }

            this.getPositions();
            
          }else{
        
            requestResponse = res.message;
            this.toastNotification(requestResponse);
        
          }

        },(err)=>{

          this.stopLoading();

          requestResponse = err.error.message;
          this.toastNotification(requestResponse);

        }
      );

    }else{

      this.stopLoading();

      requestResponse = 'Roles cannot be empty.';
      this.toastNotification(requestResponse);

    }

  }

  // create position
  createPosition(addForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var roles = [];

    for (let i = 0; i < this.roles.length; i++) {
      
      if(this.roles[i] && this.roles[i].checked){

        roles.push(this.roles[i].name)

      }
      
    }

    if(roles && roles.length > 0){

      var route = 'Staff/CreateStaffPosition';
      var requestData = {
        positionName: this.positionDetails.name,
        roles: roles
      };

      this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
        (res: any)=>{

          this.stopLoading();

          if(res.status.toLowerCase() == httpSuccessText){

            requestResponse = 'Staff position created successfully.';
            this.toastNotification(requestResponse);

            addForm.resetForm()
            this.clearRoles();
            this.toggleInnerMenu('list')
            this.getPositions();
            
          }else{
        
            requestResponse = res.message;
            this.toastNotification(requestResponse);
        
          }

        },(err)=>{

          this.stopLoading();

          requestResponse = err.error.message;
          this.toastNotification(requestResponse);

        }
      );

    }else{

      this.stopLoading();

      requestResponse = 'Roles cannot be empty.';
      this.toastNotification(requestResponse);

    }

  }

}
