import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  
  // menu variables
  public activeInnerMenu: any = 'add';

  // add product variables
  public stepNumber: any = 1;
  public measurementMaxList: any = [1,2,3,4,5];
  public discountTypeList: any = ['none', 'bulk', 'single'];
  public addProductDetails: any = {
    name: '',
    category: '',
    description: '',
    expiryDate: '',
    shelfNumber: '',
    noOfMeasurement: 1,
    unitOfMeasurement: [
      {
        name:''
      }
    ]
  };
  public formHolder: NgForm;

  // add category and sub category details
  public categories: any = [];


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getCategories();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // ADD PRODUCT FUNCTIONS 

  // generate barcode
  getBarCode(){

    var result           = '';

    var characters       = '0123456789';

    var charactersLength = 12;

    for( var i = 0; i < charactersLength; i++ ) {

      result += characters.charAt(Math.floor(Math.random() * charactersLength));

    }

    return result;

  }

  // set step number 
  increaseDecreaseStep(step){

    this.stepNumber = Number(this.stepNumber) + step;

    if(this.stepNumber < 1){

      this.stepNumber = 1

    }

  }

  // generate unit of measurement textbox
  generateMeasurementBoxes(){

    this.addProductDetails.unitOfMeasurement = [];

    for (let i = 0; i < this.addProductDetails.noOfMeasurement; i++) {
      
      var option = {
        name: ''
      };

      this.addProductDetails.unitOfMeasurement.push(option);
      
    }

  }

  // proceed to step two, process entered info
  goToStepTwo(stepOneForm: NgForm){

    this.formHolder = stepOneForm;

    for (let i = 0; i < this.addProductDetails.unitOfMeasurement.length; i++) {
      
      if(this.addProductDetails.unitOfMeasurement[i]){

        this.addProductDetails.unitOfMeasurement[i].quantity = 0;
        this.addProductDetails.unitOfMeasurement[i].wholeSalesQuantity = 0;
        this.addProductDetails.unitOfMeasurement[i].reorderLimit = 0;
        this.addProductDetails.unitOfMeasurement[i].costPrice = 0;
        this.addProductDetails.unitOfMeasurement[i].sellingPrice = 0;
        this.addProductDetails.unitOfMeasurement[i].limitPrice = 0;
        this.addProductDetails.unitOfMeasurement[i].barCode = '';
        this.addProductDetails.unitOfMeasurement[i].discountType = 'none';
        this.addProductDetails.unitOfMeasurement[i].discountPercentage = '';
        this.addProductDetails.unitOfMeasurement[i].discountUnit = 0;

      }
      
    }

    this.increaseDecreaseStep(1);

  }

  // structure data before sending http request to add item
  goToFinalStep(stepTwoForm: NgForm){

    var requestData: any = {
      name: null,
      categoryId: null,
      description: null,
      expiryDate: null,
      shelfNumber: null,
      inventoryTypes: 'newstock',
      unitOfMeasurement: [],
      quantity: {},
      reOrderLimit: {},
      costPrice: {},
      sellingPrice: {},
      limitPrice: {},
      barCode: [],
      discountType: {},
      discount: {},
      discountUnit: {},
      WholesalesQuantity: {},
    };

    requestData.name = this.addProductDetails.name;
    requestData.categoryId = this.addProductDetails.category;
    requestData.description = this.addProductDetails.description;
    requestData.expiryDate = this.addProductDetails.expiryDate;
    requestData.shelfNumber = this.addProductDetails.shelfNumber;

    for (let i = 0; i < this.addProductDetails.unitOfMeasurement.length; i++) {
      
      if(this.addProductDetails.unitOfMeasurement[i]){

        var measurement = this.addProductDetails.unitOfMeasurement[i];

        // add name of units of measurement
        requestData.unitOfMeasurement.push(measurement.name);

        // add quantity for unit of measurement
        requestData.quantity[measurement.name] = measurement.quantity

        // add reOrder Limit for unit of measurement
        requestData.reOrderLimit[measurement.name] = measurement.reorderLimit

        // add cost Price for unit of measurement
        requestData.costPrice[measurement.name] = measurement.costPrice

        // add selling Price for unit of measurement
        requestData.sellingPrice[measurement.name] = measurement.sellingPrice

        // add limit Price for unit of measurement
        if(measurement.limitPrice > 0){

          requestData.limitPrice[measurement.name] = measurement.limitPrice

        }else{

          requestData.limitPrice[measurement.name] = measurement.sellingPrice

        }

        // add barCode for unit of measurement
        if(measurement.barCode && measurement.barCode.length > 10){

          requestData.barCode.push(measurement.barCode);

        }else{

          requestData.barCode.push(this.getBarCode());

        }

        // add discount Type for unit of measurement
        requestData.discountType[measurement.name] = measurement.discountType

        // add discount percentage and discount bulk unit for unit of measurement
        if(measurement.discountType == 'none'){

          requestData.discount[measurement.name] = 0;
          requestData.discountUnit[measurement.name] = 0

        }else if(measurement.discountType == 'single'){

          requestData.discount[measurement.name] = measurement.discountPercentage;
          requestData.discountUnit[measurement.name] = 0

        }else if(measurement.discountType == 'bulk'){

          requestData.discount[measurement.name] = measurement.discountPercentage;
          requestData.discountUnit[measurement.name] = measurement.discountUnit

        }

        // add WholesalesQuantity for unit of measurement
        requestData.WholesalesQuantity[measurement.name] = measurement.wholeSalesQuantity

      }
      
    }

    this.addProduct(requestData, stepTwoForm);

  }

  // add product fnc
  addProduct(requestData, addProductForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/AddInventory';

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product added successfully.';
          this.toastNotification(requestResponse);

          addProductForm.resetForm();
          this.formHolder.resetForm();

          this.addProductDetails = {
            name: '',
            category: '',
            description: '',
            expiryDate: '',
            noOfMeasurement: 1,
            unitOfMeasurement: [
              {
                name:''
              }
            ]
          };

          this.stepNumber = 1;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }


  // CATEGORY FUNCTIONS

  // get all categories
  getCategories(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/GetCategories';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          var categories = res.Categories;

          this.categories = [];
          for (let i = 0; i < categories.length; i++) {
            
            if(categories[i]){

              var category = {
                name: categories[i].name,
                subCategories:categories[i].subCategories,
                _id: categories[i]._id,
                firstLetter: categories[i].name.substring(0,1),
                products: []
              };

              this.categories.push(category);

            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }


}
