<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Miscellaneous event records on products
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Misc Products
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('expired')" [class.activeMenu]="activeInnerMenu == 'expired'">
                <span>
                    <fa-icon icon="stopwatch"></fa-icon> Reduce Product
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of Products affected by Miscellaneous events</b>
                    <b *ngIf="activeInnerMenu == 'expired'">Misc Event Decrease of a Product</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Record details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section">
                                    <div class="item-list-section">
                                        <div class="item-section" *ngFor="let y of selectedRecord.products">
                                            <div class="item-details-section">
                                                <div class="label-section">
                                                    <span>
                                                        Product
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span>
                                                        {{y.productName}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="item-section" *ngFor="let x of y.measurement">
                                                <div class="item-details-section">
                                                    <div class="label-section">
                                                        <span>
                                                            Measurement
                                                        </span>
                                                    </div>
                                                    <div class="value-section">
                                                        <span>
                                                            {{x.name}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="item-details-section">
                                                    <div class="label-section">
                                                        <span>
                                                            Quantity
                                                        </span>
                                                    </div>
                                                    <div class="value-section">
                                                        <span>
                                                            {{x.quantity | number}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="item-details-section">
                                                    <div class="label-section">
                                                        <span>
                                                            Selling Price
                                                        </span>
                                                    </div>
                                                    <div class="value-section">
                                                        <span>
                                                            &#8358; {{x.sellingPrice | number:'.2'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Reason for event
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.comments}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.staff.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Date of Action
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section"  *ngIf="records.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="records.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of records; let i = index" 
                                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                                    [class.activeRecord]="x._id == selectedRecord._id">
                                        <div class="list-item-content" >
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.comments}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            {{x.productDetails.length | number}} product(s) affected
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span>
                                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-section">
                                    <div class="pagination-text" (click)="getPage(1)">
                                        <span>
                                            First
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(-1)">
                                        <span>
                                            Prev
                                        </span>
                                    </div>
                                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                        <span>
                                            {{x}}
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(1)">
                                        <span>
                                            Next
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="getPage(noOfPages)">
                                        <span>
                                            Last
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'expired'">
                    <div class="restock-content-section">
                        <div class="left-restock-content-section">
                            <div class="search-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="searchData" autocomplete="off" 
                                    [(ngModel)]="searchData" placeholder="Search by product name" 
                                    (keyup)="searchEvent($event)" />
                                </mat-form-field>
                            </div>
                            <div class="search-result-section">
                                <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                                (click)="chooseProduct(x)">
                                    <div class="top-search-item-section">
                                        <span>
                                            {{x.name}}
                                        </span>
                                    </div>
                                    <div class="down-search-item-section">
                                        <span>
                                            {{x.category.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-restock-content-section">
                            <form name="miscForm" #miscForm="ngForm" class="form" *ngIf="miscDetails.products.length > 0"
                            (ngSubmit)="miscForm.form.valid && processMisc(miscForm)">
                                <div class="measurement-section" *ngFor="let y of miscDetails.products; let j = index">
                                    <div class="text-btn-section">
                                        <div class="left-text-btn-section">
                                            <span>
                                                {{y.name}}
                                            </span>
                                        </div>
                                        <div class="right-text-btn-section">
                                            <div class="icon-section" (click)="removeProduct(j)">
                                                <fa-icon icon="trash"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="big-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Unit of measurements
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="unit{{j}}" [(ngModel)]="y.activeMeasurement" required multiple
                                                #unit="ngModel" (selectionChange)="chooseProductMeasurement(y)">
                                                    <mat-option value="{{x.name}}" *ngFor="let x of y.measurement">
                                                        {{x.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="measurement-section" *ngFor="let m of y.selectedMeasurement; let i = index">
                                        <div class="small-form-control-section" >
                                            <div class="label-section">
                                                <span>
                                                    Unit
                                                </span>
                                            </div>
                                            <div class="control-section">
                                                <mat-form-field appearance="none">
                                                    <input matInput type="text" name="name{{i+' '+j}}" required readonly
                                                    [(ngModel)]="m.name">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="small-form-control-section" >
                                            <div class="label-section">
                                                <span>
                                                    Available Quantity
                                                </span>
                                            </div>
                                            <div class="control-section">
                                                <mat-form-field appearance="none">
                                                    <input matInput type="number" name="availableQty{{i+' '+j}}" required 
                                                    [(ngModel)]="m.availableQty" readonly>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="small-form-control-section" >
                                            <div class="label-section">
                                                <span>
                                                    affected Quantity
                                                </span>
                                            </div>
                                            <div class="control-section">
                                                <mat-form-field appearance="none">
                                                    <input matInput type="number" name="qty{{i+' '+j}}" required
                                                    [(ngModel)]="m.qty"  >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="big-form-control-section" >
                                    <div class="label-section">
                                        <span>
                                            Reason for event
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="reason" [(ngModel)]="miscDetails.comment" required
                                            #reason="ngModel">
                                            <mat-error *ngIf="miscForm.submitted && reason.invalid">
                                                Please select a valid reason
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Reduce
                                        
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>