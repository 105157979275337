import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})
export class ReturnComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'return';

  // initiate product return
  public searchData: any;
  public salesRecords: any = [];
  public showSelectedRecord: any =false;
  public selectedRecord: any = {};
  public typeOfReturns: any = ['Refund', 'Replace'];
  public returnDetails: any = {
    salesId: null,
    productId: null,
    returnType: null,
    amountReturn: null,
    comment: '',
    reduceTheAmountOwning: null,
    measurements: [],
    activeUnit: {}
  };


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {
  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // search by receipt id
  search(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetSalesByReceiptId';
    var requestData = {
      receiptId: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.salesRecords = res.sales;
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select record fnc
  chooseReceipt(record){

    this.selectedRecord.receiptId = record.receiptId
    this.selectedRecord.typeOfsales = record.typeOfsales
    this.selectedRecord._id = record._id
    this.selectedRecord.products = [];
    this.selectedRecord.returnProduct = []

    for (let j = 0; j < record.returnProduct.length; j++) {

      if(record.returnProduct[j].returnType == 'Refund'){

        var rtPrdKeys = Object.keys(record.returnProduct[j].returnQuantity);
        for (let k = 0; k < rtPrdKeys.length; k++) {

          this.selectedRecord.returnProduct.push({
            productId: record.returnProduct[j].productId,
            unit: rtPrdKeys[k],
            returnQuantity: record.returnProduct[j].returnQuantity[rtPrdKeys[k]],
            amountReturn: record.returnProduct[j].amountReturn
          })
          
        }

      }
      
    }

    for (let i = 0; i < record.productDetails.length; i++) {
      
      if(record.productDetails[i]){

        var soldProduct = record.productDetails[i];

        var product = {
          id: soldProduct._id,
          productName: soldProduct.productName,
          totalAmount: soldProduct.totalAmount,
          measurement: [],
        }
        
        // convert object to array
        var objectKeys = Object.keys(soldProduct.quantity);
        for (let j = 0; j < objectKeys.length; j++) {
          
          var unit = objectKeys[j];

          var measurement = {
            name: unit,
            sellingPrice: soldProduct.sellingPrice[unit],
            quantity: soldProduct.quantity[unit]
          }

          product.measurement.push(measurement);
          
        }

        this.selectedRecord.products.push(product);

      }
      
    }

    for (let i = 0; i < this.selectedRecord.returnProduct.length; i++) {

      var rtdPrdId = this.selectedRecord.returnProduct[i].productId;
      var productReturnedIndex = this.selectedRecord.products.findIndex(o=> o.id == rtdPrdId)
      if(productReturnedIndex > -1){

        var sldPrdMsm = this.selectedRecord.products[productReturnedIndex].measurement;
        var rtdPrdUnit = this.selectedRecord.returnProduct[i].unit

        var unitReturnedIndex = sldPrdMsm.findIndex(p=> p.name == rtdPrdUnit )
        if(unitReturnedIndex > -1){

          var rtdPrdQty = this.selectedRecord.returnProduct[i].returnQuantity
          sldPrdMsm[unitReturnedIndex].quantity = sldPrdMsm[unitReturnedIndex].quantity - rtdPrdQty

        }
      }
      
    }
    console.log(this.selectedRecord.returnProduct)

  }

  // choose product to be returned
  chooseProduct(event){

    this.returnDetails.measurements = [];
    var product = this.selectedRecord.products.find(o=> o.id == event.value);
    if(product && product.id){

      this.returnDetails.salesId = this.selectedRecord._id;
      this.returnDetails.productId = product.id;
      this.returnDetails.activeUnit = {};
      this.returnDetails.amountReturn = 0;
      if(this.selectedRecord.typeOfsales == 'creditSales'){

        this.returnDetails.reduceTheAmountOwning = true;

      }else{

        this.returnDetails.reduceTheAmountOwning = false;

      }

      for (let i = 0; i < product.measurement.length; i++) {

        var measurement = {
          name: product.measurement[i].name,
          soldQuantity: product.measurement[i].quantity,
          sellingPrice: product.measurement[i].sellingPrice,
          qty: 0,
          checked: false
        }
        
        this.returnDetails.measurements.push(measurement);
        
      }

    }

  }

  // choose product measurement to be return
  chooseProductMeasurement(event){

    this.returnDetails.activeUnit = {};
    this.returnDetails.amountReturn = 0;
    var unit = this.returnDetails.measurements.find(o=> o.name == event.value);
    if(unit && unit.name){

      this.returnDetails.activeUnit.name = unit.name
      this.returnDetails.activeUnit.soldQuantity = unit.soldQuantity
      this.returnDetails.activeUnit.sellingPrice = unit.sellingPrice
      this.returnDetails.activeUnit.qty = unit.qty

    }

  }

  // calculate refund amount
  calculateRefundAmount(){

    this.returnDetails.amountReturn = 0;

    var amount = this.returnDetails.activeUnit.qty * this.returnDetails.activeUnit.sellingPrice
    this.returnDetails.amountReturn = this.returnDetails.amountReturn + amount

  }

  // process return
  processReturn(receiptForm: NgForm){

    if(this.returnDetails.activeUnit.qty > 0 && this.returnDetails.activeUnit.qty <= this.returnDetails.activeUnit.soldQuantity){

      var requestData = {
        salesId: this.returnDetails.salesId,
        productId: this.returnDetails.productId,
        returnType: this.returnDetails.returnType,
        amountReturn: this.returnDetails.amountReturn,
        reduceTheAmountOwning: this.returnDetails.reduceTheAmountOwning,
        comment: this.returnDetails.comment,
        productUniqueId: '',
        quantity: {},
      }
      requestData.quantity[this.returnDetails.activeUnit.name] = this.returnDetails.activeUnit.qty
      
      this.returnProduct(requestData, receiptForm)

    }else{
      
      var requestResponse = 'The return quantity is bigger than the sold quantity or less than 1. ';
      this.toastNotification(requestResponse);
  
    }


  }

  // return product
  returnProduct(requestData, receiptForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/ReturnProduct';

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product has been returned successfully.';
          this.toastNotification(requestResponse);

          receiptForm.resetForm();
          this.returnDetails.measurements = [];
          this.selectedRecord = {}
          this.salesRecords = []
          
          this.search();

        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
