import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from './material/material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChartsModule } from 'ng2-charts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from 'ngx-countdown';
import { ThermalPrintModule } from 'ng-thermal-print';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {NgxPrintModule} from 'ngx-print';
import { JwtModule } from '@auth0/angular-jwt';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { IncomeComponent } from './account/income/income.component';
import { LoginComponent } from './account/login/login.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { DashboardComponent } from './account/dashboard/dashboard.component';
import { ProfileComponent } from './account/profile/profile.component';
import { InventoryRecordComponent } from './records/inventory-record/inventory-record.component';
import { ReturnRecordComponent } from './records/return-record/return-record.component';
import { SalesRecordComponent } from './records/sales-record/sales-record.component';
import { ExpirationRecordComponent } from './records/expiration-record/expiration-record.component';
import { DebtSaleRecordComponent } from './records/debt-sale-record/debt-sale-record.component';
import { MiscRecordComponent } from './records/misc-record/misc-record.component';
import { ListComponent } from './products/list/list.component';
import { CategoryComponent } from './products/category/category.component';
import { SellComponent } from './products/sell/sell.component';
import { DeactivedProductComponent } from './products/deactived-product/deactived-product.component';
import { ActivitiesComponent } from './account/activities/activities.component';
import { RetailersComponent } from './end-users/retailers/retailers.component';
import { CustomersComponent } from './end-users/customers/customers.component';
import { AddComponent } from './products/add/add.component';
import { ReturnComponent } from './products/return/return.component';
import { RanksComponent } from './staff/ranks/ranks.component';
import { AdvertsComponent } from './account/adverts/adverts.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { ListStaffComponent } from './staff/list-staff/list-staff.component';
import { PasswordChangeComponent } from './account/password-change/password-change.component';
import { CartComponent } from './products/cart/cart.component';
import { CheckoutComponent } from './products/checkout/checkout.component';
import { CreditSalesStaffComponent } from './staff/activities/credit-sales-staff/credit-sales-staff.component';
import { SalesStaffComponent } from './staff/activities/sales-staff/sales-staff.component';
import { ExpiryStaffComponent } from './staff/activities/expiry-staff/expiry-staff.component';
import { MiscStaffComponent } from './staff/activities/misc-staff/misc-staff.component';
import { ReturnStaffComponent } from './staff/activities/return-staff/return-staff.component';
import { InventoryStaffComponent } from './staff/activities/inventory-staff/inventory-staff.component';
import { UpdateComponent } from './products/update/update.component';
import { NearExpiredComponent } from './products/near-expired/near-expired.component';
import { TopSellingComponent } from './products/top-selling/top-selling.component';
import { ReorderLimitComponent } from './products/reorder-limit/reorder-limit.component';
import { OnlineProductsComponent } from './ecommerce/online-products/online-products.component';
import { SupersaleProductsComponent } from './ecommerce/supersale-products/supersale-products.component';
import { FeaturedProductsComponent } from './ecommerce/featured-products/featured-products.component';
import { OrderedProductsComponent } from './ecommerce/ordered-products/ordered-products.component';
import { ApprovedOrdersComponent } from './ecommerce/approved-orders/approved-orders.component';
import { TransitOrdersComponent } from './ecommerce/transit-orders/transit-orders.component';
import { ContactMessagesComponent } from './ecommerce/contact-messages/contact-messages.component';
import { StoreDetailsComponent } from './ecommerce/store-details/store-details.component';
import { RiderListComponent } from './rider/rider-list/rider-list.component';
import { PackedOrdersComponent } from './ecommerce/packed-orders/packed-orders.component';
import { CompletedOrdersComponent } from './ecommerce/completed-orders/completed-orders.component';
import { IssuesOrdersComponent } from './ecommerce/issues-orders/issues-orders.component';
import { CancelledOrdersComponent } from './ecommerce/cancelled-orders/cancelled-orders.component';
import { OnlineCategoryComponent } from './ecommerce/online-category/online-category.component';
import { PrescriptionProductComponent } from './products/prescription-product/prescription-product.component';
import { StaffInvoiceComponent } from './records/staff-invoice/staff-invoice.component';
import { PendingInvoiceComponent } from './records/pending-invoice/pending-invoice.component';
import { PayInvoiceComponent } from './products/pay-invoice/pay-invoice.component';
import { DailyReportComponent } from './staff/daily-report/daily-report.component';
import { WholesaleTokensComponent } from './products/wholesale-tokens/wholesale-tokens.component';
import { EcommerceNotificationComponent } from './staff/ecommerce-notification/ecommerce-notification.component';
import { ReversedSalesRecordComponent } from './records/reversed-sales-record/reversed-sales-record.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    DashboardComponent,
    InventoryRecordComponent,
    ReturnRecordComponent,
    SalesRecordComponent,
    ExpirationRecordComponent,
    DebtSaleRecordComponent,
    MiscRecordComponent,
    ListComponent,
    CategoryComponent,
    SellComponent,
    IncomeComponent,
    ActivitiesComponent,
    RetailersComponent,
    CustomersComponent,
    ProfileComponent,
    AddComponent,
    ReturnComponent,
    RanksComponent,
    AdvertsComponent,
    AddStaffComponent,
    ListStaffComponent,
    PasswordChangeComponent,
    CartComponent,
    CheckoutComponent,
    CreditSalesStaffComponent,
    SalesStaffComponent,
    ExpiryStaffComponent,
    MiscStaffComponent,
    ReturnStaffComponent,
    InventoryStaffComponent,
    UpdateComponent,
    DeactivedProductComponent,
    NearExpiredComponent,
    TopSellingComponent,
    ReorderLimitComponent,
    OnlineProductsComponent,
    SupersaleProductsComponent,
    FeaturedProductsComponent,
    OrderedProductsComponent,
    ApprovedOrdersComponent,
    TransitOrdersComponent,
    ContactMessagesComponent,
    StoreDetailsComponent,
    RiderListComponent,
    PackedOrdersComponent,
    CompletedOrdersComponent,
    IssuesOrdersComponent,
    CancelledOrdersComponent,
    OnlineCategoryComponent,
    PrescriptionProductComponent,
    StaffInvoiceComponent,
    PendingInvoiceComponent,
    PayInvoiceComponent,
    DailyReportComponent,
    WholesaleTokensComponent,
    EcommerceNotificationComponent,
    ReversedSalesRecordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    MaterialModule,
    Ng2SearchPipeModule,
    ChartsModule,
    NgxIntlTelInputModule,
    NgbModule,
    CountdownModule,
    ThermalPrintModule,
    NgxPrintModule,
    GooglePlaceModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["vicpharm.milesbreed.com", "pharmacy.vicpharm.store", 'localhost:4200', "abuja.vicpharm.store", "abeokuta.vicpharm.store"],
        disallowedRoutes: [],
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(library: FaIconLibrary) { 

		library.addIconPacks(fas, fab, far);

	}

}

export function tokenGetter() {
  return localStorage.getItem('pharm_token');
}
