import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  // customers variables
  public customer: any = [];
  public selectedCustomer: any = {}
  public popOver: NgbPopover;

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 30;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getCustomers();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Customer/SearchCustomer';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Customer.length > 0){
            this.searchResult = res.Customer;
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // get customers
  getCustomers(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Customer/GetCustomers';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.customer = res.customers;
          for (let i = 0; i < this.customer.length; i++) {
            
            this.customer[i].firstLetter = this.customer[i].fullName ? this.customer[i].fullName.substring(0,1) : null;
            
          }

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    for (let i = 0; i < this.noOfPages; i++) {
      
      this.pageArray.push(i+1);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getCustomers();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getCustomers();

    }

  }

  // select record fnc
  selectCustomer(record, initiator: any, position: any, popOver?: any){

    this.selectedCustomer = {
      name: record.fullName,
      fullName: record.fullName,
      id: record._id,
      email: record.personalInfo.email,
      address: record.personalInfo.address,
      phoneNumber: record.phoneNumber,
      gender: record.personalInfo.gender,
      age: record.personalInfo.age,
    }

    this.togglePopover(position, initiator, popOver)

  }

  // open and close popover
  togglePopover(position, initiator, popOver?: NgbPopover){

    if(popOver){

      if(position == 'list'){
          
        var index = initiator + 1;
        popOver.autoClose = 'outside';

        if(index == 1){

          popOver.placement = 'bottom-left'
  
        }else if(index % 2 == 0){
          
          popOver.placement = 'bottom-right'
  
        }else{
  
          popOver.placement = 'bottom-left'
  
        }

        popOver.isOpen() ? popOver.close() : popOver.open();

      }else if(position == 'search'){

        popOver.autoClose = 'outside';
        popOver.placement = 'left-top'

        popOver.isOpen() ? popOver.close() : popOver.open();

      }

      this.popOver = popOver;

    }
  }
  
  // edit customer
  editCustomer(editForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Customer/UpdateCustomer';
    var requestData = {
      customerId: this.selectedCustomer.id,
      fullName: this.selectedCustomer.fullName,
      email: this.selectedCustomer.email,
      address: this.selectedCustomer.address,
      gender: this.selectedCustomer.gender,
      age: this.selectedCustomer.age,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Customer details updated successfully.';
          this.toastNotification(requestResponse);

          this.popOver.close();

          this.getCustomers();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  

}
