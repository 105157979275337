<div class="page-section">
    <div class="left-page-section">
        <div class="left-page-content">
            <div class="logo-section">
                <div class="logo-holder">
                    <img src="./assets/img/logo.png" alt="image">
                </div>
            </div>
            <div class="icon-section">
                <div class="icon-holder">
                    <img src="./assets/img/login.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="right-page-section">
        <div class="right-page-content" *ngIf="page == 'password'">
            <div class="back-button-section">
                <span>
                    <fa-icon icon="long-arrow-alt-left" (click)="goToPage('login')"></fa-icon>
                </span>
            </div>
            <div class="form-section">
                <div class="form-content">
                    <div class="main-text-section">
                        <span>
                            Forgot Password
                        </span>
                    </div>
                    <div class="sub-text-section">
                        <span>
                            Please enter your registered phone number
                        </span>
                    </div>
                    <form class="form-holder" name="passwordForm" #passwordForm="ngForm" 
                    (ngSubmit)="passwordForm.form.valid && resetPasswordByPhone(passwordForm)">
                        <div class="each-form-control">
                            <div class="label-section">
                                <span>
                                    Phone Number *
                                </span>
                            </div>
                            <div class="input-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="tel" name="password" name="phoneNumber" required
                                    [(ngModel)]="userdata.phoneNumber" #phoneNumber="ngModel" 
                                    pattern="[0-9]{11}"/>
                                    <mat-error *ngIf="passwordForm.submitted && phoneNumber.invalid">
                                        Please enter a valid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Reset Password
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="right-page-content" *ngIf="page == 'code'">
            <div class="back-button-section">
                <span>
                    <fa-icon icon="long-arrow-alt-left" (click)="page = 'password'"></fa-icon>
                </span>
            </div>
            <div class="form-section">
                <div class="form-content">
                    <div class="main-text-section">
                        <span>
                            Reset Password
                        </span>
                    </div>
                    <div class="sub-text-section">
                        <span>
                            Please enter the token sent to <b>{{resetPhoneNumber}}</b> and new password
                        </span>
                    </div>
                    <form class="form-holder" name="resetForm" #resetForm="ngForm" 
                    (ngSubmit)="resetForm.form.valid && resetPassword(resetForm)">
                        <div class="each-form-control">
                            <div class="label-section">
                                <span>
                                    Enter Token *
                                </span>
                            </div>
                            <div class="input-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="code" [(ngModel)]="userdata.code" autocomplete="doNotAutoComplete"
                                    required #code="ngModel"/>
                                    <mat-error *ngIf="resetForm.submitted && code.invalid">
                                        Please enter a valid token
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="each-form-control">
                            <div class="label-section">
                                <span>
                                    New Password *
                                </span>
                            </div>
                            <div class="input-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="password" name="password" [(ngModel)]="userdata.password" autocomplete="doNotAutoComplete"
                                    required #password="ngModel" (keyup)="checkPassword($event)" />
                                    <mat-error *ngIf="resetForm.submitted && password.invalid">
                                        Please enter a valid password
                                    </mat-error>
                                    <mat-hint *ngIf="passwordStrength == 'none'">
                                        Password must be greater or equal to 5 characters
                                    </mat-hint>
                                    <mat-hint *ngIf="passwordStrength == 'strong'">
                                        Great ! That will do
                                    </mat-hint>
                                    <mat-hint *ngIf="passwordStrength == 'weak'">
                                        Must contain numbers and alphabets (eg: abc12)
                                    </mat-hint>
                                    <fa-icon *ngIf="passwordStrength == 'strong'" style="color: #27ae60" 
                                    icon="check-circle" matSuffix></fa-icon>
                                    <fa-icon *ngIf="passwordStrength == 'weak'" style="color: #f1c40f" 
                                    icon="window-close" matSuffix></fa-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Verify
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>