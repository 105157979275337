import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-debt-sale-record',
  templateUrl: './debt-sale-record.component.html',
  styleUrls: ['./debt-sale-record.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class DebtSaleRecordComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // sales records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;
  public filterShow: any = false;

  //filter variables
  public searchData: any;

  // date picker variable
  public selectedFromDate: any = 'Filter from';
  public selectedToDate: any = 'Filter to';
  public dateFilterShow = false;

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>

  // Payment variables
  public history: any = [];
  public initiatePayStatus =  false;
  public initialPayDetails: any = {
    amount: 0,
    remainingAmount: 0
  }

  constructor(
    private functionService: RequestService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((page)=>{

      if(page){

        if(page.id == 'all'){

          this.getRecords();

        }
      }

    });
  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetCreditSales';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.creditSale;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.customer = record.CustomerId ? record.CustomerId : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.expectedDate = record.expectedDate ? record.expectedDate : null
    this.selectedRecord.remainingAmount = record.remainingAmount ? record.remainingAmount : null
    this.selectedRecord.paid = record.paid ? record.paid : null
    this.selectedRecord.active = record.active ? record.active : null
    this.selectedRecord.staff = {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    }
    this.selectedRecord.totalAmount = record.totalAmount ? Number(record.totalAmount.toFixed(2)) : null
    this.selectedRecord._id = record._id ? record._id : null
    this.selectedRecord.products = [];

    for (let i = 0; i < record.productDetails.length; i++) {
    
      if(record.productDetails[i]){

        var soldProduct = record.productDetails[i];

        var product = {
          measurement: [],
        }
        
        // convert object to array
        var objectKeys = Object.keys(soldProduct.quantity);
        for (let j = 0; j < objectKeys.length; j++) {
          
          var unit = objectKeys[j];

          var measurement = {
            id: soldProduct.id,
            productName: soldProduct.productName,
            productUniqueId: soldProduct.productUniqueId,
            totalAmount: soldProduct.totalAmount,
            unitOfMeasurement: unit,
            sellingPrice: soldProduct.sellingPrice[unit],
            quantity: soldProduct.quantity[unit]
          }

          this.selectedRecord.products.push(measurement);
          
        }

      }
      
    }

    if(this.selectedRecord.paid.length > 0){

      this.history = this.selectedRecord.paid;

    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // date event
  dateChangeFnc(event, selector){

    this.checkFilter();

  }

  // check filter date range
  checkFilter(){

    if(this.selectedFromDate == null || this.selectedToDate == null){

      var requestResponse = 'Select from date and to date';
      this.toastNotification(requestResponse);

    }else if(this.selectedFromDate !== null && this.selectedToDate !== null){

      this.getDateRangeRecords();

    }else{
      
      this.selectedFromDate = null;
      this.selectedToDate = null;

      this.dateFilterShow = false;

    }

  }
  
  // get record between date range
  getDateRangeRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    this.pageIndex = 1;

    var route = 'Sales/GetCreditSalesByDateRange';
    var requestData = {
      page: this.pageIndex,
      startDate: this.selectedFromDate,
      endDate: this.selectedToDate,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.sales;

          this.dateFilterShow = true;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }
  
  // clear filter 
  clearFilter(){

    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.dateFilterShow = false;

    this.toggleCartView()

    this.pageIndex = 1;
    this.getRecords()

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover){

    if(popOver){

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.initialPayDetails.remainingAmount = this.selectedRecord.remainingAmount

      this.popOver = popOver;

    }
  }

  // debt payment request
  payDebt(payForm: NgForm){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    if(this.initialPayDetails.amount <= this.initialPayDetails.remainingAmount){

      this.startLoading();

      var route = 'Sales/PayCreditSales';
      var requestData = {
        DebitId: this.selectedRecord._id,
        paid: this.initialPayDetails.amount
      }

      this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
        (res: any)=>{

          this.stopLoading();

          if(res.status.toLowerCase() == httpSuccessText){

            requestResponse = 'Credit payment accepted';
            this.toastNotification(requestResponse);

            payForm.resetForm()

            this.toggleCartView();
            if(this.popOver){
              this.popOver.close()
            }

            this.getRecords();
            
          }else{
        
            requestResponse = res.message;
            this.toastNotification(requestResponse);
        
          }

        },(err)=>{

          this.stopLoading();

          requestResponse = err.error.message;
          this.toastNotification(requestResponse);

        }

      );

    }else{
      requestResponse = 'You cannot pay more the remaining amount.';
      this.toastNotification(requestResponse);
    }

  }


}
