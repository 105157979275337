import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../functions/auth/auth.service';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public userdata: any = {
    phoneNumber: '',
    password: '',
  };

  constructor(
    private functionService: RequestService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    var token = localStorage.getItem('pharm_token')
    if (token) {
      this.authService.checkAuthenticated();
      this.goToPage('account/dashboard');
    }
  }

  // start and stop loading funx
  startLoading() {

    this.functionService.setLoading(true);

  }

  stopLoading() {

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // goto selected page
  goToPage(page) {

    this.functionService.setMenu(page);

  }

  // login user
  loginUser(loginForm: NgForm) {


    var numberPrefix = '+234';
    var extractedNumber = this.userdata.phoneNumber.substring(1, this.userdata.phoneNumber.length);
    var loginPhoneNumber = numberPrefix + extractedNumber;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Staff/Login';
    var requestData = {
      phoneNumber: loginPhoneNumber,
      password: this.userdata.password
    }

    this.functionService.postHttpRequestService(route, requestData).subscribe(
      (res: any) => {

        this.stopLoading();

        if (res.status.toLowerCase() == httpSuccessText) {

          localStorage.setItem('pharm_token', res.token);
          localStorage.setItem('pharm_user', JSON.stringify(res.profile));
          localStorage.setItem('pharm_adverts', JSON.stringify(res.advert));

          this.authService.checkAuthenticated();
          this.goToPage('account/dashboard');


          loginForm.resetForm();

        } else {

          requestResponse = res.message;
          this.toastNotification(requestResponse);

        }

      }, (err) => {

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
