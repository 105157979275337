import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-rider-list',
  templateUrl: './rider-list.component.html',
  styleUrls: ['./rider-list.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class RiderListComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // staff records variables
  public staffList: any = [];
  public showSelectedStaff = false;
  public selectedStaff: any = {
    id: '',
    name: '',
    role: '',
    phoneNumber: ''
  }

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  // staff position variables
  public positions: any = [];

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // add rider
  public addRiderDetails: any = {
    phoneNumber: null,
    firstName: null,
    lastName: null,
  }

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRiders();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get staffs
  getRiders(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Rider/GetAllRider';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.staffList = res.staffs;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getRiders();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRiders();

    }

  }

  // select staff fnc
  selectStaff(staff){

    this.selectedStaff = {
      id: staff._id,
      name: staff.personalInfo.firstName +' '+staff.personalInfo.lastName,
      phoneNumber: staff.phoneNumber,
      active: staff.active
    }
    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Rider/SearchRider';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.staffs.length > 0){
            this.searchResult = res.staffs;
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // add rider
  addRider(addForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var numberPrefix = '+234';
    var extractedNumber = this.addRiderDetails.phoneNumber.substring(1, this.addRiderDetails.phoneNumber.length);
    var loginPhoneNumber = numberPrefix+extractedNumber;

    var route = 'Rider/AddStaff';
    var requestData = {
      firstName: this.addRiderDetails.firstName,
      lastName: this.addRiderDetails.lastName,
      phoneNumber: loginPhoneNumber,
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Rider added successfully.';
          this.toastNotification(requestResponse);

          addForm.resetForm()

          this.toggleInnerMenu('list')
          this.getRiders()
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
