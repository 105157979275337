<div class="page-section">
    <div class="page-holder">
        <div class="page-title-section">
            <div class="text-section">
                <span>
                    Retailer List
                </span>
            </div>
            <div class="btn-section">
                <button mat-flat-button (click)="toggleAddRetailer(true);">
                    Add Retailer
                    <fa-icon icon="chevron-right"></fa-icon>
                </button>
            </div>
        </div>
        <div class="list-section">
            <div class="retailers-list-section">
                <div class="no-retailers-section" *ngIf="retailer.length <= 0">
                    <div class="img-section">
                        <fa-icon icon="database"></fa-icon>
                    </div>
                    <div class="text-section">
                        <span>
                            No Retailer Found
                        </span>
                    </div>
                </div>
                <div class="retailers-section" *ngIf="retailer.length > 0">
                    <div class="list-holder">
                        <div class="single-retailer-section" *ngFor="let x of retailer; let i = index">
                            <div class="single-retailer-content" (click)="selectRetailer(x)" >
                                <div class="left-section" >
                                    <div class="retailer-name">
                                        <span>
                                            {{x.fname}} {{x.lname}}
                                        </span>
                                    </div>
                                    <div class="number-name">
                                        <span>
                                            {{x.email}}
                                        </span>
                                    </div>
                                    <div class="role-name">
                                        <span>
                                            {{x.phoneNumber}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination-holder">
                        <div class="pagination-text" (click)="paginate(-1)">
                            <span>
                                Prev
                            </span>
                        </div>
                        <div class="pagination-text" [class.activePage]="pageIndex == x"
                        *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                            <span>
                                {{i + 1}}
                            </span>
                        </div>
                        <div class="pagination-text" (click)="paginate(1)">
                            <span>
                                Next
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="retailers-fnc-section">
                <div class="retailers-edit-section" *ngIf="showSelectedRetailer">
                    <div class="retailers-edit-content">
                        <div class="top-content">
                            <span>
                                Edit retailer
                            </span>
                        </div>
                        <div class="down-content">
                            <div class="form-section" >
                                <form name="editForm" #editForm="ngForm" (ngSubmit)="editForm.form.valid && editRetailer(editForm)">
                                    <mat-form-field appearance="none" >
                                        <mat-label>Retailer First Name</mat-label>
                                        <input matInput style="text-transform:capitalize;" type="text" name="fname" 
                                        [(ngModel)]="selectedRetailer.fname" #fname="ngModel" required/>
                                        <mat-error *ngIf="editForm.submitted && fname.invalid">
                                            Please enter a valid retailer first name
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none" >
                                        <mat-label>Retailer Last Name</mat-label>
                                        <input matInput style="text-transform:capitalize;" type="text" name="lname" 
                                        [(ngModel)]="selectedRetailer.lname" #lname="ngModel" required/>
                                        <mat-error *ngIf="editForm.submitted && lname.invalid">
                                            Please enter a valid retailer last name
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none" >
                                        <mat-label>Retailer Address</mat-label>
                                        <input matInput style="text-transform:capitalize;" type="text" name="address" 
                                        [(ngModel)]="selectedRetailer.address" #address="ngModel" />
                                        <mat-error *ngIf="editForm.submitted && address.invalid">
                                            Please enter a valid retailer address
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none" >
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput type="text" name="phoneNumber" #phoneNumber="ngModel"
                                        [(ngModel)]="selectedRetailer.phoneNumber" required/>
                                        <mat-error *ngIf="editForm.submitted && phoneNumber.invalid">
                                            Please enter a valid phone number
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none" >
                                        <mat-label>Email Address</mat-label>
                                        <input matInput type="email" name="email" #email="ngModel"
                                        [(ngModel)]="selectedRetailer.email" />
                                        <mat-error *ngIf="editForm.submitted && email.invalid">
                                            Please enter a valid email address
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none">
                                        <mat-label>Gender</mat-label>
                                        <mat-select name="gender" [(ngModel)]="selectedRetailer.gender" 
                                        #gender="ngModel" >
                                            <mat-option style="text-transform: capitalize;" value="male" >
                                                Male
                                            </mat-option>
                                            <mat-option style="text-transform: capitalize;" value="female" >
                                                Female
                                            </mat-option>
                                          </mat-select>
                                        <mat-error *ngIf="editForm.submitted && gender.invalid">
                                            Please select a valid gender
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="none" >
                                        <mat-label>Age</mat-label>
                                        <input matInput type="number" name="age" min="1" #age="ngModel"
                                        [(ngModel)]="selectedRetailer.age" />
                                        <mat-error *ngIf="editForm.submitted && age.invalid">
                                            Please enter a valid age
                                        </mat-error>
                                    </mat-form-field>
                                    <div class="btn-section" >
                                        <button mat-flat-button type="submit">
                                            Update
                                            <fa-icon icon="chevron-right"></fa-icon>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-section" (click)="toggleAddRetailer(false)" *ngIf="createRetailerStatus"></div>
<div class="modal-content" *ngIf="createRetailerStatus">
    <div class="top-content">
        <div class="text-section">
            <span>
                Add Retailer
            </span>
        </div>
        <div class="close-section">
            <fa-icon (click)="toggleAddRetailer(false)" icon="times-circle"></fa-icon>
        </div>
    </div>
    <div class="down-content">
        <form class="form-section"  name="addForm" #addForm="ngForm" (ngSubmit)="addForm.form.valid && addRetailer(addForm)">
            <div class="control-holder">
                <mat-form-field appearance="none" >
                    <mat-label>First Name</mat-label>
                    <input matInput style="text-transform:capitalize;" type="text" name="fname" autocomplete="doNotAutoComplete"
                    [(ngModel)]="addRetailerDetails.fname" #fname="ngModel" required/>
                    <mat-error *ngIf="addForm.submitted && fname.invalid">
                        Please enter a valid first name
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="none" >
                    <mat-label>Last Name</mat-label>
                    <input matInput style="text-transform:capitalize;" type="text" name="lname" autocomplete="doNotAutoComplete"
                    [(ngModel)]="addRetailerDetails.lname" #lname="ngModel" required/>
                    <mat-error *ngIf="addForm.submitted && lname.invalid">
                        Please enter a valid last name
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="none">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput type="tel" name="mobileNumber" required
                    [(ngModel)]="addRetailerDetails.phoneNumber" #mobileNumber="ngModel"
                    pattern="[0-9]{11}"/>
                    <mat-error *ngIf="addForm.submitted && mobileNumber.invalid">
                        Please enter a valid phone number
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="none" >
                    <mat-label>Age</mat-label>
                    <input matInput type="number" min='1' name="age" autocomplete="doNotAutoComplete"
                    [(ngModel)]="addRetailerDetails.age" #age="ngModel"/>
                    <mat-error *ngIf="addForm.submitted && age.invalid">
                        Please enter a valid age
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="none">
                    <mat-label>Email Address</mat-label>
                    <input matInput type="email" name="email" autocomplete="doNotAutoComplete"
                    [(ngModel)]="addRetailerDetails.email" #email="ngModel"/>
                    <mat-error *ngIf="addForm.submitted && email.invalid">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="none">
                    <mat-label>Address</mat-label>
                    <input matInput type="text" name="address" autocomplete="doNotAutoComplete"
                    [(ngModel)]="addRetailerDetails.address" #address="ngModel"/>
                    <mat-error *ngIf="addForm.submitted && address.invalid">
                        Please enter a valid address
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="btn-section">
                <button mat-flat-button>
                    Add
                    <fa-icon icon="chevron-right"></fa-icon>
                </button>
            </div>
        </form>
    </div>
</div>