import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-misc-record',
  templateUrl: './misc-record.component.html',
  styleUrls: ['./misc-record.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class MiscRecordComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}

  // misc product variables
  public miscDetails: any = {
    comment: null,
    products: []
  };

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Miscellaneous/GetMiscellaneous';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.Miscellaneous;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.comments = record.comments ? record.comments : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    } : {
      name: null,
      phoneNumber: null,
      id: null
    }
    this.selectedRecord.products = []

    for (let i = 0; i < record.productDetails.length; i++) {
      
      var singleProduct = record.productDetails[i];

      var processedProduct = {
        id: singleProduct.id,
        productName: singleProduct.productName,
        measurement: [],
      }

      // convert object to array
      var objectKeys = Object.keys(singleProduct.quantity);
      for (let j = 0; j < objectKeys.length; j++) {
        
        var unit = objectKeys[j];

        var measurement = {
          name: unit,
          quantity: singleProduct.quantity[unit],
          sellingPrice: singleProduct.sellingPrice[unit]
        }

        processedProduct.measurement.push(measurement);
        
      }

      this.selectedRecord.products.push(processedProduct);
      
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Product.length > 0){

            this.searchResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  category: products[i].category[0],
                  name: products[i].name,
                  quantity: products[i].quantity,
                  sellingPrice: products[i].sellingPrice,
                  unitOfMeasurement: products[i].unitOfMeasurement,
                  _id: products[i]._id,
                }
  
                this.searchResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select products to misc
  chooseProduct(product){

    var existing = this.miscDetails.products.find(o=> o.id == product._id);
    if(!existing){
      var selectedProduct = {
        id: product._id,
        name: product.name,
        measurement: [],
        activeMeasurement: [],
        selectedMeasurement: []
      }
  
      for (let i = 0; i < product.unitOfMeasurement.length; i++) {
  
        selectedProduct.measurement.push({
          name: product.unitOfMeasurement[i],
          qty: 0,
          availableQty: product.quantity[product.unitOfMeasurement[i]],
          sellingPrice: product.sellingPrice[product.unitOfMeasurement[i]],
          checked: false
        })
        
      }
  
      this.miscDetails.products.push(selectedProduct)
    }

  }

  // remove product from misc
  removeProduct(index){

    this.miscDetails.products.splice(index, 1);

  }

  // choose product measurement to be misc
  chooseProductMeasurement(product){

    product.selectedMeasurement = [];
    for (let i = 0; i < product.activeMeasurement.length; i++) {
      
      var unit = product.measurement.find(o=> o.name == product.activeMeasurement[i]);
      if(unit && unit.name){
        product.selectedMeasurement.push(unit)
      }
      
    }

  }

  // process misc
  processMisc(miscForm: NgForm){
    
    if(this.miscDetails.products.length > 0){

      // get all the selected products and its measurements
      var selectedProductsMeasurements = []
      for (let i = 0; i < this.miscDetails.products.length; i++) {
        
        if(this.miscDetails.products[i].activeMeasurement.length > 0){

          var product = {
            id: this.miscDetails.products[i].id,
            name: this.miscDetails.products[i].name,
            measurement: [],
          }

          var productMeasurement = this.miscDetails.products[i].selectedMeasurement
          for (let j = 0; j < productMeasurement.length; j++) {
            
            if(productMeasurement[j].qty > 0 && productMeasurement[j].qty <= productMeasurement[j].availableQty){

              product.measurement.push(productMeasurement[j])

            }
            
          }

          if(product.measurement.length > 0){

            selectedProductsMeasurements.push(product)

          }

        }
        
      }

      if(selectedProductsMeasurements.length > 0){

        // structure request data
        var requestData = {
          comments: this.miscDetails.comment,
          productDetails: []
        }

        for (let i = 0; i < selectedProductsMeasurements.length; i++) {

          var selectedProduct = selectedProductsMeasurements[i]

          var singleProduct = {
            id: selectedProduct.id,
            productName: selectedProduct.name,
            quantity: {},
            sellingPrice: {},
          }

          var selectedMeasurement = selectedProduct.measurement;

          for (let j = 0; j < selectedMeasurement.length; j++) {

            var singleMeasurement = selectedMeasurement[j];
            
            singleProduct.quantity[singleMeasurement.name] = singleMeasurement.qty
            singleProduct.sellingPrice[singleMeasurement.name] = singleMeasurement.sellingPrice
            
          }
          
          requestData.productDetails.push(singleProduct)
          
        }

        this.miscProduct(requestData, miscForm)

      }else{

        var requestResponse = 'Please choose at least one of the measurements of the product you '+
        'would like to reduce by miscellaneous event.';
        this.toastNotification(requestResponse);

      }

    }else{
    
      var requestResponse = 'Please select the product you would like to reduce by miscellaneous event.';
      this.toastNotification(requestResponse);

    }

  }

  // misc products
  miscProduct(requestData, miscForm?){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Miscellaneous/AddMiscellaneous';

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product has been reduced successfully.';
          this.toastNotification(requestResponse);

          miscForm.resetForm();
          this.miscDetails.products = [];

          this.closeSearch()
          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
