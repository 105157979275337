<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Dashboard
            </span>
        </div>
        <div class="btn-section">
            
        </div>
    </div>
    <div class="page-holder">
        <div class="summary-section">
            <div class="total-stock-section">
                <div class="label-section">
                    <span>
                        No of Products
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        {{summary.noOfProduct | number}}
                    </span>
                </div>
            </div>
            <div class="low-stock-section">
                <div class="label-section">
                    <span>
                        No of Customers
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        {{summary.noOfCustomer | number}}
                    </span>
                </div>
            </div>
            <div class="low-stock-section">
                <div class="label-section">
                    <span>
                        Near Expired Products
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        {{summary.nearExpiredProducts | number}}
                    </span>
                </div>
            </div>
            <div class="customer-section">
                <div class="label-section">
                    <span>
                        No of Staff
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        {{summary.noOfStaff | number}}
                    </span>
                </div>
            </div>
            <div class="view-balance-sheet" (click)="goToPage('account/income')">
                <span>
                    View Balance Sheet
                </span>
            </div>
        </div>
        <div class="list-section">
            <div class="left-section">
                <div class="top-left-section">
                    <div class="text-section">
                        <span>
                            Total Stock of Products
                        </span>
                    </div>
                </div>
                <div class="down-left-section">
                    <div class="product-table-section" *ngFor="let x of stockOfProducts">
                        <div class="top-product-table-section">
                            <span>
                                {{x.name}}
                            </span>
                        </div>
                        <div class="down-product-table-section">
                            <div class="top-measurement-section">
                                <div class="label-section">
                                    <span>
                                        Measurement
                                    </span>
                                </div>
                                <div class="label-section">
                                    <span>
                                        Quantity
                                    </span>
                                </div>
                                <div class="label-section">
                                    <span>
                                        Selling Price
                                    </span>
                                </div>
                            </div>
                            <div class="each-measurement-section" *ngFor="let y of x.measurements">
                                <div class="label-section">
                                    <span>
                                        {{y.name}}
                                    </span>
                                </div>
                                <div class="label-section">
                                    <span>
                                        {{y.quantity | number}}
                                    </span>
                                </div>
                                <div class="label-section">
                                    <span>
                                        &#8358; {{ y.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-section">
                <div class="top-right-section">
                    <div class="text-section">
                        <span>
                            Top Selling Products
                        </span>
                    </div>
                    <div class="btn-section">
                        <span (click)="goToPage('products/topselling')">
                            View Details
                        </span>
                    </div>

                </div>
                <div class="down-right-section">
                    <div class="no-data-section" *ngIf="topSellingItems.length < 1">
                        <div class="img-section">
                            <fa-icon icon="database"></fa-icon>
                        </div>
                        <div class="text-section">
                            <span>
                                No product has been sold
                            </span>
                        </div>
                    </div>
                    <div class="data-section" *ngIf="topSellingItems.length > 0">
                        <div class="each-sale-section" *ngFor="let x of topSellingItems">
                            <div class="left-sale-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="right-sale-section">
                                <span class="text-section">
                                    &#8358; {{x.totalSales | number:'.2'}} ({{x.width}})
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

