import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-pending-invoice',
  templateUrl: './pending-invoice.component.html',
  styleUrls: ['./pending-invoice.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class PendingInvoiceComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // sales records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;
  
  //filter variables
  public searchData: any;


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetInvoiceThatNeedApproval';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.Invoice;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.customer = record.contactPerson ? record.contactPerson : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.bankTransferId = record.invoiceId ? record.invoiceId : null
    this.selectedRecord.deliveryLocation = record.deliveryLocation ? record.deliveryLocation : null
    this.selectedRecord._id = record._id ? record._id : null
    this.selectedRecord.totalAmount = record.totalAmount ? Number(record.totalAmount.toFixed(2)) : null
    this.selectedRecord.prescriptionProduct = record.prescriptionProduct ? record.prescriptionProduct : null
    this.selectedRecord.prescriptionApproved = record.prescriptionProductApprove ? record.prescriptionProductApprove : null
    this.selectedRecord.prescriptionUri = record.prescriptionUri ? record.prescriptionUri : null
    this.selectedRecord.internal = record.Status && record.Status.toLowerCase() == 'Generate In Store'.toLowerCase() ? true : false
    this.selectedRecord.products = [];

    for (let i = 0; i < record.productDetails.length; i++) {
      
      if(record.productDetails[i]){

        var soldProduct = record.productDetails[i];

        var product = {
          id: soldProduct._id,
          name: soldProduct.name,
          quantity: soldProduct.quantity,
          measurement: soldProduct.unitOfMeasurement,
          sellingPrice: soldProduct.sellingPrice,
          totalAmount: soldProduct.totalAmount,
          subTotalAmount: soldProduct.subTotalAmount,
        }

        this.selectedRecord.products.push(product);

      }
      
    }
    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // approveSale
  approveSale(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/ApproveInvoicePrescription';

    var requestData = {
      invoiceId: this.selectedRecord._id,
      approveWithoutPrescription: this.selectedRecord.prescriptionUri ? false : true,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Invoice has been approved successfully.';
          this.toastNotification(requestResponse);

          this.toggleCartView();
          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );
  }

}
