<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                invoices with Prescription
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Filter current record"  />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            Invoice details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-list-section">
                        <div class="item-section" *ngFor="let x of selectedRecord.products">
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Product
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.name}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Measurement
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.unitOfMeasurement}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Selling Price
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.sellingPrice | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        quantity
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        {{x.quantity | number}}
                                    </span>
                                </div>
                            </div>
                            <div class="item-details-section">
                                <div class="label-section">
                                    <span>
                                        Total
                                    </span>
                                </div>
                                <div class="value-section">
                                    <span>
                                        &#8358; {{x.totalAmount | number:'.2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Invoice ID
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.bankTransferId}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Total amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.totalAmount | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Customer
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.customer.name}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Delivery Location
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.deliveryLocation.locationName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Prescription Document
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <a *ngIf="selectedRecord.prescriptionUri" target="_blank"
                                href="{{selectedRecord.prescriptionUri}}">
                                    View
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Internally Generated
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                <b *ngIf="selectedRecord.internal">
                                    YES
                                </b>
                                <b *ngIf="!selectedRecord.internal">
                                    NO
                                </b>
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Date generated
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="btn-details-section">
                        <div class="full-btn-details-section">
                            <button mat-flat-button *ngIf="!selectedRecord.prescriptionApproved"
                            (click)="approveSale()">
                                Approve Prescription
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:searchData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord._id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        {{x.invoiceId}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span>
                                        <b>
                                            &#8358;{{x.totalAmount | number: '.2'}}
                                        </b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
