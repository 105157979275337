import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-expiration-record',
  templateUrl: './expiration-record.component.html',
  styleUrls: ['./expiration-record.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class ExpirationRecordComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';
  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // records variables
  public records: any = [];
  public showSelectedRecord = false;
  public selectedRecord: any = {}

  // expiry product variables
  public expiryDetails: any = {
    expiryDate: null,
    productId: null,
    productName: null,
    comment: null,
    measurements: [],
    activeUnit: {
      name: null,
      availableQty: null,
      qty: null,
    }
  };

  // pagination variables
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public pageIndex = 1;
  public activePagination: any = 0;

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];


  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getRecords();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }
  
  // get records
  getRecords(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetExpiredProduct';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.records = res.Products;

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

     this.getRecords();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getRecords();

    }

  }

  // select record fnc
  selectRecord(record){

    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.product = record.productId ? record.productId : null
    this.selectedRecord.dateExpired = record.expiryDate ? record.expiryDate : null
    this.selectedRecord.comment = record.comment ? record.comment : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null
    this.selectedRecord.staff = record.staffId ? {
      name: record.staffId.personalInfo.firstName + ' ' + record.staffId.personalInfo.lastName,
      phoneNumber: record.staffId.personalInfo.phoneNumber,
      id: record.staffId._id
    } : {
      name: null,
      phoneNumber: null,
      id: null
    }
    this.selectedRecord.measurements = []

    // convert object to array
    var objectKeys = Object.keys(record.quantity);
    for (let j = 0; j < objectKeys.length; j++) {
      
      var unit = objectKeys[j];

      var measurement = {
        name: unit,
        quantity: record.quantity[unit],
        priceAtExpiration: record.priceAtExpiration[unit]
      }

      this.selectedRecord.measurements.push(measurement);
      
    }

    this.shoppingCartView = 'true';
    this.marketView = 'true';

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    this.expiryDetails.productId = null;
    this.expiryDetails.activeUnit = {};
  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Product.length > 0){

            this.searchResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  category: products[i].category[0],
                  name: products[i].name,
                  quantity: products[i].quantity,
                  unitOfMeasurement: products[i].unitOfMeasurement,
                  _id: products[i]._id,
                }
  
                this.searchResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

    this.expiryDetails.measurement = [];

  }

  // select products to expire
  chooseProduct(product){

    this.expiryDetails.measurement = [];
    this.expiryDetails.productName = product.name;
    this.expiryDetails.productId = product._id;

    for (let i = 0; i < product.unitOfMeasurement.length; i++) {
      
      var unit = {
        name: product.unitOfMeasurement[i],
        qty: 0,
        availableQty: product.quantity[product.unitOfMeasurement[i]],
        checked: false
      }

      this.expiryDetails.measurement.push(unit)
      
    }

  }

  // choose product measurement to be expired
  chooseProductMeasurement(event){

    this.expiryDetails.activeUnit = {};
    var unit = this.expiryDetails.measurement.find(o=> o.name == event.value);
    if(unit && unit.name){

      this.expiryDetails.activeUnit.name = unit.name
      this.expiryDetails.activeUnit.availableQty = unit.availableQty
      this.expiryDetails.activeUnit.qty = unit.qty

    }

  }

  // process expiration
  processExpiration(expiryForm: NgForm){

    if(this.expiryDetails.activeUnit.qty > 0 && this.expiryDetails.activeUnit.qty <= this.expiryDetails.activeUnit.availableQty){ 

      // structure request data
      var requestData = {
        comments: this.expiryDetails.comment,
        productId: this.expiryDetails.productId,
        expiryDate: this.expiryDetails.expiryDate,
        quantity: {}
      }
        
      requestData.quantity[this.expiryDetails.activeUnit.name] = this.expiryDetails.activeUnit.qty;
      this.expireProduct(requestData, expiryForm)

    }else{

      var requestResponse = 'The unit has its quantity less than 1 or greater than the available quantity.';
      this.toastNotification(requestResponse);

    }

  }

  // expire products
  expireProduct(requestData, expiryForm?){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/ExpiredProduct';

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product has been expired successfully.';
          this.toastNotification(requestResponse);

          expiryForm.resetForm();
          this.expiryDetails.measurement = [];

          this.closeSearch()
          this.getRecords();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

}
