<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Top Selling Products
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="no-data-section"  *ngIf="topSellingItems.length <= 0">
            <div class="img-section">
                <fa-icon icon="database"></fa-icon>
            </div>
            <div class="text-section">
                <span>
                    No record Found
                </span>
            </div>
        </div>
        <div class="data-section" *ngIf="topSellingItems.length > 0">
            <div class="list-section">
                <div class="list-item-section" *ngFor="let x of topSellingItems; let i = index" >
                    <div class="list-item-content" (click)="selectRecord(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                        <div class="icon-section">
                            <div class="circle">
                                <span>
                                    {{x.firstLetter}}
                                </span>
                            </div>
                        </div>
                        <div class="details-section">
                            <div class="top-details-section">
                                <span>
                                    {{x.name}}
                                </span>
                            </div>
                            <div class="middle-details-section">
                                <span>
                                    <b>
                                        &#8358; {{x.totalSales | number:'.2'}} ({{x.width}})
                                    </b>
                                </span>
                            </div>
                            <div class="down-details-section">
                                <span>
                                    <b *ngFor="let y of x.measurements">
                                        {{y.name}}({{y.quantity | number}}),  
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Product sale details
            </span>
        </div>
        <div class="down-popover-section">
            <div class="item-details-section">
                <div class="label-section">
                    <span>
                        Product Name
                    </span>
                </div>
                <div class="value-section">
                    <span>
                       {{selectedRecord.name}}
                    </span>
                </div>
            </div>
            <div class="item-details-section">
                <div class="label-section">
                    <span>
                        Total Sales
                    </span>
                </div>
                <div class="value-section">
                    <span>
                        &#8358; {{selectedRecord.totalSales | number:'.2'}} ({{selectedRecord.width}})
                    </span>
                </div>
            </div>
            <div class="big-item-details-section">
                <div class="label-section">
                    <span>
                        Sales Breakdown
                    </span>
                </div>
                <div class="value-section">
                    <span>
                       <b *ngFor="let y of selectedRecord.measurements">
                        {{y.name}}({{y.quantity | number}}), 
                       </b>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>