<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Profile
            </span>
        </div>
        <div class="btn-section">
            
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('edit')" [class.activeMenu]="activeInnerMenu == 'edit'">
                <span>
                    <fa-icon icon="user-edit"></fa-icon> Edit Profile
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('password')" [class.activeMenu]="activeInnerMenu == 'password'">
                <span>
                    <fa-icon icon="lock"></fa-icon> Change password
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'edit'">Edit Profile</b>
                    <b *ngIf="activeInnerMenu == 'password'">change password</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'edit'">
                    <form name="profileForm" (ngSubmit)="profileForm.form.valid && editProfile(profileForm)"
                    #profileForm="ngForm">
                        <div class="img-section">
                            <img src="./assets/img/avatar.png" *ngIf="!userdata.image.displayImage" 
                            alt="img" (click)="fileInput.click()">
                            <img src="{{userdata.image.displayImage}}" *ngIf="userdata.image.displayImage" 
                            alt="img" (click)="fileInput.click()">
                            <input hidden (change)="onFileSelected()" #fileInput type="file" id="file">
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    First Name
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="text" name="firstName" [(ngModel)]="userdata.personalInfo.firstName" 
                                    #firstName="ngModel"/>
                                    <mat-error *ngIf="profileForm.submitted && firstName.invalid">
                                        Please enter a valid first name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Surname
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput  type="text" name="lastName" [(ngModel)]="userdata.personalInfo.lastName" 
                                    #lastName="ngModel"/>
                                    <mat-error *ngIf="profileForm.submitted && lastName.invalid">
                                        Please enter a valid surname
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Mobile Number
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput  matInput type="tel" [(ngModel)]="userdata.personalInfo.phoneNumber" 
                                    #phoneNumber="ngModel" required pattern="[0-9]{11}" name="phoneNumber"/>
                                    <mat-error *ngIf="profileForm.submitted && phoneNumber.invalid">
                                        Please enter a valid phone number
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Email Address
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput  matInput style="text-transform:lowercase;" type="email" name="email" 
                                    [(ngModel)]="userdata.personalInfo.email" #email="ngModel"/>
                                    <mat-error *ngIf="profileForm.submitted && email.invalid">
                                        Please enter a valid email address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Age
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput  matInput type="date" name="age" [(ngModel)]="userdata.personalInfo.age" 
                                    #age="ngModel"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Residential Address
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput  matInput type="text" name="address" [(ngModel)]="userdata.personalInfo.address" 
                                    #address="ngModel"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Save
                                <fa-icon icon="angle-right"></fa-icon>
                            </button>
                        </div>
                    </form>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'password'">
                    <form name="changePasswordForm" #changePasswordForm="ngForm" 
                    (ngSubmit)="changePasswordForm.form.valid && changePassword(changePasswordForm)">
                        <div class="img-section">
                            <img src="./assets/img/avatar.png" *ngIf="!userdata.image.displayImage" >
                            <img src="{{userdata.image.displayImage}}" *ngIf="userdata.image.displayImage" >
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    Old Password
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="password" [(ngModel)]="changePasswordDetails.oldPassword" 
                                    required #oldPassword="ngModel" name="oldPassword"  />
                                    <mat-error *ngIf="changePasswordForm.submitted && oldPassword.invalid">
                                        Please enter a valid password
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-control-section">
                            <div class="label-section">
                                <span>
                                    New Password
                                </span>
                            </div>
                            <div class="control-section">
                                <mat-form-field appearance="none">
                                    <input matInput type="password" name="password" [(ngModel)]="changePasswordDetails.password"
                                    required #password="ngModel" (keyup)="checkPassword($event)" />
                                    <mat-error *ngIf="changePasswordForm.submitted && password.invalid">
                                        Please enter a valid password
                                    </mat-error>
                                    <mat-hint *ngIf="passwordStrength == 'none'">
                                        Password must be > or = 5 characters
                                    </mat-hint>
                                    <mat-hint *ngIf="passwordStrength == 'strong'">
                                        Great ! That will do
                                    </mat-hint>
                                    <mat-hint *ngIf="passwordStrength == 'weak'">
                                        Must contain numbers and alphabets (eg: abc12)
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btn-section">
                            <button mat-flat-button>
                                Change
                                <fa-icon icon="angle-right"></fa-icon>
                            </button>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>