import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-retailers',
  templateUrl: './retailers.component.html',
  styleUrls: ['./retailers.component.scss']
})
export class RetailersComponent implements OnInit {

  // retailers variables
  public retailer: any = [
    {
      fname: '',
      lname: '',
      email: '',
      phoneNumber: '',
    }
  ];

  public showSelectedRetailer = false;

  public selectedRetailer: any = {
    id: '',
    name: '',
    role: '',
    phoneNumber: ''
  }

  // pagination variables
  public totalRecords: any;

  public pageSize: any = 30;

  public pageArray = [];

  public noOfPages = 0;

  public pageIndex = 1;

  public activePagination: any = 0;

  // add retailer variables
  public addRetailerDetails: any = {
    phoneNumber: '',
    fname: '',
    lname: '',
    age: '',
    email: '',
    address: ''
  }

  public createRetailerStatus: any =  false;

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {
  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // select record fnc
  selectRetailer(record){

    this.showSelectedRetailer = true;

    this.selectedRetailer = {
      itembrought: record.itembrought,
      fname: record.fname,
      lname: record.lname,
      id: record.id,
      email: record.email,
      address: record.address,
      phoneNumber: record.phoneNumber,
      gender: record.gender,
      age: record.age,
      timestamp: record.timestamp
    }

  }
  
  // edit retailer
  editRetailer(editForm: NgForm){


  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      //this.getRetailer();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      //this.getRetailer();

    }

  }

  // toggel add retailer
  toggleAddRetailer(status){

    this.createRetailerStatus = status;

  }

  // add customer
  addRetailer(addRetailerForm: NgForm){

    

  }

}
