import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-online-category',
  templateUrl: './online-category.component.html',
  styleUrls: ['./online-category.component.scss']
})
export class OnlineCategoryComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

  // product records variables
  public categories: any = [];

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 20;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // deactivate product variables
  public records: any = [];
  public onlineCategoryDetails: any = {
    id: '',
    status: false
  }

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getCategories();
    this.getAllCategories();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get online categories
  getCategories(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'GetCategories';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getEcommerceHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.categories = [];

          var categories = res.Categories

          for (let i = 0; i < categories.length; i++) {

            if(categories[i]){

              var category = {
                id: categories[i]._id,
                name: categories[i].name ? this.capitalize(categories[i].name.toLowerCase()) : null,
                firstLetter: categories[i].name.substring(0,1),
                subCategories:categories[i].subCategories,
              }

              this.categories.push(category);
              
            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // capitalize text
  capitalize(sample){
    const words = sample.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  }

  // TOGGLE ONLINE CATEGORY FUNCTION

  // get all categories
  getAllCategories(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/GetCategories';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          var categories = res.Categories;

          this.records = [];

          for (let i = 0; i < categories.length; i++) {
            
            if(categories[i]){

              var category = {
                name: categories[i].name,
                subCategories:categories[i].subCategories,
                _id: categories[i]._id,
                firstLetter: categories[i].name.substring(0,1),
                products: categories[i].product
              };

              this.records.push(category);

            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // deactivate function
  toggleOnlineCategory(categoryForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/DisableOrEnableCategoryOnline';
    var requestData: any = {
      categoryId: this.onlineCategoryDetails.id,
      enableOrDisable: this.onlineCategoryDetails.status == null ? false : this.onlineCategoryDetails.status
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Category online status changed successfully.';
          this.toastNotification(requestResponse);

          this.onlineCategoryDetails.id = null;

          categoryForm.resetForm();
          
          this.toggleInnerMenu('list')

          this.getCategories();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

}
