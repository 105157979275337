import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

  // product records variables
  public products: any = [];
  public selectedProduct: any = {};

  // search variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 50;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // restock product variables
  public searchRestockData: any;
  public searchRestockResult: any = [];
  public restockDetails: any = {
    item: '',
    expiryDate: '',
    unitOfMeasurement: []
  }


  // export variables
  public exportProducts: any = [];

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getProducts();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get products
  getProducts(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetProduct';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.products = [];

          var products = res.Products;

          for (let i = 0; i < products.length; i++) {
            
            if(products[i]){

              var product = {
                barCode: products[i].barCode,
                category: products[i].category[0] ? products[i].category[0] : {name:null},
                costPrice: products[i].costPrice,
                createdAt: products[i].createdAt,
                description: products[i].description,
                discount: products[i].discount,
                discountType: products[i].discountType,
                discountUnit: products[i].discountUnit,
                expiryDate: products[i].expiryDate,
                limitPrice: products[i].limitPrice,
                moreImages: products[i].moreImages,
                name: products[i].name,
                previousQuantity: products[i].previousQuantity,
                previousSellingPrice: products[i].previousSellingPrice,
                quantity: products[i].quantity,
                reOrderLimit: products[i].reOrderLimit,
                sellOnline: products[i].sellOnline,
                sellingPrice: products[i].sellingPrice,
                staffId: products[i].staffId,
                status: products[i].status,
                unitOfMeasurement: products[i].unitOfMeasurement,
                _id: products[i]._id,
                firstLetterOfCategory: products[i].category[0] ? products[i].category[0].name.substring(0,1) : null
              }

              this.products.push(product)

            }
            
          }

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getProducts();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getProducts();

    }

  }

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;
    

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Product.length > 0){

            this.searchResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  barCode: products[i].barCode,
                  category: products[i].category[0],
                  costPrice: products[i].costPrice,
                  createdAt: products[i].createdAt,
                  description: products[i].description,
                  discount: products[i].discount,
                  discountType: products[i].discountType,
                  discountUnit: products[i].discountUnit,
                  expiryDate: products[i].expiryDate,
                  limitPrice: products[i].limitPrice,
                  moreImages: products[i].moreImages,
                  name: products[i].name,
                  previousQuantity: products[i].previousQuantity,
                  previousSellingPrice: products[i].previousSellingPrice,
                  quantity: products[i].quantity,
                  reOrderLimit: products[i].reOrderLimit,
                  sellOnline: products[i].sellOnline,
                  sellingPrice: products[i].sellingPrice,
                  staffId: products[i].staffId,
                  status: products[i].status,
                  unitOfMeasurement: products[i].unitOfMeasurement,
                  _id: products[i]._id,
                  firstLetterOfCategory: products[i].category[0].name.substring(0,1)
                }
  
                this.searchResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select product from product list
  selectProduct(product){

    this.goToPage('products/update/'+product._id)

  }

  // RESTOCK PRODUCT FUNCTION

  // search function
  searchRestockEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchRestockData = searchData;
      this.searchRestockResult = [];

      this.restockDetails.item = null;
      this.restockDetails.unitOfMeasurement = [];
      
      this.searchRestockRequest();

    }

  }

  // close search result
  closeRestockSearch(){

    this.searchRestockResult = [];
    this.searchRestockData = '';

    this.restockDetails.item = null;
    this.restockDetails.unitOfMeasurement = [];
  }

  // search request
  searchRestockRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchRestockData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          if(res.Product.length > 0){

            this.searchRestockResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  category: products[i].category[0],
                  name: products[i].name,
                  costPrice: products[i].costPrice,
                  quantity: products[i].quantity,
                  unitOfMeasurement: products[i].unitOfMeasurement,
                  _id: products[i]._id,
                }
  
                this.searchRestockResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // create restock structure for the the selected item
  chooseProduct(product){

    this.restockDetails.item = product._id
    this.restockDetails.unitOfMeasurement = [];

    for (let i = 0; i < product.unitOfMeasurement.length; i++) {
      
      var restockStructure = {
        name: product.unitOfMeasurement[i],
        quantity: 0,
        costPrice: product.costPrice[product.unitOfMeasurement[i]],
      };

      this.restockDetails.unitOfMeasurement.push(restockStructure);
      
    }

  }

  // restock function
  restockItem(restockForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Inventory/AddInventory';
    var requestData: any = {
      productId: this.restockDetails.item,
      expiryDate: this.restockDetails.expiryDate,
      inventoryTypes: 'restock',
      quantity: {},
      costPrice: {},
    }

    for (let i = 0; i < this.restockDetails.unitOfMeasurement.length; i++) {
      
      if(this.restockDetails.unitOfMeasurement[i]){

        var measurement = this.restockDetails.unitOfMeasurement[i];

        // add quantity for unit of measurement
        if(measurement.quantity > 0){

          requestData.quantity[measurement.name] = measurement.quantity

        }

        // add cost Price for unit of measurement
        if(measurement.quantity > 0 && measurement.costPrice > 0){
          
          requestData.costPrice[measurement.name] = measurement.costPrice

        }

      }
    }

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product restocked successfully.';
          this.toastNotification(requestResponse);

          restockForm.resetForm();
          this.closeRestockSearch()

          this.getProducts();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }

  // export request
  exportRequest(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/GetProductsWithOutPagination';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.exportProducts = [];

          var products = res.Products;

          var addedProducts = [];

          for (let i = 0; i < products.length; i++) {
            
            if(products[i]){

              for (let j = 0; j < products[i].unitOfMeasurement.length; j++) {

                const productMesurement = products[i].unitOfMeasurement[j];

                if(addedProducts.includes(products[i].name)){

                  var product_empty = {
                    name: '',
                    category: '',
                    unitOfMeasurement: productMesurement,
                    quantity: products[i].quantity[productMesurement] ? products[i].quantity[productMesurement] : null,
                    costPrice: products[i].costPrice[productMesurement] ? products[i].costPrice[productMesurement] : null,
                    wholesalePrice: products[i].limitPrice[productMesurement] ? products[i].limitPrice[productMesurement] : null,
                    retailPrice: products[i].sellingPrice[productMesurement] ? products[i].sellingPrice[productMesurement] : null,
                    reOrderLimit: products[i].reOrderLimit[productMesurement] ? products[i].reOrderLimit[productMesurement] : null,
                    discount: products[i].discount[productMesurement] ? products[i].discount[productMesurement] : null,
                    discountType: products[i].discountType[productMesurement] ? products[i].discountType[productMesurement] : null,
                    discountUnit: products[i].discountUnit[productMesurement] ? products[i].discountUnit[productMesurement] : null,
                  }
    
                  this.exportProducts.push(product_empty)

                }else{

                  var product = {
                    name: products[i].name,
                    category: products[i].category[0].name,
                    unitOfMeasurement: productMesurement,
                    quantity: products[i].quantity[productMesurement] ? products[i].quantity[productMesurement] : null,
                    costPrice: products[i].costPrice[productMesurement] ? products[i].costPrice[productMesurement] : null,
                    wholesalePrice: products[i].limitPrice[productMesurement] ? products[i].limitPrice[productMesurement] : null,
                    retailPrice: products[i].sellingPrice[productMesurement] ? products[i].sellingPrice[productMesurement] : null,
                    reOrderLimit: products[i].reOrderLimit[productMesurement] ? products[i].reOrderLimit[productMesurement] : null,
                    discount: products[i].discount[productMesurement] ? products[i].discount[productMesurement] : null,
                    discountType: products[i].discountType[productMesurement] ? products[i].discountType[productMesurement] : null,
                    discountUnit: products[i].discountUnit[productMesurement] ? products[i].discountUnit[productMesurement] : null,
                  }
                  
                  this.exportProducts.push(product)

                }

                addedProducts.push(products[i].name)
                
              }

            }
            
          }

          this.exportData();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // export data
  exportData(){

    if(this.exportProducts.length > 0){

      var json: any[] =  this.exportProducts
      var excelFileName: string = 'product_list';

      const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
      const workbook: xlsx.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);

    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    var excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var ext = '.xlsx';
    var time = moment().format('d/MMM/YYYY');

    const data: Blob = new Blob([buffer], {type: excelType});
    fileSaver.saveAs(data, fileName + '_export_' + time + ext);
  }

}
