import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.scss']
})
export class FeaturedProductsComponent implements OnInit {

  // menu variables
  public activeInnerMenu: any = 'list';

  // product records variables
  public products: any = [];
  public selectedProduct: any = {};

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 20;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // deactivate product variables
  public showSearchItems = false;
  public showSearchLoading = false;
  public searchData: any;
  public searchResult: any = [];
  public productDetails: any = {
    id: '',
    status: false
  }

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getFeaturedProducts();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle Inner Menu
  toggleInnerMenu(menu: any){

    this.activeInnerMenu = menu

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get online products
  getFeaturedProducts(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'GetFeatureProduct';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getEcommerceHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.products = [];

          var products = res.Products;

          for (let i = 0; i < products.length; i++) {
            
            if(products[i]){

              var product = {
                barCode: products[i].barCode,
                category: products[i].category[0],
                costPrice: products[i].costPrice,
                createdAt: products[i].createdAt,
                description: products[i].description,
                discount: products[i].discount,
                discountType: products[i].discountType,
                discountUnit: products[i].discountUnit,
                expiryDate: products[i].expiryDate,
                limitPrice: products[i].limitPrice,
                moreImages: products[i].moreImages,
                name: products[i].name,
                previousQuantity: products[i].previousQuantity,
                previousSellingPrice: products[i].previousSellingPrice,
                quantity: products[i].quantity,
                reOrderLimit: products[i].reOrderLimit,
                sellOnline: products[i].sellOnline,
                sellingPrice: products[i].sellingPrice,
                staffId: products[i].staffId,
                status: products[i].status,
                unitOfMeasurement: products[i].unitOfMeasurement,
                _id: products[i]._id,
                firstLetterOfCategory: products[i].category[0].name.substring(0,1)
              }

              this.products.push(product)

            }
            
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // select product from product list
  selectProduct(product){

    this.goToPage('products/update/'+product._id)

  }

  // TOGGLE ONLINE PRODUCT FUNCTION

  // search function
  searchEvent(event){

    var searchData = event.target.value;

    if(searchData && searchData.toString().length > 2 ){

      this.searchData = searchData;
      this.showSearchItems = true;
      this.showSearchLoading = true;
      this.searchResult = [];

      this.searchRequest();

    }

  }

  // close search result
  closeSearch(){

    this.searchResult = [];
    this.searchData = '';
    this.showSearchItems = false;
    this.showSearchLoading = false;

  }

  // search request
  searchRequest(){

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Product/SearchProductByKeywords';
    var requestData = {
      keywords: this.searchData,
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        if(res.status.toLowerCase() == httpSuccessText){

          this.showSearchLoading = false;

          if(res.Product.length > 0){

            this.searchResult = [];

            var products = res.Product;
  
            for (let i = 0; i < products.length; i++) {
              
              if(products[i]){
  
                var product = {
                  category: products[i].category[0],
                  name: products[i].name,
                  costPrice: products[i].costPrice,
                  quantity: products[i].quantity,
                  unitOfMeasurement: products[i].unitOfMeasurement,
                  _id: products[i]._id,
                }
  
                this.searchResult.push(product)
  
              }
              
            }
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // create deactivate structure for the the selected item
  chooseProduct(product){

    this.productDetails.id = product._id
    this.productDetails.name = product.name

  }

  // deactivate function
  toggleFeaturedProducts(productForm: NgForm){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'OnlineSale/AddOrRemoveFeatureProduct';
    var requestData: any = {
      productId: this.productDetails.id,
      addToFeatureProduct: this.productDetails.status == null ? false : this.productDetails.status
    }

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Product online status changed successfully.';
          this.toastNotification(requestResponse);

          this.productDetails.id = null;

          productForm.resetForm();

          this.closeSearch()
          
          this.toggleInnerMenu('list')

          this.getFeaturedProducts();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }


}
