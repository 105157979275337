<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Staff Return record
            </span>
        </div>
        <div class="filter-section">
        </div>
        <div class="btn-section">
            <div class="left-btn-section">
                <div class="search-section">
                    <div class="search-holder">
                        <div class="icon-section">
                            <fa-icon icon="search" matPrefix></fa-icon>
                        </div>
                        <div class="left-search-section">
                            <mat-form-field appearance="none" class="searchId">
                                <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                                placeholder="Filter current record"  />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-btn-section">
                <button mat-flat-button (click)="goToPage('staff/list')">
                    Staff List
                    
                </button>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="right-section" [@animateArc]="shoppingCartView">
            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                <div class="top-details-section">
                    <div class="left-top-details-section">
                        <span>
                            sale Return details
                        </span>
                    </div>
                    <div class="right-top-details-section">
                        <div class="icon-section" (click)="toggleCartView()">
                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="down-details-section">
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Product
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.product.productName}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Measurement
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.product.unitOfMeasurement}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Selling Price
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.product.sellingPrice | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                sold quantity
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.product.soldQuantity | number}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                returned quantity
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.product.returnedQuantity | number}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Return Type
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.returnType}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section" *ngIf="selectedRecord.returnType == 'Refund'">
                        <div class="label-section">
                            <span>
                                Refunded Amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{selectedRecord.amountReturn | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Date Returned
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.createdAt | date : 'hh:mm:ss a dd MMM, yyyy': "UTC"}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section" *ngIf="selectedRecord.comment">
                        <div class="label-section">
                            <span>
                                Comment
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{selectedRecord.comment}}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Staff
                            </span>
                        </div>
                        <div class="value-section">
                            <span *ngIf="selectedRecord.staff">
                                {{selectedRecord.staff.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-section" [@animateOtherArc]="marketView">
            <div class="data-section" *ngIf="records.length > 0">
                <div class="list-section">
                    <div class="list-item-section" *ngFor="let x of records|filter:searchData; let i = index" 
                    [class.newListItemSection]="marketView == 'true'" (click)="selectRecord(x)"
                    [class.activeRecord]="x._id == selectedRecord._id">
                        <div class="list-item-content" >
                            <div class="details-section">
                                <div class="top-details-section">
                                    <span>
                                        &#8358; {{x.amountReturn | number: '.2'}}
                                    </span>
                                </div>
                                <div class="middle-details-section">
                                    <span style="text-transform: capitalize;">
                                        Staff: <b>{{x.staffId.personalInfo.firstName +' '+ x.staffId.personalInfo.lastName}}</b>
                                    </span>
                                </div>
                                <div class="down-details-section">
                                    <span>
                                        {{x.createdAt | date : 'hh:mm:ss a dd/MMM/yyyy ': "UTC"}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-section">
                    <div class="pagination-text" (click)="getPage(1)">
                        <span>
                            First
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(-1)">
                        <span>
                            Prev
                        </span>
                    </div>
                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                    *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                        <span>
                            {{x}}
                        </span>
                    </div>
                    <div class="pagination-text" (click)="paginate(1)">
                        <span>
                            Next
                        </span>
                    </div>
                    <div class="pagination-text" (click)="getPage(noOfPages)">
                        <span>
                            Last
                        </span>
                    </div>
                    <div class="pagination-text" (click)="clearFilter()" *ngIf="dateFilterShow">
                        <span>
                            Clear
                        </span>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="records.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No record Found
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>