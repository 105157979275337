<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Invoice Payment
            </span>
        </div>
        <div class="btn-section">
            <button mat-flat-button (click)="goToPage('invoices/generated');" *ngIf="!printStatus">
                Invoices
                
            </button>
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="data-section" *ngIf="checkoutDetails.cart.length > 0">
                <div class="list-section">
                    <div class="cart-list-item-section" *ngFor="let y of checkoutDetails.cart; let j = index">
                        <div class="cart-item-section">
                            <div class="right-cart-item-section">
                                <div class="top-right-cart-item-section">
                                    <span>
                                        {{y.name}} ({{y.measurement}})
                                    </span>
                                </div>
                                <div class="middle-right-cart-item-section">
                                    <span>
                                        quantity: {{y.quantity | number}}
                                    </span>
                                </div>
                                <div class="down-right-cart-item-section">
                                    <span>
                                        total: <b>&#8358; {{y.totalAmount | number:'.2'}}</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-data-section"  *ngIf="checkoutDetails.cart.length <= 0">
                <div class="img-section">
                    <fa-icon icon="database"></fa-icon>
                </div>
                <div class="text-section">
                    <span>
                        No Item Found
                    </span>
                </div>
            </div>
        </div>
        <div class="middle-section" *ngIf="checkoutDetails.cart.length > 0">
            <div class="top-cart-section">
                <div class="left-top-cart-section">
                    <span>
                        Order Summary
                    </span>
                </div>
                <div class="right-top-cart-section" >
                    <span>
                        &#8358; {{checkoutDetails.metadata.totalAmountAfterVat | number:'.2'}}
                    </span>
                </div>
            </div>
            <div class="down-cart-section" [ngbPopover]="popContent"
            triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                <div class="summary-section">
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Invoice Number
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{ checkoutDetails.metadata.bankTransferId }}
                            </span>
                        </div>
                    </div>
                    <div class="big-item-details-section">
                        <div class="label-section">
                            <span>
                                Customer
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                {{checkoutDetails.customerDetails.name}} ({{checkoutDetails.customerDetails.phoneNumber}})
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Cart Amount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358;  {{ 
                                    checkoutDetails.metadata.cartTotalWithoutDiscount - 
                                    checkoutDetails.metadata.shippingFee - 
                                    checkoutDetails.metadata.vatAmount  | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Total Discount
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{ checkoutDetails.metadata.totalDiscount  | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                Shipping Fee
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358; {{ checkoutDetails.metadata.shippingFee  | number:'.2'}}
                            </span>
                        </div>
                    </div>
                    <div class="item-details-section">
                        <div class="label-section">
                            <span>
                                VAT ({{checkoutDetails.metadata.vatPercentage | number:'.1'}}%)
                            </span>
                        </div>
                        <div class="value-section">
                            <span>
                                &#8358;  {{ checkoutDetails.metadata.vatAmount  | number:'.2'}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="order-payment-section">
                    <div class="top-cart-section">
                        <div class="left-top-cart-section">
                            <span>
                                Payment Details
                            </span>
                        </div>
                        <div class="right-top-cart-section" >
                        </div>
                    </div>
                    <div class="down-cart-section" >
                        <div class="payment-section" >
                            <div class="form-control-section" 
                            *ngFor="let x of checkoutDetails.metadata.paymentOption; let i = index">
                                <div class="label-section">
                                    <span>
                                        {{x.paymentType}}: <b> &#8358; {{x.amount | number:'.2'}}</b>
                                    </span>
                                </div>
                                <div class="control-section">
                                    <mat-form-field appearance="none">
                                        <input matInput style="text-transform:capitalize;" type="number" name="amount{{i}}"
                                        [(ngModel)]="x.amount" (keyup)="calculatePaidAmount()" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-control-section">
                                <div class="label-section">
                                    <span>
                                        Select Type of Sale
                                    </span>
                                </div>
                                <div class="control-section">
                                    <mat-form-field appearance="none">
                                        <mat-select name="salesType" style="text-transform: capitalize;"
                                        [(ngModel)]="checkoutDetails.metadata.salesType" #salesType="ngModel">
                                            <mat-option style="text-transform: capitalize" *ngFor="let x of salesOptions" 
                                            value="{{x.value}}" (selectionChange)="structureCreditSales()">{{x.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-control-section" *ngIf="checkoutDetails.metadata.salesType == 'creditSales'">
                                <div class="label-section">
                                    <span>
                                        Total Amount Paid
                                    </span>
                                </div>
                                <div class="control-section">
                                    <mat-form-field appearance="none">
                                        <input matInput type="number" name="debtAmount" disabled style="text-transform: capitalize;"
                                        [(ngModel)]="checkoutDetails.metadata.creditAmountPaid" #debtAmount="ngModel"/>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-control-section" *ngIf="checkoutDetails.metadata.salesType == 'creditSales'">
                                <div class="label-section">
                                    <span>
                                        Payment Final Date
                                    </span>
                                </div>
                                <div class="control-section">
                                    <mat-form-field appearance="none">
                                        <input matInput [matDatepicker]="picker" name="debtExpectedDate" 
                                        #debtExpectedDate="ngModel" [(ngModel)]="checkoutDetails.metadata.creditFinalDate" >
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="btn-section" *ngIf="printStatus">
                                <div class="left-btn-section">
                                    <button mat-flat-button printSectionId="print-section" ngxPrint >
                                        Print receipt
                                        
                                    </button>
                                </div>
                                <div class="right-btn-section">
                                    <button mat-flat-button (click)="clearCheckoutAndCart()">
                                        Finish
                                        
                                    </button>
                                </div>
                            </div>
                            <div class="btn-section" *ngIf="!printStatus">
                                <div class="full-btn-section" *ngIf="!confirmCustomer">
                                    <button mat-flat-button (click)="searchCustomer()" >
                                        Confirm Customer
                                        
                                    </button>
                                </div>
                                <div class="full-btn-section" *ngIf="confirmCustomer && 
                                this.checkoutDetails.metadata.storePickUp">
                                    <button mat-flat-button (click)="processSale()" *ngIf="showSellButton">
                                        Sell now
                                        
                                    </button>
                                </div>
                                <div class="full-btn-section" *ngIf="confirmCustomer && 
                                !this.checkoutDetails.metadata.storePickUp">
                                    <button mat-flat-button (click)="togglePopover(popOver, 'rider')" 
                                    *ngIf="!assignDetails.assigned">
                                        Assign Rider
                                        
                                    </button>
                                    <button mat-flat-button (click)="processSale()" 
                                    *ngIf="showSellButton && assignDetails.assigned">
                                        Sell now
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="print-section">
    <div id="head-section" style="height: auto; width: 100%; border-bottom: 5px solid black">
        <div id="logo-section" style="height: 50px; width: 100%; position: relative;">
            <img id="logo-image" src="./assets/img/logo.png" alt="image" style="position: absolute; top: 50%; left: 50%; 
            transform: translate(-50%,-50%); height: 44.5px; width: 131px;">
        </div>
        <div id="name-section" style="height: 20px; width: 100%; position: relative;">
            <span style="position: absolute; top: 15%; left: 0; width: 100%; height: 70%; font-size: 14px; font-family: bold; 
            text-align: center;">
                VICPHARM INDUSTRIES LIMITED.
            </span>
        </div>
        <div id="location-section" style="height: 30px; width: 100%; position: relative;">
            <span style="position: absolute; top: 5%; left: 0; width: 100%; height: 90%; font-size: 11px; font-family: normal; 
            text-align: center;">
                Suite AO10 & AO11 Zitel Plaza, Plot 227, P.O.W. Mafemi Crescent, Utako, Abuja.
            </span>
        </div>
    </div>
    <div id="body-section" style="height: auto; width: 100%; border-bottom: 5px solid black;">
        <div id="staff-date-section" style="float: left; height: auto; width: 100%; margin: 0; 
        border-bottom: 2.5px solid black;">
            <div id="label-value-section" style="float: left; height: 20px; width: 100%; padding-top: 10px">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Receipt ID
                    </span>
                </div>
                <div id="value-section" style="text-transform: none; float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.receiptId}}
                    </span>
                </div>
            </div>
            <div id="label-value-section" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Date/Time
                    </span>
                </div>
                <div id="value-section" style="text-transform: capitalize;float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.date}}
                    </span>
                </div>
            </div>
            <div id="label-value-section" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 30%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; 
                    font-size: 12px; font-weight: bold;">
                        Cashier
                    </span>
                </div>
                <div id="value-section" style="text-transform: capitalize;float: left; height: 100%; width: 70%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 12px; 
                    font-weight: normal;">
                        {{receiptData.cashier}}
                    </span>
                </div>
            </div>
        </div>
        <div id="item-head-section" style="float: left; height: 20px; width: 100%; margin: 0; border-bottom: 2.5px solid black;">
            <div id="desc-section" style="float: left; height: 20px; width: 25%; position: relative; text-align: left;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Item
                </span>
            </div>
            <div id="qty-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: center;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Qty
                </span>
            </div>
            <div id="price-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: center;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Price
                </span>
            </div>
            <div id="total-section" style="float: left; height: 100%; width: 25%; position: relative; text-align: right;">
                <span style="height: 20px; width: 100%;  font-size: 12px; font-weight: bold;">
                    Total
                </span>
            </div>
        </div>
        <div id="item-body-section" style="float: left; height: auto; width: 100%; margin: 0; border-bottom: 1.30px solid black;"
        *ngFor="let x of receiptData.products">
            <div id="desc-section" style="float: left; height: auto; width: 25%; position: relative; text-align: left; padding: 2.5px 0;">
                <span style=" height: auto; width: 100%;  font-size: 12px; font-weight: normal; 
               overflow-wrap: break-word; text-transform: capitalize;">
                    {{x.name}} ({{x.measurement}})
                </span>
            </div>
            <div id="qty-section" style="float: left; height: auto; width: 25%; position: relative; text-align: center; padding: 2.5px 0;">
                <span style=" height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    {{x.quantity |  number}}
                </span>
            </div>
            <div id="price-section" style="float: left; height: auto; width: 25%; position: relative; text-align: center; padding: 2.5px 0;">
                <span style="height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    &#8358;{{x.sellingPrice | number:".2"}}
                </span>
            </div>
            <div id="total-section" style="float: left; height: auto; width: 25%; position: relative; text-align: right; padding: 2.5px 0;">
                <span style="height: auto; width: 100%;  font-size: 12px; font-weight: normal;">
                    &#8358;{{x.totalAmount | number:".2"}}
                </span>
            </div>
        </div>
        <div id="item-total-section" style="float: left; height: auto; width: 100%; margin: 0; border-bottom: 5px solid black;">
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        Total Amount
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.cartTotalWithoutDiscount - 
                            receiptData.shippingFee - receiptData.vatAmount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        Shipping Fee
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.shippingFee | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        VAT ({{receiptData.vatPercentage|number}})%
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.vatAmount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        Discount
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.discount | number:".2"}}
                    </span>
                </div>
            </div>
            <div id="item-total-holder" style="float: left; height: 20px; width: 100%;">
                <div id="label-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: left; font-size: 13px; font-weight: bold;">
                        NET Total
                    </span>
                </div>
                <div id="value-section" style="float: left; height: 100%; width: 50%; position: relative;">
                    <span style="position: absolute; top: 5%; height: 90%; left: 0; width: 100%; text-align: right; font-size: 13px; font-weight: normal;">
                        &#8358;{{receiptData.netTotal | number:".2"}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div id="footer-section" style="height: auto; width: 100%;">
        <div id="advert-section" style="float: left; height: 20px; width: 100%; position: relative; padding-top: 10px"
        *ngFor="let x of receiptData.adverts">
            <span style="position: absolute; top: 10%; left: 0; width: 100%; height: 80%; font-size: 11px; font-family: normal; text-align: center;">
                {{x.advert}} 
            </span>
        </div>
    </div>    
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span *ngIf="selectedAction.action == 'rider'">
                Assign Order to Rider
            </span>
        </div>
        <div class="down-popover-section">
            <div class="cancel-section" *ngIf="selectedAction.action == 'rider'">
                <form name="editForm" #editForm="ngForm" (ngSubmit)="editForm.form.valid && completeSaleAfterRider()">
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Invoice Number
                            </span>
                        </div>
                        <div class="control-section">
                            <mat-form-field appearance="none" >
                                <input matInput style="text-transform:capitalize;" type="text" name="name" 
                                [(ngModel)]="checkoutDetails.metadata.bankTransferId" #name="ngModel" readonly/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Rider
                            </span>
                        </div>
                        <div class="control-section">
                            <select name="role" [(ngModel)]="selectedAction.rider" required #role="ngModel" 
                            required>
                                <option value="{{x._id}}" *ngFor="let x of riderResult">
                                    {{x.personalInfo.firstName}} {{x.personalInfo.lastName}}
                                </option>
                            </select>
                            <mat-error *ngIf="editForm.submitted && role.invalid">
                                Please enter a valid role
                            </mat-error>
                        </div>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button>
                            Assign
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>