import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RequestService } from '../../functions/request.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-wholesale-tokens',
  templateUrl: './wholesale-tokens.component.html',
  styleUrls: ['./wholesale-tokens.component.scss'],
  animations: [
    trigger('animateArc', [
      state('true', style({
        width: '30%',
      })),
      state('false', style({
        width: '0%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '0%', }),
        style({ width: '30%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '30%', }),
        style({ width: '0%', }),
      ])))
    ]),
    trigger('animateOtherArc', [
      state('true', style({
        width: '70%',
      })),
      state('false', style({
        width: '100%',
      })),
      transition('false => true', animate('500ms linear', keyframes([
        style({ width: '100%', }),
        style({ width: '70%', }),
      ]))),
      transition('true => false', animate('500ms linear', keyframes([
        style({ width: '70%', }),
        style({ width: '100%', }),
      ])))
    ])
  ]
})
export class WholesaleTokensComponent implements OnInit {

  public shoppingCartView: string = 'false';
  public marketView: string = 'false'

  // product records variables
  public tokens: any = [];
  public selectedRecord: any = {}
  public popOver: NgbPopover;

  // pagination variables
  public pageIndex = 1;
  public totalRecords: any;
  public pageSize: any = 20;
  public pageArray = [];
  public noOfPages = 0;
  public activePagination: any = 0;

  // generate token
  public generatedToken: any = null;

  constructor(
    private functionService: RequestService,
  ) { }

  ngOnInit(): void {

    this.getTokens();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }

  // toggle side cart view
  toggleCartView(){
    this.shoppingCartView = this.shoppingCartView === 'false' ? 'true' : 'false';
    this.marketView = this.marketView === 'false' ? 'true' : 'false';
  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // get online tokens
  getTokens(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GetAllTheUsedSalesToken';
    var requestData = {
      page: this.pageIndex,
    }

    this.functionService.getTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.tokens = res.salesToken

          this.structurePagination(res.totalItems);
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // structure pagination 
  structurePagination(totalItems){

    this.totalRecords = totalItems;

    this.noOfPages = Math.ceil(this.totalRecords/this.pageSize);

    this.pageArray = [];

    var start = this.pageIndex - 2;
    if(start < 0){

      start = 1

    }

    var end = this.pageIndex + 3;
    if(end < 6 && end <= this.noOfPages){

      end = 6

    }else if(end > this.noOfPages){

      end = this.noOfPages + 1

    }

    for (let i = start; i < end; i++) {

      this.pageArray.push(i);
      
    }

  }

  // get pages on scroll
  paginate(page){

    var pageIndex = this.pageIndex

    this.pageIndex = (this.pageIndex * 1) + (page * 1);

    if(this.pageIndex <= this.noOfPages && this.pageIndex > 0){

      this.getTokens();

    }else{

      this.pageIndex = pageIndex

    }

  }

  // get page on request
  getPage(page){

    if(page <= this.noOfPages){

      this.pageIndex = page;

      this.getTokens();

    }

  }

  // select record fnc
  selectRecord(record){
 
    this.selectedRecord.id = record._id ? record._id : null
    this.selectedRecord.valid = record.valid ? record.valid : null
    this.selectedRecord.usedBy = record.usedBy ? record.usedBy : null
    this.selectedRecord.used = record.used ? record.used : null
    this.selectedRecord.salesToken = record.salesToken ? record.salesToken : null
    this.selectedRecord.generatedBy = record.generatedBy ? record.generatedBy : null
    this.selectedRecord.expired = record.expired ? record.expired : null
    this.selectedRecord.createdAt = record.createdAt ? record.createdAt : null

    this.shoppingCartView = 'true';
    this.marketView = 'true';
 
  }

  // generate Token
  generateToken(popOver){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/GenerateASalesToken';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          this.generatedToken = res.salesToken;

          requestResponse = 'Token generated.';
          this.toastNotification(requestResponse);

          this.togglePopover(popOver);
          //this.getTokens();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }
    );

  }
  // open and close popover
  togglePopover(popOver?: NgbPopover){

    if(popOver){

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;

    }
  }
}
