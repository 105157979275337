<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Customer List
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData" 
                            placeholder="Search by name" (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="right-search-section">
                        <button mat-flat-button (click)="searchRequest();">
                            Search
                        </button>
                    </div>
                </div>
                <div class="search-list-holder" *ngIf="showSearchItems" [ngbPopover]="popContent" 
                [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                    <div class="top-search-section">
                        <span (click)="closeSearch();">
                            close
                        </span>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length > 0">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index" 
                        (click)="selectCustomer(x, i, 'search', popOver)">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.fullName}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.phoneNumber}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length < 1">
                        <div class="loading-section">
                            <span class="no-data-holder" *ngIf="!showSearchLoading">
                                no result found for "{{searchData}}"
                            </span>
                            <span class="loader-holder" *ngIf="showSearchLoading">
                                loading...
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="no-data-section"  *ngIf="customer.length < 1">
            <div class="img-section">
                <fa-icon icon="database"></fa-icon>
            </div>
            <div class="text-section">
                <span>
                    No Customer Found
                </span>
            </div>
        </div>
        <div class="data-section" *ngIf="customer.length > 0">
            <div class="list-section">
                <div class="list-item-section" *ngFor="let x of customer; let i = index" >
                    <div class="list-item-content" (click)="selectCustomer(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                    [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                        <div class="icon-section">
                            <div class="circle">
                                <span>
                                    {{x.firstLetter}}
                                </span>
                            </div>
                        </div>
                        <div class="details-section">
                            <div class="top-details-section">
                                <span>
                                    {{x.fullName}}
                                </span>
                            </div>
                            <div class="middle-details-section">
                                <span>
                                    <b>
                                        {{x.phoneNumber}} 
                                    </b>
                                </span>
                            </div>
                            <div class="down-details-section">
                                <span>
                                    {{x.personalInfo.email}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-section">
                <div class="pagination-text" (click)="getPage(1)">
                    <span>
                        First
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(-1)">
                    <span>
                        Prev
                    </span>
                </div>
                <div class="pagination-text" [class.activePage]="pageIndex == x"
                *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                    <span>
                        {{x}}
                    </span>
                </div>
                <div class="pagination-text" (click)="paginate(1)">
                    <span>
                        Next
                    </span>
                </div>
                <div class="pagination-text" (click)="getPage(noOfPages)">
                    <span>
                        Last
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                {{selectedCustomer.name}}
            </span>
        </div>
        <div class="down-popover-section">
            <form name="editForm" #editForm="ngForm" (ngSubmit)="editForm.form.valid && editCustomer(editForm)">
                <div class="form-control-section">
                    <div class="label-section">
                        <span>
                            Full Name
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="fullName" [(ngModel)]="selectedCustomer.fullName" 
                            #fullName="ngModel" required/>
                            <mat-error *ngIf="editForm.submitted && fullName.invalid">
                                Please enter a valid full name
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-control-section">
                    <div class="label-section">
                        <span>
                            Phone Number
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="phoneNumber" #phoneNumber="ngModel"
                            [(ngModel)]="selectedCustomer.phoneNumber" required/>
                            <mat-error *ngIf="editForm.submitted && phoneNumber.invalid">
                                Please enter a valid phone number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-control-section">
                    <div class="label-section">
                        <span>
                            Email Address
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput style="text-transform:lowercase;"  [(ngModel)]="selectedCustomer.email" 
                            #email="ngModel" type="text" name="email"/>
                            <mat-error *ngIf="editForm.submitted && email.invalid">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-control-section">
                    <div class="label-section">
                        <span>
                            Age
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="date" name="age" #age="ngModel" [(ngModel)]="selectedCustomer.age" />
                            <mat-error *ngIf="editForm.submitted && age.invalid">
                                Please enter a valid age
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="big-form-control-section">
                    <div class="label-section">
                        <span>
                            Address
                        </span>
                    </div>
                    <div class="control-section">
                        <mat-form-field appearance="none">
                            <input matInput type="text" name="address" #address="ngModel" 
                            [(ngModel)]="selectedCustomer.address" />
                            <mat-error *ngIf="editForm.submitted && address.invalid">
                                Please enter a valid customer address
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="popover-btn-section">
                    <button mat-flat-button>
                        Update
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>