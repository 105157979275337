<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Add Product
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'" >
                <span>
                    <fa-icon icon="plus"></fa-icon> Add Product
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('products/list');" >
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Product list
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'add'">Add a product</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="add-content-section">
                        <div class="left-add-content-section">
                            <div class="step-section">
                                <span [class.activeStep]="stepNumber == 1">
                                    product data
                                </span>
                            </div>
                            <div class="form-section">
                                <form (ngSubmit)="stepOneForm.form.valid && goToStepTwo(stepOneForm)"
                                name="stepOneForm" #stepOneForm="ngForm" >
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Name
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="name" #name="ngModel" required
                                                [(ngModel)]="addProductDetails.name" />
                                                <mat-error *ngIf="stepOneForm.submitted && name.invalid">
                                                    Please enter a valid item name
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Category
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="category" [(ngModel)]="addProductDetails.category" required
                                                #category="ngModel">
                                                    <mat-option style="text-transform: capitalize;" value="{{x._id}}" 
                                                    *ngFor="let x of categories">{{x.name}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="stepOneForm.submitted && category.invalid">
                                                    Please select a valid category
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Expiry Date
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="date" name="expiryDate" required
                                                [(ngModel)]="addProductDetails.expiryDate" #expiryDate="ngModel" />
                                                <mat-error *ngIf="stepOneForm.submitted && expiryDate.invalid">
                                                    Please select a valid expiry Date
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Shelf Number
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="shelfNumber" #shelfNumber="ngModel" 
                                                [(ngModel)]="addProductDetails.shelfNumber" maxlength="50" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="big-form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Product Descritpion
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="description" #description="ngModel" 
                                                [(ngModel)]="addProductDetails.description" maxlength="50" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section">
                                        <div class="label-section">
                                            <span>
                                                Number of Unit of Measurements
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <mat-select name="noOfMeasurement" #noOfMeasurement="ngModel" required
                                                [(ngModel)]="addProductDetails.noOfMeasurement" 
                                                (selectionChange)="generateMeasurementBoxes()">
                                                    <mat-option value="{{x}}" *ngFor="let x of measurementMaxList;">
                                                        {{x}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="stepOneForm.submitted && noOfMeasurement.invalid">
                                                    Please select a valid number of measurements
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="form-control-section" *ngFor="let x of addProductDetails.unitOfMeasurement; 
                                    let i = index">
                                        <div class="label-section">
                                            <span>
                                                Unit of Measurement ({{i+1}})
                                            </span>
                                        </div>
                                        <div class="control-section">
                                            <mat-form-field appearance="none">
                                                <input matInput type="text" name="unit{{x}}" 
                                                [(ngModel)]="addProductDetails.unitOfMeasurement[i].name" required/>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="btn-section">
                                        <button mat-flat-button>
                                            Continue
                                            
                                        </button>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        <div class="right-add-content-section">
                            <div class="step-section">
                                <span [class.activeStep]="stepNumber == 2">
                                    Unit of measurements
                                </span>
                            </div>
                            <div class="form-section">
                                <form  (ngSubmit)="stepTwoForm.form.valid && goToFinalStep(stepTwoForm)" 
                                name="stepTwoForm" #stepTwoForm="ngForm" *ngIf="stepNumber == 2">
                                    <div *ngFor="let x of addProductDetails.unitOfMeasurement;"
                                    class="measurement-section">
                                        <div class="left-measurement-section">
                                            <span>
                                                {{x.name}}
                                            </span>
                                        </div>
                                        <div class="right-measurement-section">
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.costPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.limitPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="formatted-text-section">
                                                <span>
                                                    &#8358; {{x.sellingPrice | number:'.2'}}
                                                </span>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Cost Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="costPrice{{x}}" 
                                                        [(ngModel)]="x.costPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Wholesale Selling Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="limitPrice{{x}}" 
                                                        [(ngModel)]="x.limitPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Retail Selling Price 
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="sellingPrice{{x}}" 
                                                        [(ngModel)]="x.sellingPrice" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Quantity
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="quantity{{x}}" 
                                                        [(ngModel)]="x.quantity" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Wholesale Quantity
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="wholeSalesQuantity{{x}}" 
                                                        [(ngModel)]="x.wholeSalesQuantity" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Reorder Limit
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="reorderLimit{{x}}" 
                                                        [(ngModel)]="x.reorderLimit" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Bar Code
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="text" name="barCode{{x}}" 
                                                        [(ngModel)]="x.barCode"/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section">
                                                <div class="label-section">
                                                    <span>
                                                        Type of Discount
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <mat-select name="discountType" [(ngModel)]="x.discountType" required>
                                                            <mat-option value="{{y}}" *ngFor="let y of discountTypeList;">
                                                                {{y}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section" *ngIf="x.discountType == 'bulk' ">
                                                <div class="label-section">
                                                    <span>
                                                        Number of {{x.name}}s to apply Bulk discount on
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="discountUnit{{x}}" 
                                                        [(ngModel)]="x.discountUnit" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-control-section" *ngIf="x.discountType !== 'none'">
                                                <div class="label-section">
                                                    <span>
                                                        Discount Percentage
                                                    </span>
                                                </div>
                                                <div class="control-section">
                                                    <mat-form-field appearance="none">
                                                        <input matInput type="number" name="discountPercentage{{x}}" 
                                                        [(ngModel)]="x.discountPercentage" required/>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-section">
                                        <button mat-flat-button>
                                            Save
                                            
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>