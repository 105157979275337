import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '../../functions/request.service';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import * as moment from 'moment';
import {NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public popOver: NgbPopover;
  public showSellButton: any = true;

  // checkout variables
  public checkoutDetails: any = {
    cart: [],
    cartTotalAmount: 0,
    cartIndex: -1,
    customerDetails: {},
    metadata: {
      vatPercentage: 0,
      vatAmount: 0,
      totalAmountAfterVat: 0,
      paymentOption: [
        {
          paymentType: 'POS',
          amount: null
        },
        {
          paymentType: 'Transfer',
          amount: null
        },
        {
          paymentType: 'Cash',
          amount: null
        },
      ],
      salesType: null,
      creditAmountPaid: 0,
      creditFinalDate: null,
      bankTransferId: null,
      totalDiscount: null,
      invoiceId: null,
      prescriptionProduct: null,
      shippingFee: null,
      cartTotalWithoutDiscount: null,
      storePickUp: null
    }
  };
  public paymentOptions = [
    'POS', 'Transfer', 'Cash'
  ];
  public salesOptions = [
    {
      name:'full payment',
      value: 'fullPayment'
    },
    {
      name:'credit sales',
      value: 'creditSales'
    }
  ];
  public listOfCarts: any = [];

  // print variables
  public printStatus: any = false;
  public receiptData: any = {
    receiptId: '',
    cashier: '',
    products: [],
    adverts: [],
    netTotal: 0,
    vatPercentage: 0,
    vatAmount: 0,
    cartTotalAmount: 0,
    discountTotal: 0,
    date: null,
    cartTotalWithoutDiscount: null,
    shippingFee: null
  }
  
  // prescription
  public prescriptionStatus: any = false;
  public prescriptionDetails: any = {
    file: null,
    displayImage: null
  }

  // rider
  public riderResult: any = [];
  public assignDetails: any = {
    requestData: {
      riderId: null
    },
    riderName: null,
    assigned: false
  }
  @ViewChild('popOver') popOverRef: any;

  // popover data
  public selectedAction: any = {}

  constructor(
    private functionService: RequestService,
    private printService: PrintService
  ) { 

  }

  ngOnInit(): void {

    this.getCheckout();
    this.getListCart();

  }

  // start and stop loading funx
  startLoading(){

    this.functionService.setLoading(true);

  }

  stopLoading(){

    this.functionService.setLoading(false);

  }

  toastNotification(response) {

    this.functionService.showNotification(response);

  }
  
  // goto selected page
  goToPage(page){

    this.functionService.setMenu(page);

  }

  // open and close popover
  togglePopover(popOver?: NgbPopover, action?){

    if(popOver){

      if(action == 'drug'){

        this.prescriptionDetails.file = null;
        this.prescriptionDetails.displayImage = null;

      }else if(action == 'rider'){
        
        if(this.riderResult && this.riderResult.length == 0){
          
          this.getAllRiders();
    
        }
        
      }

      popOver.autoClose = 'outside';
      popOver.placement = 'left-top'

      popOver.isOpen() ? popOver.close() : popOver.open();

      this.popOver = popOver;

    }

    this.selectedAction.action = action
  }

  // get checkout details
  getCheckout(){

    this.functionService.getCheckout().subscribe((checkout)=>{

      if(checkout && checkout.cart && checkout.cart.length > 0){

        this.initializeCheckout(checkout)

      }

    })

  };

  // get list of carts
  getListCart(){

    this.functionService.getCart().subscribe((cart)=>{

      this.listOfCarts = cart;

    })

  };

  // initialize checkout
  initializeCheckout(checkout){

    // get adverts from local storage
    this.receiptData.adverts = JSON.parse(localStorage.getItem('pharm_adverts'));

    // assign cart details to checkout details
    this.checkoutDetails.cart = checkout.cart
    this.checkoutDetails.cartTotalAmount = checkout.cartTotalAmount
    this.checkoutDetails.customerDetails = checkout.customerDetails

    // get vat amount and totalAmountAfterVat
    this.checkoutDetails.metadata.bankTransferId = checkout.server.bankTransferId;
    this.checkoutDetails.metadata.cartTotalWithoutDiscount = checkout.server.subTotal;
    this.checkoutDetails.metadata.totalAmountAfterVat = checkout.server.totalAmount;
    this.checkoutDetails.metadata.vatAmount = checkout.server.vat;
    this.checkoutDetails.metadata.vatPercentage = checkout.server.vatPercentage
    this.checkoutDetails.metadata.totalDiscount = checkout.server.totalDiscount;
    this.checkoutDetails.metadata.invoiceId = checkout.server.invoiceId;
    this.checkoutDetails.metadata.prescriptionProduct = checkout.server.prescriptionProduct;
    this.checkoutDetails.metadata.shippingFee = checkout.server.shippingFee;
    this.checkoutDetails.metadata.storePickUp = checkout.server.storePickUp;

  }

  // structure creditsales
  structureCreditSales(){

    if(this.checkoutDetails.metadata.salesType == 'creditSales'){

      this.checkoutDetails.metadata.creditAmountPaid = 0;
      this.checkoutDetails.metadata.creditFinalDate = null;

      this.calculatePaidAmount();

    }

  }

  // calculate paid amount
  calculatePaidAmount(){

    this.checkoutDetails.metadata.creditAmountPaid = 0;

    for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {
      
      if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

        var paymentAmount = this.checkoutDetails.metadata.paymentOption[i].amount;
  
        this.checkoutDetails.metadata.creditAmountPaid = this.checkoutDetails.metadata.creditAmountPaid + paymentAmount;
  
      }
        
    }

  }

  // clear checkout andf cart array
  clearCheckoutAndCart(){

    var cart = {
      status: true,
      cart:[]
    };
    var checkout = [];

    // get active cart index
    var activeCartIndex:any = this.listOfCarts.findIndex(o=> o.status == true);

    // check if active cart exist
    if(activeCartIndex > -1){

      this.listOfCarts.splice(activeCartIndex,1);

    }

    if(this.listOfCarts.length < 1){
    
      this.listOfCarts.unshift(cart);

    }

    this.functionService.setCart(this.listOfCarts);
    this.functionService.setCheckout(checkout);

    this.goToPage('account/market');

  }

  // proceed with sale
  processSale(){

    var requestResponse = '';
    
    if(this.checkoutDetails.metadata.salesType){

      var passPaymentAmount =  false;

      var paymentOption = [];
      var paymentAmount = 0;

      if(this.checkoutDetails.metadata.salesType == 'fullPayment'){

        // check payment option
        var passPayment = false;

        for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {
          
          if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

            passPayment = true;
            break;

          }
        }

        if(passPayment){

          for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {

            if(this.checkoutDetails.metadata.paymentOption[i].amount > 0){

              paymentOption.push(this.checkoutDetails.metadata.paymentOption[i]);
              paymentAmount = Number(paymentAmount) + Number(this.checkoutDetails.metadata.paymentOption[i].amount)

            }
            
          }

          if(paymentAmount == this.checkoutDetails.metadata.totalAmountAfterVat){

            passPaymentAmount= true;

            this.checkoutDetails.metadata.creditAmountPaid = 0;
            this.checkoutDetails.metadata.creditFinalDate = null;

          }else{

            passPaymentAmount= false;

            requestResponse = 'Please make sure that the paid amount corresponds to the total amount';
            this.toastNotification(requestResponse);

          }
        
        }else{

          passPaymentAmount= false;
        
          requestResponse = 'Please select payment option.';
          this.toastNotification(requestResponse);
        
        }


      }else if(this.checkoutDetails.metadata.salesType == 'creditSales'){

        if(this.checkoutDetails.metadata.creditAmountPaid > -1 && this.checkoutDetails.metadata.creditFinalDate){

          for (let i = 0; i < this.checkoutDetails.metadata.paymentOption.length; i++) {

            if(this.checkoutDetails.metadata.paymentOption[i].amount > -1){

              paymentOption.push(this.checkoutDetails.metadata.paymentOption[i]);
              paymentAmount = Number(paymentAmount) + Number(this.checkoutDetails.metadata.paymentOption[i].amount)

            }
            
          }

          if(paymentAmount == this.checkoutDetails.metadata.creditAmountPaid && 
          this.checkoutDetails.metadata.creditAmountPaid < this.checkoutDetails.metadata.totalAmountAfterVat){

            passPaymentAmount= true;

          }else{

            passPaymentAmount= false;

            requestResponse = 'Please make sure that the paid amount corresponds to the total amount';
            this.toastNotification(requestResponse);

          }

        }else{

          passPaymentAmount= false;

          requestResponse = 'Please select Amount Paid and Payment Final Date.';
          this.toastNotification(requestResponse);

        }

      }

      if(passPaymentAmount){

        for (let j = 0; j < paymentOption.length; j++) {
          
          if(paymentOption[j].amount < 0 || paymentOption[j].amount == null){

            paymentOption[j].amount = 0

          }
          
        }

        var requestData = {
          invoiceId: this.checkoutDetails.metadata.invoiceId,
          typeOfsales: this.checkoutDetails.metadata.salesType,
          paymentType: paymentOption,
          expectedDate: this.checkoutDetails.metadata.creditFinalDate,
          paid: this.checkoutDetails.metadata.creditAmountPaid,
          CustomerId: this.checkoutDetails.customerDetails.id,
          riderId: this.assignDetails.requestData.riderId,
        };

        this.sell(requestData);

      }
  
    }else{
  
      requestResponse = 'Please select sales type.';
      this.toastNotification(requestResponse);
  
    }

  }

  // sell request
  sell(requestData){

    this.showSellButton = false;

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/payForInvoice';

    this.functionService.postTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Sale completed successfully.';
          this.toastNotification(requestResponse);

          this.receiptData.receiptId = res.receiptId
          this.togglePrintStatus(true);
           
        }else{

          this.showSellButton = true;
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();
        this.showSellButton = true;

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // toggle print status
  togglePrintStatus(status){

    this.printStatus = status

    if(this.printStatus){

      this.getReceiptData()
      
    }

  }

  // structure receipt data
  getReceiptData(){

    // set cahsier name
    var user = JSON.parse(localStorage.getItem('pharm_user'));
    this.receiptData.cashier = user.personalInfo.firstName;

    // set total price after tax, cart total amount and vat
    this.receiptData.vatPercentage = this.checkoutDetails.metadata.vatPercentage
    this.receiptData.vatAmount = this.checkoutDetails.metadata.vatAmount
    this.receiptData.cartTotalAmount = this.checkoutDetails.cartTotalAmount
    this.receiptData.cartTotalWithoutDiscount = this.checkoutDetails.metadata.cartTotalWithoutDiscount
    this.receiptData.netTotal = this.checkoutDetails.metadata.totalAmountAfterVat
    this.receiptData.discount = this.checkoutDetails.metadata.totalDiscount;
    this.receiptData.shippingFee = this.checkoutDetails.metadata.shippingFee;

    this.receiptData.customer = this.checkoutDetails.customerDetails.fullName;

    // set products and discount
    this.receiptData.products = [];
    for (let i = 0; i < this.checkoutDetails.cart.length; i++) {
      
      if(this.checkoutDetails.cart[i]){

        var product = this.checkoutDetails.cart[i];

        var purcahsedProduct = {
          name: product.name,
          measurement: product.measurement,
          quantity: product.cartQty,
          sellingPrice: product.sellingPrice,
          totalAmount: product.cartAmount,
        }

        this.receiptData.products.push(purcahsedProduct)

      }
      
    }

    // set date
    this.receiptData.date = moment(new Date()).format('YYYY-MM-D, hh:mm:ss A')
  }

  // PRESCRIPTION

  // get image file
  onFileSelected() {
    
    var file:any = (<HTMLInputElement>document.getElementById('file')).files[0];
    this.prescriptionDetails.file = file;

    var r = new FileReader();

    r.onload = (e:any)=> {

      // data is in r.result
      this.prescriptionDetails.displayImage = e.target.result;

    }

    r.readAsDataURL(file);

  }

  // addPrescription
  addPrescription(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Sales/UploadPrescription';

    var requestData = new FormData();
    requestData.append('invoiceId', this.checkoutDetails.metadata.invoiceId)
    requestData.append('image', this.prescriptionDetails.file)

    this.functionService.putTokenHttpRequestService(route, requestData).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          requestResponse = 'Prescription uploaded. Please copy the invoice number and await approval.';
          this.toastNotification(requestResponse);

          this.prescriptionDetails.file = null;
          this.prescriptionDetails.displayImage = null;

          if(this.popOver){
            this.popOver.close()
          }

          this.clearCheckoutAndCart();
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // continue With Sales when there is no prescription
  continueWithSales(){

    var requestResponse = 'Please copy the invoice number and await approval.';
    this.toastNotification(requestResponse);

    this.clearCheckoutAndCart();

  }

  // RIDER

  // search request
  getAllRiders(){

    this.startLoading();

    var httpSuccessText = 'OK'.toLowerCase();
    var requestResponse = '';

    var route = 'Rider/GetAllRider';

    this.functionService.getTokenHttpRequestService(route).subscribe(
      (res: any)=>{

        this.stopLoading();

        if(res.status.toLowerCase() == httpSuccessText){

          if(res.staffs.length > 0){
            this.riderResult = res.staffs;
          }
           
        }else{
      
          requestResponse = res.message;
          this.toastNotification(requestResponse);
      
        }

      },(err)=>{

        this.stopLoading();

        requestResponse = err.error.message;
        this.toastNotification(requestResponse);

      }

    );

  }

  // complete Sale After Rider has been assigned
  completeSaleAfterRider(){

    this.assignDetails.requestData.riderId = this.selectedAction.rider
    this.assignDetails.assigned = true;
    if(this.popOver){
      this.popOver.close()
    }
    

  }

}
