<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Staff List
            </span>
        </div>
        <div class="btn-section">
            <div class="search-section">
                <div class="search-holder">
                    <div class="icon-section">
                        <fa-icon icon="search" matPrefix></fa-icon>
                    </div>
                    <div class="left-search-section">
                        <mat-form-field appearance="none" class="searchId">
                            <input matInput type="text" name="searchData" autocomplete="off" [(ngModel)]="searchData"
                                placeholder="Search by staff number" (keyup)="searchEvent($event)" />
                        </mat-form-field>
                    </div>
                    <div class="right-search-section">
                        <button mat-flat-button (click)="searchRequest();">
                            Search
                        </button>
                    </div>
                </div>
                <div class="search-list-holder" *ngIf="showSearchItems">
                    <div class="top-search-section">
                        <span (click)="closeSearch();">
                            close
                        </span>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length > 0">
                        <div class="search-item-section" *ngFor="let x of searchResult; let i = index"
                            (click)="selectStaff(x)">
                            <div class="top-search-item-section">
                                <span>
                                    {{x.personalInfo.firstName}} {{x.personalInfo.lastName}}
                                </span>
                            </div>
                            <div class="down-search-item-section">
                                <span>
                                    {{x.phoneNumber}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="down-search-section" *ngIf="searchResult.length < 1">
                        <div class="loading-section">
                            <span class="no-data-holder" *ngIf="!showSearchLoading">
                                no result found for "{{searchData}}"
                            </span>
                            <span class="loader-holder" *ngIf="showSearchLoading">
                                loading...
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'">
                <span>
                    <fa-icon icon="list-ul"></fa-icon> All Staff
                </span>
            </div>
            <div class="menu-section" (click)="toggleInnerMenu('add')" [class.activeMenu]="activeInnerMenu == 'add'">
                <span>
                    <fa-icon icon="user"></fa-icon> Add Staff
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">list of staffs</b>
                    <b *ngIf="activeInnerMenu == 'add'">Add a staff</b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="content-section">
                        <div class="right-content-section" [@animateArc]="shoppingCartView">
                            <div class="details-list-section" *ngIf="shoppingCartView == 'true'">
                                <div class="top-details-section">
                                    <div class="left-top-details-section">
                                        <span>
                                            Staff details
                                        </span>
                                    </div>
                                    <div class="right-top-details-section">
                                        <div class="icon-section" (click)="toggleCartView()">
                                            <fa-icon icon="long-arrow-alt-right"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="down-details-section" [ngbPopover]="popContent" triggers="manual"
                                    popoverClass='popoversection' #popOver="ngbPopover">
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff Name
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedStaff.name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Phone Number
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedStaff.phoneNumber}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="big-item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Email Address
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span style="text-transform: lowercase;">
                                                {{selectedStaff.email}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff Position
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                {{selectedStaff.role}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-details-section">
                                        <div class="label-section">
                                            <span>
                                                Staff Active?
                                            </span>
                                        </div>
                                        <div class="value-section">
                                            <span>
                                                <b *ngIf="selectedStaff.active">YES</b>
                                                <b *ngIf="!selectedStaff.active">NO</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="item-list-section">
                                        <div class="item-section">
                                            <div class="item-details-section" *ngFor="let x of activities">
                                                <div class="label-section">
                                                    <span>
                                                        {{x.name}}
                                                    </span>
                                                </div>
                                                <div class="value-section">
                                                    <span style="cursor: pointer;"
                                                        (click)="showStaffTransaction(x.value)">
                                                        view
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-details-section">
                                        <div class="left-btn-details-section">
                                            <div class="icon-section" (click)="togglePopover(popOver, 'position')">
                                                <fa-icon icon="project-diagram"></fa-icon>
                                            </div>
                                        </div>
                                        <div class="right-btn-details-section">
                                            <div class="icon-section" (click)="togglePopover(popOver, 'status')">
                                                <fa-icon icon="angle-double-right"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-details-section" *ngIf="selectedStaff.activeStore">
                                        <div class="full-btn-details-section">
                                            <button mat-flat-button (click)="togglePopover(popOver, 'switch')">
                                                Switch store

                                            </button>
                                        </div>
                                    </div>
                                    <div class="btn-details-section" *ngIf="!selectedStaff.activeStore">
                                        <div class="full-btn-details-section">
                                            <button mat-flat-button (click)="togglePopover(popOver, 'transfer')">
                                                Transfer

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-content-section" [@animateOtherArc]="marketView">
                            <div class="no-data-section" *ngIf="staffList.length <= 0">
                                <div class="img-section">
                                    <fa-icon icon="database"></fa-icon>
                                </div>
                                <div class="text-section">
                                    <span>
                                        No record Found
                                    </span>
                                </div>
                            </div>
                            <div class="data-section" *ngIf="staffList.length > 0">
                                <div class="list-section">
                                    <div class="list-item-section" *ngFor="let x of staffList; let i = index"
                                        [class.newListItemSection]="marketView == 'true'" (click)="selectStaff(x)"
                                        [class.activeRecord]="x._id == selectedStaff.id">
                                        <div class="list-item-content">
                                            <div class="details-section">
                                                <div class="top-details-section">
                                                    <span>
                                                        {{x.personalInfo.firstName}} {{x.personalInfo.lastName}}
                                                    </span>
                                                </div>
                                                <div class="middle-details-section">
                                                    <span>
                                                        <b>
                                                            {{x.phoneNumber}}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="down-details-section">
                                                    <span *ngIf="x.positionId">
                                                        {{x.positionId.positionName}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-section">
                                    <div class="pagination-text" (click)="getPage(1)">
                                        <span>
                                            First
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(-1)">
                                        <span>
                                            Prev
                                        </span>
                                    </div>
                                    <div class="pagination-text" [class.activePage]="pageIndex == x"
                                        *ngFor="let x of pageArray; let i = index" (click)="getPage(x)">
                                        <span>
                                            {{x}}
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="paginate(1)">
                                        <span>
                                            Next
                                        </span>
                                    </div>
                                    <div class="pagination-text" (click)="getPage(noOfPages)">
                                        <span>
                                            Last
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activeInnerMenu == 'add'">
                    <div class="restock-content-section">
                        <div class="right-restock-content-section">
                            <form name="addStaffForm" #addStaffForm="ngForm" class="form"
                                (ngSubmit)="addStaffForm.form.valid && addStaff(addStaffForm)">
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            First Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="firstName"
                                                [(ngModel)]="addStaffDetails.firstName" required #firstName="ngModel" />
                                            <mat-error *ngIf="addStaffForm.submitted && firstName.invalid">
                                                Please enter a valid first name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Last Name
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="lastName"
                                                [(ngModel)]="addStaffDetails.lastName" required #lastName="ngModel" />
                                            <mat-error *ngIf="addStaffForm.submitted && lastName.invalid">
                                                Please enter a valid last name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Mobile Number
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="tel" name="phoneNumber" required
                                                [(ngModel)]="addStaffDetails.phoneNumber" #phoneNumber="ngModel"
                                                pattern="[0-9]{11}" />
                                            <mat-error *ngIf="addStaffForm.submitted && phoneNumber.invalid">
                                                Please enter a valid phone number
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Email Address
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" style="text-transform: lowercase;" name="email"
                                                [(ngModel)]="addStaffDetails.email" #email="ngModel" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="big-form-control-section">
                                    <div class="label-section">
                                        <span>
                                            Address
                                        </span>
                                    </div>
                                    <div class="control-section">
                                        <mat-form-field appearance="none">
                                            <input matInput type="text" name="address"
                                                [(ngModel)]="addStaffDetails.address" #address="ngModel" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="btn-section">
                                    <button mat-flat-button>
                                        Add
                                        <fa-icon icon="plus"></fa-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span *ngIf="selectedStaff.action == 'status'">
                Toggle staff active status
            </span>
            <span *ngIf="selectedStaff.action == 'position'">
                change staff position
            </span>
            <span *ngIf="selectedStaff.action == 'transfer'">
                Transfer staff to store
            </span>
            <span *ngIf="selectedStaff.action == 'switch'">
                switch to store
            </span>
        </div>
        <div class="down-popover-section">
            <div class="approve-prescription-section" *ngIf="selectedStaff.action == 'status'">
                <div class="approve-section">
                    <div class="text-section">
                        <span>
                            Please click the button below if you are sure you want to
                            <b *ngIf="selectedStaff.active">restrict access</b>
                            <b *ngIf="!selectedStaff.active">grant access</b>
                            for <b>{{selectedStaff.name}}</b>.
                        </span>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button (click)="toggleActivation(selectedStaff.active)">
                            <b *ngIf="selectedStaff.active">Restrict</b>
                            <b *ngIf="!selectedStaff.active">Grant</b>

                        </button>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button (click)="deleteStaff()">
                            <b>Delete</b>
                        </button>
                    </div>
                </div>
            </div>
            <div class="cancel-section" *ngIf="selectedStaff.action == 'position'">
                <form name="editForm" #editForm="ngForm"
                    (ngSubmit)="editForm.form.valid && changeStaffPosition(editForm)">
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Staff Name
                            </span>
                        </div>
                        <div class="control-section">
                            <mat-form-field appearance="none">
                                <input matInput style="text-transform:capitalize;" type="text" name="name"
                                    [(ngModel)]="selectedStaff.name" #name="ngModel" readonly />
                                <mat-error *ngIf="editForm.submitted && name.invalid">
                                    Please enter a valid staff name
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Staff Position
                            </span>
                        </div>
                        <div class="control-section">
                            <select name="role" [(ngModel)]="selectedStaff.newRole" required #role="ngModel" required>
                                <option value="{{x.positionName}}" *ngFor="let x of positions">
                                    {{x.positionName}}
                                </option>
                            </select>
                            <mat-error *ngIf="editForm.submitted && role.invalid">
                                Please enter a valid role
                            </mat-error>
                        </div>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button>
                            Change

                        </button>
                    </div>
                </form>
            </div>
            <div class="cancel-section" *ngIf="selectedStaff.action == 'switch' || selectedStaff.action == 'transfer'">
                <form name="storeForm" #storeForm="ngForm"
                    (ngSubmit)="storeForm.form.valid && changeStaffStore(storeForm)">
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Staff Name
                            </span>
                        </div>
                        <div class="control-section">
                            <mat-form-field appearance="none">
                                <input matInput style="text-transform:capitalize;" type="text" name="name"
                                    [(ngModel)]="selectedStaff.name" #name="ngModel" readonly />
                                <mat-error *ngIf="storeForm.submitted && name.invalid">
                                    Please enter a valid staff name
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="big-form-control-section">
                        <div class="label-section">
                            <span>
                                Store
                            </span>
                        </div>
                        <div class="control-section">
                            <select name="store" [(ngModel)]="selectedStaff.storeId" required #store="ngModel" required>
                                <option value="{{x._id}}" *ngFor="let x of stores">
                                    {{x.locationName}}
                                </option>
                            </select>
                            <mat-error *ngIf="storeForm.submitted && store.invalid">
                                Please enter a valid store
                            </mat-error>
                        </div>
                    </div>
                    <div class="popover-btn-section">
                        <button mat-flat-button>
                            Change

                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>