<div class="page-section">
    <div class="page-title-section">
        <div class="text-section">
            <span>
                Low Stock Products
            </span>
        </div>
        <div class="btn-section">
        </div>
    </div>
    <div class="page-holder">
        <div class="left-section">
            <div class="menu-section" (click)="toggleInnerMenu('list')" [class.activeMenu]="activeInnerMenu == 'list'" >
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Low Stock Products
                </span>
            </div>
            <div class="menu-section" (click)="exportData()">
                <span>
                    <fa-icon icon="file-export"></fa-icon> Export Data
                </span>
            </div>
            <div class="menu-section" (click)="goToPage('products/list');" >
                <span>
                    <fa-icon icon="list-ul"></fa-icon> Product list
                </span>
            </div>
        </div>
        <div class="right-section">
            <div class="menu-title-section">
                <span>
                    <b *ngIf="activeInnerMenu == 'list'">List of products with low stock </b>
                </span>
            </div>
            <div class="menu-content">
                <ng-template [ngIf]="activeInnerMenu == 'list'">
                    <div class="no-data-section"  *ngIf="lowStockItems.length <= 0">
                        <div class="img-section">
                            <fa-icon icon="database"></fa-icon>
                        </div>
                        <div class="text-section">
                            <span>
                                No record Found
                            </span>
                        </div>
                    </div>
                    <div class="data-section" *ngIf="lowStockItems.length > 0">
                        <div class="list-section">
                            <div class="list-item-section" *ngFor="let x of lowStockItems; let i = index" >
                                <div class="list-item-content" (click)="selectRecord(x, i, 'list', popOver)" [ngbPopover]="popContent" 
                                [popoverTitle]="popTitle" triggers="manual" popoverClass='popoversection' #popOver="ngbPopover">
                                    <div class="icon-section">
                                        <div class="circle">
                                            <span>
                                                {{x.firstLetter}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="details-section">
                                        <div class="top-details-section">
                                            <span>
                                                {{x.name}}
                                            </span>
                                        </div>
                                        <div class="middle-details-section">
                                            <span>
                                                <b *ngFor="let y of x.measurements">
                                                    {{y.name}}({{y.quantity | number}}),  
                                                </b>
                                            </span>
                                        </div>
                                        <div class="down-details-section">
                                            <span>
                                                <b *ngFor="let y of x.measurements">
                                                    {{y.name}}({{y.reOrderLimit | number}}),  
                                                </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <div class="popover-content">
        <div class="top-popover-section">
            <span>
                Product Stock details
            </span>
        </div>
        <div class="down-popover-section">
            <div class="item-details-section">
                <div class="label-section">
                    <span>
                        Product Name
                    </span>
                </div>
                <div class="value-section">
                    <span>
                       {{selectedRecord.name}}
                    </span>
                </div>
            </div>
            <div class="big-item-details-section">
                <div class="label-section">
                    <span>
                        Availabile Quantity
                    </span>
                </div>
                <div class="value-section">
                    <span>
                       <b *ngFor="let y of selectedRecord.measurements">
                        {{y.name}}({{y.quantity | number}}), 
                       </b>
                    </span>
                </div>
            </div>
            <div class="big-item-details-section">
                <div class="label-section">
                    <span>
                        Re-order Quantity
                    </span>
                </div>
                <div class="value-section">
                    <span>
                       <b *ngFor="let y of selectedRecord.measurements">
                        {{y.name}}({{y.reOrderLimit | number}}), 
                       </b>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>